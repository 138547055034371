<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="forgotten-password-modal-label">
                {{ ((step == 3) && !resend_code) ? 'Enter the 6 digit code we have sent to your email'
                    : 'Reset password' }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-if="resend_code">
                Sometimes system-generated emails go into your junk, spam or deleted folder. Please check there.
            </div>
            <div v-if="(!sent && !resend_code)">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="((step < 3) && has_user_data)">
                        <loading></loading>
                    </div>
                    <div v-if="((step < 3) && !has_user_data)">
                        <div v-if="!$root.client.reset_password_email_only" class="form-group">
                            <label
                                for="reset_first_name"
                                id="reset_name-label"
                                class="mandatory-label"
                            >
                                Please enter your name *
                            </label>
                            <div class="input-group">
                                <input
                                    v-model="first_name"
                                    type="text"
                                    id="reset_first_name"
                                    class="form-control mandatory-field"
                                    :readonly="sending"
                                    :placeholder="placeholder('reset_first_name')"
                                    v-on:focus="placeholder('reset_first_name', true)"
                                    v-on:blur="placeholder('reset_first_name', true)"
                                    v-on:keyup="unHighlightNameErrors()"
                                    v-on:change="unHighlightNameErrors()"
                                >
                                <input
                                    v-model="last_name"
                                    type="text"
                                    id="reset_last_name"
                                    class="form-control mandatory-field"
                                    :readonly="sending"
                                    :placeholder="placeholder('reset_last_name')"
                                    v-on:focus="placeholder('reset_last_name', true)"
                                    v-on:blur="placeholder('reset_last_name', true)"
                                    v-on:keyup="unHighlightNameErrors()"
                                    v-on:change="unHighlightNameErrors()"
                                >
                            </div>
                        </div>
                        <div class="form-group">
                            <label
                                for="reset_email"
                                id="reset_email-label"
                                class="mandatory-label"
                            >
                                Please enter your email *
                            </label>
                            <input
                                v-model="email"
                                type="email"
                                id="reset_email"
                                class="form-control mandatory-field"
                                :readonly="sending"
                                v-on:keyup="$root.unHighlightErrors('reset_email')"
                                v-on:change="$root.unHighlightErrors('reset_email')"
                            >
                        </div>
                    </div>

                    <div v-if="(step == 3)">
                        <span v-if="is_migrated_user">
                            <p>
                                There have been some updates and enhancements made to {{ $root.client.client_name }}.
                                For security reasons, we need you to re-set your password.
                                We have emailed you a 6-digit code.
                            </p>
                        </span>
                        <label
                            for="reset_code_1"
                            id="reset_code-label"
                            class="mandatory-label mb-3"
                        >
                            Enter the six digit code below. Sometimes these go into spam/junk so please check there.
                        </label>
                        <div class="row">
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_1"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_1"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_1')"
                                        v-on:change="$root.unHighlightErrors('reset_code_1')"
                                    >
                                </div>
                            </div>
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_2"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_2"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_2')"
                                        v-on:change="$root.unHighlightErrors('reset_code_2')"
                                    >
                                </div>
                            </div>
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_3"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_3"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_3')"
                                        v-on:change="$root.unHighlightErrors('reset_code_3')"
                                    >
                                </div>
                            </div>
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_4"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_4"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_4')"
                                        v-on:change="$root.unHighlightErrors('reset_code_4')"
                                    >
                                </div>
                            </div>
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_5"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_5"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_5')"
                                        v-on:change="$root.unHighlightErrors('reset_code_5')"
                                    >
                                </div>
                            </div>
                            <div class="col-sm-2 col-4">
                                <div class="form-group">
                                    <input
                                        v-model="code_6"
                                        type="text"
                                        v-mask="'######'"
                                        id="reset_code_6"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        style="font-size: 2rem; text-align: center"
                                        v-on:keyup="$root.unHighlightErrors('reset_code_6')"
                                        v-on:change="$root.unHighlightErrors('reset_code_6')"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="(step == 4)">
                        <div class="form-group">
                            <label for="new_password" id="new_password-label" class="mandatory-label">
                                Enter your new password
                            </label>
                            <input
                                v-model="new_password"
                                type="password"
                                id="new_password"
                                class="form-control mandatory-field"
                                :readonly="sending"
                                autocomplete="off"
                                v-on:keyup="$root.unHighlightErrors('new_password')"
                                v-on:change="$root.unHighlightErrors('new_password')"
                            >
                            <password-strength
                                :password="new_password"
                                v-model="new_password_score"
                            ></password-strength>
                        </div>
                        <div class="form-group">
                            <label for="confirm_new_password" id="confirm_new_password-label" class="mandatory-label">
                                Confirm new password
                            </label>
                            <input
                                v-model="confirm_new_password"
                                type="password"
                                id="confirm_new_password"
                                class="form-control mandatory-field"
                                :readonly="sending"
                                autocomplete="off"
                                v-on:keyup="$root.unHighlightErrors('confirm_new_password')"
                                v-on:change="$root.unHighlightErrors('confirm_new_password')"
                            >
                        </div>
                    </div>

                    <div v-if="(errors || (step == 3))">
                        <a
                            :key="'email-support-button'"
                            href="#"
                            class="btn btn-secondary btn-sm"
                            data-toggle="modal"
                            data-target="#email-support-modal"
                            @click.prevent="resetSupportForm()"
                        >
                            <i class="far fa-envelope mr-1"></i> Email support
                        </a>
                        <span v-if="(step == 3)">
                            <a
                                v-if="!resend_code_timer"
                                :key="'resend-code-button'"
                                href="#"
                                class="btn btn-secondary btn-sm ml-2"
                                @click.prevent="toggleResendCode()"
                            >
                                <i class="far fa-undo-alt mr-1"></i> Resend code
                            </a>
                            <button
                                v-else
                                :key="'resend-code-button-disabled'"
                                type="button"
                                class="btn btn-secondary btn-sm ml-2 text-left"
                                :disabled="true"
                                style="width: 150px"
                            >
                                <i class="far fa-undo-alt mr-1"></i> Resend code in {{ resendCodeTime() }}
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="(!sent && ((step > 2) || !has_user_data))" class="modal-footer">
            <div v-if="resend_code">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="resendCode()">
                        <i class="far fa-undo-alt mr-1"></i> Resend code
                    </button>
                    <button type="button" class="btn btn-light" @click="toggleResendCode()">
                        <i class="far fa-times mr-1"></i> Cancel
                    </button>
                </span>
            </div>
            <div v-else>
                <span v-if="!sending">
                    <span>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="((step == 4) && (!new_password || (new_password_score < 7)
                                || !confirm_new_password))"
                            @click="onSubmit()"
                        >
                            <i :class="submitIcon()"></i> {{ submitButtonText() }}
                        </button>
                    </span>
                </span>
                <span v-else>
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ submitButtonText() }}</span>
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                step: 2,
                sending: false,
                sent: false,
                first_name: '',
                last_name: '',
                email: '',
                user_id: 0,
                code: '',
                code_1: '',
                code_2: '',
                code_3: '',
                code_4: '',
                code_5: '',
                code_6: '',
                new_password: '',
                confirm_new_password: '',
                new_password_score: 0,
                resend_code: false,
                has_user_data: false,
                is_migrated_user: false,
                user_unapproved: false,
                email_not_verified: false,
                user_invited: false,
                user: {},
                resend_code_timer: 0,
                resend_code_interval: null
            }
        },
        mounted () {
            //
        },
        beforeDestroy() {
            if (this.resend_code_interval) {
                clearInterval(this.resend_code_interval);
            }
        },
        watch: {
            code_1: function (val) {
                this.updateCode(val);
            },
            code_2: function (val) {
                this.updateCode(val);
            },
            code_3: function (val) {
                this.updateCode(val);
            },
            code_4: function (val) {
                this.updateCode(val);
            },
            code_5: function (val) {
                this.updateCode(val);
            },
            code_6: function (val) {
                this.updateCode(val);
            }
        },
        methods: {
            resetForm: function (userData = null) {
                this.errors = false;
                this.step = 2;
                this.sending = false;
                this.sent = false;
                this.user_id = 0;
                this.code = '';
                this.code_1 = '';
                this.code_2 = '';
                this.code_3 = '';
                this.code_4 = '';
                this.code_5 = '';
                this.code_6 = '';
                this.first_name = '';
                this.last_name = '';
                this.email = '';
                this.new_password = '';
                this.confirm_new_password = '';
                this.new_password_score = 0;
                this.resend_code = false;
                this.has_user_data = false;
                this.is_migrated_user = false;
                this.user_unapproved = false;
                this.email_not_verified = false;
                this.user_invited = false;
                this.resend_code_timer = 0;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if ((this.$route.name == 'login') || (this.$route.name == 'login-password-reset')) {
                    const component = this.$parent;
                    if (component) {
                        this.email = component.email;
                        if (userData) {
                            this.first_name = userData.first_name;
                            this.last_name = userData.last_name;
                            this.is_migrated_user = userData.migrated;
                            this.has_user_data = true;
                            this.onSubmit();

                            return null;
                        }

                        return null;
                    }
                }
                if (this.$root.isLoggedIn()) {
                    this.first_name = this.$root.user.first_name;
                    this.last_name = this.$root.user.last_name;
                    this.email = this.$root.user.email;
                }
            },
            submitIcon: function () {
                if (this.step < 4) {
                    return 'far fa-arrow-circle-right mr-1';
                }

                return 'far fa-paper-plane mr-1';
            },
            submitButtonText: function () {
                if (this.step < 4) {
                    return 'Continue';
                }

                return 'Submit';
            },
            onSubmit: function () {
                this.sending = true;
                this.errors = false;
                this.user_unapproved = false;
                this.email_not_verified = false;
                this.user_invited = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    step: this.step,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    user_id: this.user_id,
                    code: this.code,
                    new_password: this.new_password,
                    confirm_new_password: this.confirm_new_password,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };

                const path = '/' + this.$root.app.client_id + '/user/reset-password-v2';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    if (vm.step == 4) {
                        vm.sent = 'Your password has changed. Please login again with your new password.';
                        vm.$parent.email = vm.email;
                        vm.$parent.password = '';
                        vm.$parent.errors = false;

                        return null;
                    }
                    vm.step = response.data.next_step;
                    if (vm.step == 3) {
                        vm.resend_code_timer = 5 * 60;
                        if (!vm.resend_code_interval) {
                            vm.resend_code_interval = setInterval(function () {
                                if (vm.resend_code_timer) {
                                    vm.resend_code_timer--;
                                }
                            }, 1000);
                        }
                        vm.user_id = response.data.user_id;
                        setTimeout(function () {
                            $('#reset_code_1').focus();
                        }, 800);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 404) {
                        vm.errors = 'That information doesn\'t match our records, please try again.';

                        return null;
                    }
                    if (error.response.status == 406) {
                        if (error.response.data.error == 'Unapproved') {
                            vm.errors = 'Your application is still being reviewed.'
                                + ' To chase this please email support.';
                            vm.user_unapproved = true;
                            vm.user = error.response.data.user;
                            vm.user.first_name = vm.first_name,
                            vm.user.last_name = vm.last_name,
                            vm.user.email = vm.email;

                            return null;
                        }
                        if (error.response.data.error == 'NotVerified') {
                            vm.errors = 'You need to verify your email address before you can login.'
                                + '<br><br>Please check your email from this system and click on the email'
                                + ' verification link.<br><br>If you cannot find the email in your inbox,'
                                + ' please check your spam/junk folders or email support.';
                            vm.email_not_verified = true;
                            vm.user = error.response.data.user;
                            vm.user.first_name = vm.first_name,
                            vm.user.last_name = vm.last_name,
                            vm.user.email = vm.email;

                            return null;
                        }
                        if (error.response.data.error == 'Invited') {
                            vm.errors = 'You have been sent an invite to become a user on this system. '
                                + ' You need to complete registration before you can login.'
                                + '<br><br>Please check your email from this system and click on the COMPLETE'
                                + ' REGISTRATION link. Or click on "Get a new link" in the email if the invite '
                                + 'already expired.<br><br>If you cannot find the email in your inbox,'
                                + ' please check your spam/junk folders or email support.';
                            vm.user_invited = true;
                            vm.user = error.response.data.user;
                            vm.user.first_name = vm.first_name,
                            vm.user.last_name = vm.last_name,
                            vm.user.email = vm.email;

                            return null;
                        }
                    }
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                                if (vm.errors.indexOf('expired') > -1) {
                                    vm.errors += ' Click on Resend code to get a new one.';
                                    vm.step = 3;
                                }
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (let [key, value] of Object.entries(error.response.data.error)) {
                                    const fields = [
                                        'first_name',
                                        'last_name',
                                        'email',
                                        'code'
                                    ];
                                    if (fields.indexOf(key) > -1) {
                                        key = 'reset_' + key;
                                    }
                                    vm.errors += value.join('<br>') + '<br>';
                                    if ((key == 'reset_first_name') || (key == 'reset_last_name')) {
                                        $('#reset_name-label').addClass('text-danger');
                                    } else {
                                        $('#' + key + '-label').addClass('text-danger');
                                    }
                                    if (key == 'reset_code') {
                                        for (var n = 1; n <= 6; n++) {
                                            if ($('#reset_code_' + n).val() === '') {
                                                $('#reset_code_' + n).addClass('field-error');
                                            }
                                        }
                                    } else {
                                        $('#' + key).addClass('field-error');
                                    }
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            toggleResendCode: function () {
                this.resend_code = !this.resend_code;
            },
            resendCode: function () {
                this.resend_code = false;
                this.step = 2;
                this.code = '';
                this.code_1 = '';
                this.code_2 = '';
                this.code_3 = '';
                this.code_4 = '';
                this.code_5 = '';
                this.code_6 = '';
                this.onSubmit();
            },
            resetSupportForm: function () {
                $('#forgotten-password-modal').modal('hide');
                let loggedOut = false;
                if (this.$root.isLoggedIn() && (this.$root.user.email != this.email)) {
                    this.$root.doLogout();
                    this.$root.user = {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email
                    };
                    loggedOut = true;
                }
                if (this.user_unapproved || this.email_not_verified || this.user_invited) {
                    this.$root.user = this.user;
                }
                if (loggedOut) {
                    var vm = this;
                    setTimeout(function () {
                        vm.resetSupportForm();
                    }, 200);

                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    if (this.user_unapproved) {
                        component.resetForm(
                            'access',
                            'Please review application from ' + this.$root.user.first_name + ' '
                                + this.$root.user.last_name,
                            'forgotten-password'
                        );

                        return null;
                    }
                    if (this.email_not_verified) {
                        component.resetForm(
                            'access',
                            'I have not received my email verification link',
                            'forgotten-password'
                        );

                        return null;
                    }
                    if (this.user_invited) {
                        component.resetForm(
                            'access',
                            'I have not received my invite email',
                            'forgotten-password'
                        );

                        return null;
                    }
                    if (this.step < 3) {
                        component.resetForm(
                            'access',
                            'I am unable to reset my password, it says my name or email address does not match',
                            'forgotten-password'
                        );

                        return null;
                    }
                    component.resetForm(
                        'access',
                        'I am unable to reset my password, it says the 6 digit code is invalid',
                        'forgotten-password'
                    );
                }
            },
            updateCode: function (val) {
                if (val.length > 1) {
                    this.code_1 = val.substr(0, 1);
                    this.code_2 = val.substr(1, 1);
                    this.code_3 = val.substr(2, 1);
                    this.code_4 = val.substr(3, 1);
                    this.code_5 = val.substr(4, 1);
                    this.code_6 = val.substr(5, 1);
                }
                this.code = this.code_1 + this.code_2 + this.code_3 + this.code_4 + this.code_5 + this.code_6;

                var vm = this;
                setTimeout(function () {
                    if (vm.code.length == 6) {
                        $('.mandatory-field').blur();
                        $('.mandatory-label').removeClass('text-danger');
                    } else {
                        for (let digit = 6; digit >= 1; digit--) {
                            if ($('#reset_code_' + digit).val() == '') {
                                $('#reset_code_' + digit).focus();
                            }
                        }
                    }
                }, 20);
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'reset_first_name':
                        text = 'First';
                        break;
                    case 'reset_last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.first_name) {
                    $('#reset_first_name').removeClass('field-error');
                }
                if (this.last_name) {
                    $('#reset_last_name').removeClass('field-error');
                }
                if (this.first_name && this.last_name) {
                    $('#reset_name-label').removeClass('text-danger');
                }
            },
            resendCodeTime: function () {
                if (this.resend_code_timer < 60) {
                    return ('0' + this.resend_code_timer).slice(-2);
                }
                const minutes = Math.floor(this.resend_code_timer / 60);
                const seconds = this.resend_code_timer - (minutes * 60);

                return minutes + ':' + ('0' + seconds).slice(-2);
            }
        }
    }
</script>
