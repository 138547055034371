<template>
    <div>
        <div class="card asset-details">
            <div class="card-body" style="position: relative">
                <div
                    v-if="errors"
                    v-html="errors"
                    class="alert alert-danger"
                    role="alert"
                    style="width: calc(100% - 50px)"
                ></div>

                <div v-if="no_profile_error">
                    <a
                        href="#"
                        data-toggle="modal"
                        data-target="#email-support-modal"
                        @click.prevent="resetSupportForm('no-profile')"
                    >
                        Please click here to contact support to get a {{ $root.contents.profile_title }}
                        assigned to your account
                    </a>
                </div>

                <div v-if="asset && !no_profile_error">
                    <div v-if="asset.template && $root.hasPermission('templates_view') && !template">
                        <loading></loading>
                    </div>
                    <div v-else class="row">
                        <div
                            :class="'col-lg-8 col-md-6 mt-2r mt-md-0 asset-preview'
                                + ($root.current_collection ? ' in-collection' : '')"
                        >
                            <div
                                v-if="(prevItem() || nextItem())"
                                class="d-md-none"
                                style="position: absolute; top: -36px"
                            >
                                <button
                                    type="button"
                                    :class="'prev-next close' + (prevItem() ? '' : ' disabled')"
                                    id="asset-details-prev-button-sm"
                                    :style="'float: none' + (prevItem() ? '' : '; cursor: default')"
                                    @click="viewItem(prevItem())"
                                >
                                    <span style="font-size: 22px">
                                        <i class="fal fa-arrow-alt-left"></i>
                                    </span>
                                </button>
                                <b-tooltip
                                    v-if="prevItem()"
                                    target="asset-details-prev-button-sm"
                                    triggers="hover"
                                >
                                    Previous
                                </b-tooltip>

                                <button type="button" class="close disabled" style="float: none; cursor: default">
                                    <span style="font-size: 20px; position: relative; top: -1px">
                                        <i class="fal fa-horizontal-rule fa-rotate-90"></i>
                                    </span>
                                </button>

                                <button
                                    type="button"
                                    :class="'prev-next close' + (nextItem() ? '' : ' disabled')"
                                    id="asset-details-next-button-sm"
                                    :style="'float: none' + (nextItem() ? '' : '; cursor: default')"
                                    @click="viewItem(nextItem())"
                                >
                                    <span style="font-size: 22px">
                                        <i class="fal fa-arrow-alt-right"></i>
                                    </span>
                                </button>
                                <b-tooltip
                                    v-if="nextItem()"
                                    target="asset-details-next-button-sm"
                                    triggers="hover"
                                >
                                    Next
                                </b-tooltip>
                            </div>
                            <div class="thumbnail">
                                <div
                                    v-if="!image_src
                                        || (editing_image && !editor_image && (approval_status == 'approved'))"
                                    class="image-loading"
                                >
                                    <loading></loading>
                                    <div v-if="image_src" class="text-center text-muted">
                                        Preparing image for editing
                                    </div>
                                </div>
                                <img
                                    v-if="image_src && (!editing_image || (approval_status != 'approved'))"
                                    class="preview-image"
                                    :src="image_src"
                                    :alt="asset.title"
                                >
                                <video
                                    v-if="image_src && video_src && (!editing_image || (approval_status != 'approved'))"
                                    class="video-player"
                                    :width="video_width"
                                    :height="video_height"
                                    controls
                                >
                                    <source :src="video_src" type="video/mp4">
                                </video>
                                <audio
                                    v-if="image_src && audio_src && (!editing_image || (approval_status != 'approved'))"
                                    class="audio-player"
                                    controls
                                >
                                    <source :src="audio_src" type="audio/mpeg">
                                </audio>
                                <div class="asset-image-editor" style="height: 100%">
                                    <image-editor
                                        v-if="edit_image"
                                        ref="imageEditor"
                                        id="asset-image-editor"
                                        :width="edit_image.width"
                                        :height="edit_image.height"
                                        :image="edit_image.src"
                                        v-on:imageLoaded="onImageLoaded"
                                        v-on:error="onImageEditorError"
                                        v-on:imageCropped="onImageCropped"
                                    ></image-editor>
                                </div>

                                <div
                                    v-if="canSelect()"
                                    :key="'select-overlay'"
                                    :class="'asset-select-overlay big' + (mobile_actions ? ' show' : '')"
                                >
                                    <div class="triangle"></div>
                                    <div
                                        class="tick"
                                        @click="!$root.current_collection ? selectAsset() : addToCollection()"
                                        v-b-tooltip.hover
                                        :title="!$root.current_collection ? 'Select' : 'Collect'"
                                    >
                                        <i class="fa-regular fa-check"></i>
                                    </div>
                                    <div class="ghost"></div>
                                </div>
                                <div
                                    v-if="canDeselect()"
                                    :key="'deselect-overlay'"
                                    :class="'asset-select-overlay big deselect' + (mobile_actions ? ' show' : '')"
                                >
                                    <div class="triangle"></div>
                                    <div
                                        class="tick"
                                        @click="selectAsset(false)"
                                        v-b-tooltip.hover
                                        :title="!$root.current_collection ? 'Deselect' : 'Remove'"
                                    >
                                        <span :key="'check-icon'" class="check-icon">
                                            <i class="fa-regular fa-check"></i>
                                        </span>
                                        <span :key="'minus-icon'" class="minus-icon">
                                            <i class="fa-regular fa-minus"></i>
                                        </span>
                                    </div>
                                    <div class="ghost"></div>
                                </div>

                                <div v-if="asset && !editing_image" class="paginator">
                                    <div v-if="(asset.number_of_pages > 1)" class="input-group">
                                        <div class="input-group-prepend">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                id="asset-view-prev"
                                                :disabled="(current_page == 1)"
                                                @click="prevPage()"
                                            ><i class="far fa-chevron-left"></i></button>
                                            <b-tooltip
                                                v-if="(current_page > 1)"
                                                target="asset-view-prev"
                                                triggers="hover"
                                            >Previous page</b-tooltip>
                                        </div>
                                        <input
                                            :value="'Page ' + current_page + ' of ' + asset.number_of_pages"
                                            type="text"
                                            class="form-control text-center px-1"
                                            :readonly="true"
                                            style="max-width: 120px"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                id="asset-view-next"
                                                :disabled="(current_page == asset.number_of_pages)"
                                                @click="nextPage()"
                                            ><i class="far fa-chevron-right"></i></button>
                                            <b-tooltip
                                                v-if="(current_page < asset.number_of_pages)"
                                                target="asset-view-next"
                                                triggers="hover"
                                            >Next page</b-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style="padding-top: 30px">
                            <div
                                v-if="(prevItem() || nextItem())"
                                class="d-none d-md-block"
                                style="position: absolute; top: -4px"
                            >
                                <button
                                    type="button"
                                    :class="'prev-next close' + (prevItem() ? '' : ' disabled')"
                                    id="asset-details-prev-button"
                                    :style="'float: none' + (prevItem() ? '' : '; cursor: default')"
                                    @click="viewItem(prevItem())"
                                >
                                    <span style="font-size: 22px">
                                        <i class="fal fa-arrow-alt-left"></i>
                                    </span>
                                </button>
                                <b-tooltip
                                    v-if="prevItem()"
                                    target="asset-details-prev-button"
                                    triggers="hover"
                                >
                                    Previous
                                </b-tooltip>

                                <button type="button" class="close disabled" style="float: none; cursor: default">
                                    <span style="font-size: 20px; position: relative; top: -1px">
                                        <i class="fal fa-horizontal-rule fa-rotate-90"></i>
                                    </span>
                                </button>

                                <button
                                    type="button"
                                    :class="'prev-next close' + (nextItem() ? '' : ' disabled')"
                                    id="asset-details-next-button"
                                    :style="'float: none' + (nextItem() ? '' : '; cursor: default')"
                                    @click="viewItem(nextItem())"
                                >
                                    <span style="font-size: 22px">
                                        <i class="fal fa-arrow-alt-right"></i>
                                    </span>
                                </button>
                                <b-tooltip
                                    v-if="nextItem()"
                                    target="asset-details-next-button"
                                    triggers="hover"
                                >
                                    Next
                                </b-tooltip>
                            </div>

                            <h5>{{ asset.title }}</h5>
                            <hr class="mt-0">
                            <div id="asset-details-tabs">
                                <ul class="nav nav-pills ad mb-4" role="tablist" style="padding-right: 60px">
                                    <li
                                        v-if="canViewTemplateOptions()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-template-options'"
                                            :class="'nav-link' + (isSuspended() ? ' bg-danger text-white' : '')
                                                + ((tab == 'template-options') ? ' active' : '')"
                                            id="f-template-options-tab"
                                            href="#"
                                            :aria-selected="(tab == 'template-options') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            :title="'Customise' + (isSuspended() ? ' - suspended' : '')"
                                            @click.prevent="editArtwork()"
                                        >
                                            <i class="fal fa-pen-paintbrush"></i>
                                        </a>
                                    </li>
                                    <li
                                        v-if="canViewPricePanel()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-view-print-order-prices'"
                                            class="nav-link"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="View prices"
                                            @click.prevent="resetViewPricePanel()"
                                        >
                                            <i v-if="($root.user.currency == 'pound')" class="fal fa-sterling-sign"></i>
                                            <i v-if="($root.user.currency == 'euro')" class="fal fa-euro-sign"></i>
                                            <i v-if="($root.user.currency == 'dollar')" class="fal fa-dollar-sign"></i>
                                        </a>
                                    </li>
                                    <li
                                        v-if="canOrderPrint()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-order-print'"
                                            :class="'nav-link' + (isSuspended() ? ' bg-danger text-white' : '')"
                                            href="#"
                                            v-b-tooltip.hover
                                            :title="'Order item' + (isSuspended() ? ' - suspended' : '')"
                                            @click.prevent="orderPrint()"
                                        >
                                            <i class="fal fa-shopping-cart"></i>
                                        </a>
                                    </li>
                                    <li v-if="canDownload()" class="nav-item" role="presentation">
                                        <a
                                            :key="'asset-details-download'"
                                            :class="'nav-link' + (isSuspended() ? ' bg-danger text-white' : '')
                                                + ((tab == 'download') ? ' active' : '')"
                                            id="f-download-tab"
                                            href="#"
                                            :aria-selected="(tab == 'download') ? 'true' : 'false'"
                                            @click.prevent="tab = 'download'"
                                        >
                                            <i class="fal fa-download"></i>
                                        </a>
                                        <b-tooltip target="f-download-tab" placement="top" triggers="hover" noninteractive>
                                            Download
                                            <span v-if="isSuspended()">- suspended</span>
                                            <span v-if="!isSuspended() && asset.restricted" class="ml-1">
                                                <i class="fal fa-lock-alt"></i>
                                            </span>
                                        </b-tooltip>
                                    </li>
                                    <li
                                        v-if="canShare()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-share'"
                                            :class="'nav-link' + ((tab == 'share') ? ' active' : '')"
                                            id="f-share-tab"
                                            href="#"
                                            :aria-selected="(tab == 'share') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            title="Share"
                                            @click.prevent="tab = 'share'"
                                        >
                                            <i class="fal fa-share-alt"></i>
                                        </a>
                                    </li>
                                    <li
                                        v-if="isCollection() && isCollaborator()
                                            && ($route.name != 'asset-approvals')"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-remove-from-collection'"
                                            class="nav-link collection"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove from collection"
                                            @click.prevent="selectAsset(false)"
                                        >
                                            <span style="position: relative">
                                                <i class="fa-light fa-rectangle-history"></i>
                                                <span id="rfc-minus">
                                                    <i class="far fa-minus stacked"></i>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    
                                    <li v-if="canEditImage()" class="nav-item" role="presentation">
                                        <a
                                            :key="'asset-details-edit-image'"
                                            :class="'nav-link' + (isSuspended() ? ' bg-danger text-white' : '')
                                                + ((tab == 'edit-image') ? ' active' : '')"
                                            id="f-edit-image-tab"
                                            href="#"
                                            :aria-selected="(tab == 'edit-image') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            :title="'Edit image' + (isSuspended() ? ' - suspended' : '')"
                                            @click.prevent="tab = 'edit-image'"
                                        >
                                            <i class="fal fa-crop-alt"></i>
                                        </a>
                                    </li>
                                    <li
                                        v-if="canViewLinkedFiles()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-linked-files'"
                                            :class="'nav-link' + ((tab == 'linked-files') ? ' active' : '')"
                                            id="f-linked-files-tab"
                                            href="#"
                                            :aria-selected="(tab == 'linked-files') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            title="Attachments"
                                            @click.prevent="tab = 'linked-files'"
                                            style="position: relative"
                                        >
                                            <i class="fal fa-paperclip"></i>
                                            <span
                                                v-if="(linked_files.length > 0)"
                                                class="badge badge-dark badge-pill"
                                                style="position: absolute; top: -7px; right: -5px; font-size: 9.75px"
                                            >
                                                <span v-if="(linked_files.length > 9)">9+</span>
                                                <span v-else>{{ linked_files.length }}</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li
                                        v-if="canViewSavedArtworks()"
                                        class="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            :key="'asset-details-template-saved'"
                                            :class="'nav-link' + ((tab == 'saved-artworks') ? ' active' : '')"
                                            id="f-saved-artworks-tab"
                                            href="#"
                                            :aria-selected="(tab == 'saved-artworks') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            title="Saved versions"
                                            @click.prevent="tab = 'saved-artworks'"
                                            style="position: relative"
                                        >
                                            <i class="fal fa-floppy-disk"></i>
                                            <span
                                                v-if="(num_saved_artworks > 0)"
                                                class="badge badge-dark badge-pill"
                                                style="position: absolute; top: -7px; right: -5px; font-size: 9.75px"
                                            >
                                                <span v-if="(num_saved_artworks > 9)">9+</span>
                                                <span v-else>{{ num_saved_artworks }}</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a
                                            :key="'asset-details-info'"
                                            :class="'nav-link' + ((tab == 'info') ? ' active' : '')"
                                            id="f-info-tab"
                                            href="#"
                                            :aria-selected="(tab == 'info') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            title="Info"
                                            @click.prevent="tab = 'info'"
                                        >
                                            <i class="fal fa-info-circle" style="position: relative; top: 1px"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item mobile-toggle" role="presentation">
                                        <a
                                            v-if="!mobile_actions"
                                            :key="'asset-details-more-actions'"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="More actions"
                                            @click.prevent="toggleMobileActions()"
                                        >
                                            <i class="far fa-ellipsis-h" style="position: relative; top: 1px"></i>
                                        </a>
                                        <a
                                            v-else
                                            :key="'asset-details-hide-actions'"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Hide more actions"
                                            @click.prevent="toggleMobileActions()"
                                        >
                                            <i class="fal fa-times" style="position: relative; top: 1px"></i>
                                        </a>
                                    </li>
                                    <li
                                        v-if="hasAdminPermission()"
                                        class="nav-item"
                                        role="presentation"
                                        style="position: absolute; right: 12px"
                                    >
                                        <a
                                            :key="'asset-details-admin'"
                                            :class="adminButtonClass()"
                                            id="f-admin-tab"
                                            href="#"
                                            :aria-selected="(tab == 'admin') ? 'true' : 'false'"
                                            v-b-tooltip.hover
                                            title="Admin"
                                            @click.prevent="toggleAdmin()"
                                            style="margin-right: 0"
                                        >
                                            <i class="fal fa-cog" style="position: relative; top: 1px"></i>
                                        </a>
                                    </li>
                                </ul>

                                <loading v-if="loading"></loading>

                                <div v-else class="tab-content">
                                    <div
                                        v-if="canViewTemplateOptions()"
                                        :class="'tab-pane fade' + ((tab == 'template-options') ? ' show active' : '')"
                                        id="f-template-options"
                                        role="tabpanel"
                                        aria-labelledby="f-template-options-tab"
                                    >
                                        <p>
                                            &nbsp;<!--EDIT-->
                                            <span class="float-right">
                                                {{ $root.ucfirst($root.contents.template_title) }}: {{ asset.id }}
                                            </span>
                                        </p>
                                        <div v-if="!showing_template_options">
                                            <button
                                                :key="'show-template-options-button'"
                                                type="button"
                                                class="btn btn-third btn-block"
                                                @click="showTemplateOptions()"
                                                :disabled="isSuspended()"
                                            >
                                                <i class="far fa-pen-paintbrush mr-1"></i>
                                                {{ isSuspended() ? 'This item is suspended' : 'Customise this item' }}
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button
                                                :key="'showing-template-options-button'"
                                                type="button"
                                                class="btn btn-third btn-block"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Opening editor</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        v-if="canDownload()"
                                        :class="'tab-pane fade' + ((tab == 'download') ? ' show active' : '')"
                                        id="f-download"
                                        role="tabpanel"
                                        aria-labelledby="f-download-tab"
                                    >
                                        <div v-if="isSuspended()" class="alert alert-danger" role="alert">
                                            This item is suspended
                                        </div>
                                        <loading v-if="!isSuspended() && (!asset_details || downloads_loading)"></loading>
                                        <div v-if="!isSuspended() && asset_details && !downloads_loading">
                                            <p v-if="(!canDownloadLinkedFiles() && (download_options.length == 0) && (approval_status != 'awaiting-approval'))">
                                                There are no available download options
                                            </p>
                                            <div v-else class="download-options-container pr-3">
                                                <div v-if="((download_options.length > 0) || (approval_status == 'awaiting-approval'))">
                                                    <p v-if="(approval_status != 'awaiting-approval')">
                                                        DOWNLOAD OPTIONS
                                                        <span class="float-right">
                                                            {{ $root.ucfirst($root.contents.asset_title) }}: {{ asset.id }}
                                                        </span>
                                                    </p>

                                                    <div
                                                        v-if="approvalInfo()"
                                                        class="alert alert-info"
                                                        v-html="approvalInfo()"
                                                    ></div>

                                                    <div v-if="(approval_status != 'awaiting-approval')">
                                                        <div
                                                            v-for="item in download_options"
                                                            :key="item.file_path"
                                                            class="form-control p-0 mb-3"
                                                            style="height: auto"
                                                        >
                                                            <div class="row no-gutters" style="min-height: 36px">
                                                                <div class="col-sm-4 col-5 pt-2 pb-2 pl-2">
                                                                    <span v-if="item.name">{{ item.name }}</span>
                                                                    <span style="text-transform: uppercase">
                                                                        {{ item.extension }}
                                                                    </span>
                                                                    <span
                                                                        v-if="item.colour"
                                                                        class="badge badge-info font-size-11"
                                                                        style="text-transform: uppercase"
                                                                    >
                                                                        {{ item.colour }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-sm-4 col-5 pt-2 pb-2">
                                                                    <span v-if="item.dimensions">
                                                                        {{ item.dimensions[0] }}x{{ item.dimensions[1] }}px
                                                                    </span>&nbsp;
                                                                </div>
                                                                <div class="col pt-2 pb-2 pl-2">
                                                                    {{ $root.formatFileSize(item.size) }}
                                                                </div>
                                                                <div class="col-sm-1 text-sm-right">
                                                                    <button
                                                                        v-if="(downloading != item.file_path)"
                                                                        class="btn btn-primary download-option-button"
                                                                        type="button"
                                                                        v-b-tooltip.hover.left
                                                                        title="Download"
                                                                        :disabled="(downloading
                                                                            || (approval_status == 'awaiting-approval'))"
                                                                        @click="downloadAsset(item)"
                                                                    >
                                                                        <i class="fal fa-download"></i>
                                                                    </button>
                                                                    <span v-else>
                                                                        <button
                                                                            class="btn btn-primary download-option-button"
                                                                            type="button"
                                                                        >
                                                                            <i class="fal fa-spinner-third fa-spin"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            v-if="asset.web_image"
                                                            class="text-center small text-muted mb-4"
                                                        >
                                                            NB: Smaller JPEG files will reduce the image quality
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="canDownloadLinkedFiles()">
                                                    <p>ATTACHMENTS</p>
                                                    <asset-linked-files
                                                        :data="linked_files"
                                                        :asset_id="asset ? asset.id : null"
                                                        :downloads_view="true"
                                                        :approval_status="approval_status"
                                                        :download_only="asset ? (asset.download_only ? true : false)
                                                            : true"
                                                    ></asset-linked-files>
                                                </div>

                                                <div v-if="canEditImage()">
                                                    <p>SOCIAL MEDIA AND OTHER FORMATS</p>
                                                    <!--<p>
                                                        <span
                                                            class="float-right contact-links font-size-22"
                                                            style="position: relative; top: -5px"
                                                        >
                                                            <span v-if="!social_media_expanded">
                                                                <a href="#" @click.prevent="social_media_expanded = true">
                                                                    <i class="far fa-chevron-circle-down"></i>
                                                                </a>
                                                            </span>
                                                            <span v-else><span>
                                                                <a href="#" @click.prevent="social_media_expanded = false">
                                                                    <i class="far fa-chevron-circle-up"></i>
                                                                </a>
                                                            </span></span>
                                                        </span>
                                                        <span @click="social_media_expanded = true">
                                                            SOCIAL MEDIA AND OTHER FORMATS
                                                        </span>
                                                    </p>-->
                                                    <div id="social-media-downloads">
                                                    <!--<div id="social-media-downloads" style="display: none">-->
                                                        <div class="form-control p-0 mb-3" style="height: auto">
                                                            <div class="row no-gutters" style="min-height: 36px">
                                                                <div class="col-sm-10 col-12 pt-2 pb-2 pl-2">
                                                                    Custom editor - Variable size
                                                                </div>
                                                                <div class="col-sm-2 text-sm-right">
                                                                    <button
                                                                        v-if="((!asset.restricted
                                                                            || (approval_status == 'approved'))
                                                                            && !editor_image)"
                                                                        :key="'loading-custom-edit'"
                                                                        class="btn btn-primary download-option-button"
                                                                        type="button"
                                                                    >
                                                                        <i class="fal fa-spinner-third fa-spin"></i>
                                                                    </button>
                                                                    <button
                                                                        v-else
                                                                        :key="'loaded-custom-edit'"
                                                                        class="btn btn-primary download-option-button"
                                                                        type="button"
                                                                        v-b-tooltip.hover.left
                                                                        title="Edit image"
                                                                        :disabled="(approval_status == 'awaiting-approval')"
                                                                        @click="initCropPreset(false, 0)"
                                                                    >
                                                                        <i
                                                                            class="fal fa-crop-alt"
                                                                            style="position: relative; top: 1px"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <loading v-if="($root.app.image_presets && !editor_image)"></loading>
                                                        <div v-if="($root.app.image_presets && editor_image)">
                                                            <div
                                                                v-for="item in $root.app.image_presets.groups"
                                                                :key="item.group"
                                                            >
                                                                <div
                                                                    v-for="preset in item.presets"
                                                                    :key="item.group + '-' + preset.name"
                                                                >
                                                                    <div
                                                                        v-if="((editor_image.original_width >= preset.width)
                                                                            && (editor_image.original_height >= preset.height))"
                                                                        class="form-control p-0 mb-3"
                                                                        style="height: auto"
                                                                    >
                                                                        <div class="row no-gutters" style="min-height: 36px">
                                                                            <div class="col-sm-6 col-7 pt-2 pb-2 pl-2">
                                                                                {{ item.group }} - {{ preset.name }}
                                                                            </div>
                                                                            <div class="col-sm-4 col-5 pt-2 pb-2 pl-2">
                                                                                {{ preset.width }}x{{ preset.height }}px
                                                                            </div>
                                                                            <div class="col-sm-2 text-sm-right">
                                                                                <button
                                                                                    v-if="((!asset.restricted
                                                                                        || (approval_status == 'approved'))
                                                                                        && !editor_image)"
                                                                                    :key="'loading-' + item.group + '-' + preset.name"
                                                                                    class="btn btn-primary download-option-button"
                                                                                    type="button"
                                                                                >
                                                                                    <i class="fal fa-spinner-third fa-spin"></i>
                                                                                </button>
                                                                                <button
                                                                                    v-else
                                                                                    :key="'loaded-' + item.group + '-' + preset.name"
                                                                                    class="btn btn-primary download-option-button"
                                                                                    type="button"
                                                                                    v-b-tooltip.hover.left
                                                                                    title="Edit image & download"
                                                                                    :disabled="(approval_status == 'awaiting-approval')"
                                                                                    @click="initCropPreset(item, preset)"
                                                                                >
                                                                                    <i class="fal fa-download"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="canShare()"
                                        :class="'tab-pane fade' + ((tab == 'share') ? ' show active' : '')"
                                        id="f-share"
                                        role="tabpanel"
                                        aria-labelledby="f-share-tab"
                                    >
                                        <div v-if="isSuspended()" class="alert alert-danger" role="alert">
                                            This item is suspended
                                        </div>
                                        <asset-share v-else :share_asset="asset" :is_modal="false"></asset-share>
                                    </div>

                                    <div
                                        v-if="(!asset.archived && canEditImage())"
                                        :class="'tab-pane fade' + ((tab == 'edit-image') ? ' show active' : '')"
                                        id="f-edit-image"
                                        role="tabpanel"
                                        aria-labelledby="f-edit-image-tab"
                                    >
                                        <div v-if="isSuspended()" class="alert alert-danger" role="alert">
                                            This item is suspended
                                        </div>
                                        <div v-else>
                                            <p>
                                                IMAGE EDITOR
                                                <span class="float-right">
                                                    {{ $root.ucfirst($root.contents.asset_title) }}: {{ asset.id }}
                                                </span>
                                            </p>
                                            <div
                                                v-if="(!downloads_loading && approvalInfo(true))"
                                                class="alert alert-info"
                                                v-html="approvalInfo(true)"
                                            ></div>
                                            <div
                                                v-if="(!downloads_loading && (approval_status == 'requires-approval'))"
                                                class="alert alert-info"
                                            >
                                                You need approval to edit or download this {{ $root.contents.asset_title }}.
                                                <a href="#" @click.prevent="showApprovalRequestForm()">Click here</a>
                                                to request approval.
                                            </div>

                                            <div
                                                class="btn-group btn-block mt-0 mb-2"
                                                role="group"
                                                aria-label="Undo/reset/redo"
                                            >
                                                <button
                                                    class="btn btn-secondary"
                                                    :disabled="((image_undo_list.length == 0) || isDisabled())"
                                                    @click="imageUndo()"
                                                >
                                                    <i class="far fa-reply mr-1"></i> Undo
                                                </button>
                                                <button
                                                    class="btn btn-secondary"
                                                    :disabled="((image_undo_list.length == 0) || isDisabled())"
                                                    @click="imageReset()"
                                                >
                                                    <i class="far fa-repeat mr-1"></i> Reset
                                                </button>
                                                <button
                                                    class="btn btn-secondary"
                                                    :disabled="((image_redo_list.length == 0) || isDisabled())"
                                                    @click="imageRedo()"
                                                >
                                                    <i class="far fa-share mr-1"></i> Redo
                                                </button>
                                            </div>

                                            <div class="row no-gutters mb-2">
                                                <div class="col-6 pr-1">
                                                    <button
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="isDisabled('crop')"
                                                        @click="initCrop(image_edit_mode != 'crop')"
                                                    >
                                                        <i class="far fa-crop-alt mr-1"></i> Crop
                                                    </button>
                                                </div>
                                                <div class="col-6 pl-1">
                                                    <button
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="isDisabled('resize')"
                                                        @click="initResize()"
                                                    >
                                                        <i class="far fa-expand-arrows mr-1"></i> Resize
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                class="form-control crop-tools mb-2"
                                                style="height: auto; max-height: 300px; overflow: auto"
                                            >
                                                <div v-if="showCropGroup('default')" class="row no-gutters">
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 'custom')"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle('custom')" @click="initCropCustom()">
                                                            <i class="fal fa-crop-alt font-size-20"></i>
                                                            <br>Custom
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 2)"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle(2)" @click="initCrop(2, 1)">
                                                            <i class="fal fa-square font-size-20"></i>
                                                            <br>Square
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 3)"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle(3)" @click="initCrop(3, 1.33)">
                                                            <i class="fal fa-rectangle-landscape font-size-20"></i>
                                                            <br>Landscape 4:3
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 4)"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle(4)" @click="initCrop(4, 1.78)">
                                                            <i class="fal fa-rectangle-wide font-size-20"></i>
                                                            <br>Landscape 16:9
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 5)"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle(5)" @click="initCrop(5, 0.75)">
                                                            <i class="fal fa-rectangle-portrait font-size-20"></i>
                                                            <br>Portrait 4:3
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!image_crop_preset || (image_crop_preset == 6)"
                                                        :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                    >
                                                        <button :class="cropButtonStyle(6)" @click="initCrop(6, 0.56)">
                                                            <i class="fal fa-rectangle-wide fa-rotate-90 font-size-20"></i>
                                                            <br>Portrait 16:9
                                                        </button>
                                                    </div>
                                                </div>

                                                <div v-if="$root.app.image_presets">
                                                    <div v-for="item in $root.app.image_presets.groups" :key="item.group">
                                                        <div v-if="showCropGroup(item.group)">
                                                            <hr v-if="!image_crop_preset" class="my-2">
                                                            <p class="text-center font-size-16 mb-2">{{ item.group }}</p>
                                                            <div class="row no-gutters">
                                                                <div
                                                                    v-for="preset in item.presets"
                                                                    :key="preset.name"
                                                                    :class="'col-sm-' + (image_crop_preset ? 12 : 6)"
                                                                    :style="'display: ' + (showCropPreset(item.group, preset)
                                                                        ? 'block' : 'none')"
                                                                >
                                                                    <button
                                                                        :key="item.group + '-' + preset.name"
                                                                        :class="cropButtonStyle(item.group + preset.name)"
                                                                        @click="initCropPreset(item, preset)"
                                                                    >
                                                                        <i :class="cropCustomPresetIcon(preset)"></i>
                                                                        <br>{{ preset.name }}
                                                                        <br>{{ preset.width }} x {{ preset.height }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-center pt-2">
                                                    <button
                                                        class="btn btn-primary mr-2"
                                                        :disabled="!image_crop_preset"
                                                        @click="applyCrop()"
                                                    >
                                                        <i class="far fa-check mr-1"></i> Apply
                                                    </button>
                                                    <button class="btn btn-light" @click="cancelCrop()">
                                                        <i class="far fa-times mr-1"></i> Cancel
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-control resize-tools mb-2" style="height: auto">
                                                <div class="form-group mt-3">
                                                    <div class="custom-control custom-checkbox text-center">
                                                        <input
                                                            v-model="image_lock_ratio"
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="lock-image-ratio"
                                                        >
                                                        <label class="custom-control-label" for="lock-image-ratio">
                                                            Lock aspect ratio
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" style="width: 70px">Width</span>
                                                        </div>
                                                        <input
                                                            v-model="image_width"
                                                            type="number"
                                                            class="form-control"
                                                            :min="imageMinWidth()"
                                                            :max="imageMaxWidth()"
                                                        >
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">px</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" style="width: 70px">Height</span>
                                                        </div>
                                                        <input
                                                            v-model="image_height"
                                                            type="number"
                                                            class="form-control"
                                                            :min="imageMinHeight()"
                                                            :max="imageMaxHeight()"
                                                        >
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">px</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <button class="btn btn-primary mr-2" @click="applyResize()">
                                                        <i class="far fa-check mr-1"></i> Apply
                                                    </button>
                                                    <button class="btn btn-light" @click="cancelResize()">
                                                        <i class="far fa-times mr-1"></i> Cancel
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="row no-gutters mb-2">
                                                <div class="col-6 pr-1">
                                                    <button
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="isDisabled('flip')"
                                                        @click="initFlip()"
                                                    >
                                                        <i class="far fa-image-polaroid fa-flip-vertical"></i>
                                                        <i class="far fa-long-arrow-alt-right"></i>
                                                        <i class="far fa-image-polaroid mr-1"></i>
                                                        Flip
                                                    </button>
                                                </div>
                                                <div class="col-6 pl-1">
                                                    <button
                                                        class="btn btn-secondary btn-block"
                                                        :disabled="isDisabled('rotate')"
                                                        @click="initRotate()"
                                                    >
                                                        <i class="far fa-sync-alt mr-1"></i> Rotate
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-control flip-tools mb-2" style="height: auto">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <button
                                                            class="btn btn-outline-light btn-block"
                                                            @click="applyFlip('X')"
                                                        >
                                                            <i class="fal fa-image-polaroid font-size-20"></i>
                                                            <i class="far fa-long-arrow-alt-right"></i>
                                                            <i
                                                                class="fal fa-image-polaroid fa-flip-horizontal font-size-20"
                                                            ></i>
                                                            <br>Horizontal
                                                        </button>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <button
                                                            class="btn btn-outline-light btn-block"
                                                            @click="applyFlip('Y')"
                                                        >
                                                            <i class="fal fa-image-polaroid font-size-20"></i>
                                                            <i class="far fa-long-arrow-alt-right"></i>
                                                            <i
                                                                class="fal fa-image-polaroid fa-flip-vertical font-size-20"
                                                            ></i>
                                                            <br>Vertical
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-control rotate-tools mb-2" style="height: auto">
                                                <div class="form-group" style="position: relative">
                                                    <i class="fas fa-circle default-centre-point"></i>
                                                    <div class="row">
                                                        <div class="col-2">
                                                            -180&deg;
                                                        </div>
                                                        <div class="col text-center">
                                                            0&deg;
                                                        </div>
                                                        <div class="col-2 text-right">
                                                            +180&deg;
                                                        </div>
                                                    </div>
                                                    <b-form-input
                                                        v-model="image_degrees"
                                                        type="range"
                                                        min="-180"
                                                        max="180"
                                                        style="position: relative; z-index: 1"
                                                    ></b-form-input>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input
                                                            v-model="image_degrees"
                                                            type="number"
                                                            class="form-control"
                                                            :min="-180"
                                                            :max="180"
                                                        >
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">degrees</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button
                                                class="btn btn-secondary btn-block mb-2"
                                                :disabled="isDisabled('filter')"
                                                @click="initFilters()"
                                            >
                                                <i class="far fa-sliders-h mr-1"></i> Filters
                                            </button>
                                            <div class="form-control filter-tools mb-2" style="height: auto">
                                                <div class="form-group" style="position: relative">
                                                    <i class="fas fa-circle default-centre-point"></i>
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <i class="fas fa-circle text-muted"></i>
                                                        </div>
                                                        <div class="col text-center">
                                                            Brightness
                                                        </div>
                                                        <div class="col-2 text-right">
                                                            <i class="fal fa-circle text-muted"></i>
                                                        </div>
                                                    </div>
                                                    <b-form-input
                                                        v-model="image_brightness"
                                                        type="range"
                                                        style="position: relative; z-index: 1"
                                                    ></b-form-input>
                                                </div>
                                                <div v-if="$root.app.image_greyscale" class="mb-2">
                                                    <hr class="mt-0">
                                                    <div class="custom-control custom-checkbox text-center">
                                                        <input
                                                            v-model="image_greyscale"
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="image-greyscale"
                                                        >
                                                        <label class="custom-control-label" for="image-greyscale">
                                                            Greyscale
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="(image_undo_list.length > 0)">
                                                <p class="mt-3">DOWNLOAD OPTIONS</p>
                                                <div class="form-control p-0 mb-3" style="height: auto">
                                                    <div class="row no-gutters" style="min-height: 36px">
                                                        <div class="col-3 pt-2 pb-2 pl-2">
                                                            JPEG
                                                        </div>
                                                        <div class="col-sm-4 col-5 pt-2 pb-2">
                                                            {{ image_width }}x{{ image_height }}px
                                                        </div>
                                                        <div class="col-sm-3 col-3 pt-2 pb-2 pl-2">
                                                            {{ getEditorImageFileSize('jpeg') }}
                                                        </div>
                                                        <div class="col-sm-2 text-sm-right">
                                                            <button
                                                                class="btn btn-primary download-option-button"
                                                                type="button"
                                                                v-b-tooltip.hover.left
                                                                title="Download"
                                                                :disabled="isDisabled()"
                                                                @click="$refs.imageEditor.getImage('jpeg',
                                                                    edit_image.download_filename + '.jpg')"
                                                            >
                                                                <i class="fal fa-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-control p-0 mb-3" style="height: auto">
                                                    <div class="row no-gutters" style="min-height: 36px">
                                                        <div class="col-3 pt-2 pb-2 pl-2">
                                                            PNG
                                                        </div>
                                                        <div class="col-sm-4 col-5 pt-2 pb-2">
                                                            {{ image_width }}x{{ image_height }}px
                                                        </div>
                                                        <div class="col-sm-3 col-3 pt-2 pb-2 pl-2">
                                                            {{ getEditorImageFileSize('png') }}
                                                        </div>
                                                        <div class="col-sm-2 text-sm-right">
                                                            <button
                                                                class="btn btn-primary download-option-button"
                                                                type="button"
                                                                v-b-tooltip.hover.left
                                                                title="Download"
                                                                :disabled="isDisabled()"
                                                                @click="$refs.imageEditor.getImage('png',
                                                                    edit_image.download_filename + '.png')"
                                                            >
                                                                <i class="fal fa-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="canViewLinkedFiles()"
                                        :class="'tab-pane fade' + ((tab == 'linked-files') ? ' show active' : '')"
                                        id="f-linked-files"
                                        role="tabpanel"
                                        aria-labelledby="f-linked-files-tab"
                                    >
                                        <div class="attachments-container pr-3">
                                            <p>
                                                ATTACHMENTS
                                                <span class="float-right">
                                                    {{ $root.ucfirst($root.contents.asset_title) }}: {{ asset.id }}
                                                </span>
                                            </p>
                                            <div
                                                v-if="linked_files.length == 0"
                                                class="alert alert-warning"
                                                role="alert"
                                            >
                                                This {{ $root.contents.asset_title }} has no attachments.
                                            </div>
                                            <asset-linked-files
                                                :data="linked_files"
                                                :asset_id="asset ? asset.id : null"
                                                :approval_info="approvalInfo()"
                                                :approval_status="approval_status"
                                                :download_only="asset ? (asset.download_only ? true : false) : true"
                                            ></asset-linked-files>
                                        </div>
                                    </div>

                                    <div
                                        v-if="canViewSavedArtworks()"
                                        :class="'tab-pane fade' + ((tab == 'saved-artworks') ? ' show active' : '')"
                                        id="f-saved-artworks"
                                        role="tabpanel"
                                        aria-labelledby="f-saved-artworks-tab"
                                    >
                                        <div class="artworks-container pr-3">
                                            <p>
                                                SAVED VERSIONS
                                                <span class="float-right">
                                                    {{ $root.ucfirst(asset.template ? $root.contents.template_title
                                                        : $root.contents.asset_title) }}: {{ asset.id }}
                                                </span>
                                            </p>
                                            <saved-artworks
                                                :asset="asset"
                                                :template="template"
                                                :editing_artwork="$root.editing_artwork"
                                            ></saved-artworks>
                                        </div>
                                    </div>

                                    <div
                                        :class="'tab-pane fade' + ((tab == 'info') ? ' show active' : '')"
                                        id="f-info"
                                        role="tabpanel"
                                        aria-labelledby="f-info-tab"
                                    >
                                        <div id="asset-information" class="pr-3">
                                            <p>
                                                <span style="text-transform: uppercase">
                                                    {{ asset.template ? $root.contents.template_title
                                                        : $root.contents.asset_title }} INFORMATION
                                                </span>
                                                <span class="float-right">
                                                    {{ $root.ucfirst(asset.template ? $root.contents.template_title
                                                        : $root.contents.asset_title) }}: {{ asset.id }}
                                                </span>
                                            </p>

                                            <div class="table-responsive">
                                                <table class="table table-sm m-0 mb-3">
                                                    <tbody>
                                                        <tr v-if="$root.client.show_asset_description">
                                                            <td>
                                                                {{ $root.ucfirst($root.contents.description_title) }}:
                                                                {{ asset.description }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $root.ucfirst($root.contents.asset_title) }} type:
                                                                {{ (asset_details ? asset_details.resource_type_name : '') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Original file format: {{ asset.file_type }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Original file size:
                                                                {{ $root.formatFileSize(asset.file_size) }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $root.ucfirst($root.contents.folder_title) }}:
                                                                {{ (asset_details ? asset_details.folder_name : '') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ (subfoldersCount() == 1)
                                                                    ? $root.ucfirst($root.contents.subfolder_title)
                                                                    : $root.ucfirst($root.contents.subfolders_title) }}:
                                                                {{ (asset_details ? asset_details.subfolder_names : '') }}
                                                            </td>
                                                        </tr>
                                                        <tr v-for="item in getAssetCustomFields()" :key="item.id">
                                                            <td>{{ item.name }}: {{ getCustomFieldValue(item) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Keyword{{ (keywordsCount() == 1) ? '' : 's' }}:
                                                                <span
                                                                    v-for="item in getKeywords()"
                                                                    :key="'akw' + item.index"
                                                                    class="contact-links ml-1 mr-1 mb-2"
                                                                    style="display: inline-block; white-space: nowrap"
                                                                >
                                                                    <span
                                                                        :class="'badge badge-soft-'
                                                                            + (item.detected ? 'primary' : 'secondary')
                                                                            + ' contact-links font-size-12'"
                                                                        :style="'position: relative'
                                                                            + ((item.detected || hasDetectedLabels())
                                                                            ? '; padding-left: 20px' : '')
                                                                            + ($root.isLoggedIn()
                                                                            ? '; cursor: pointer' : '')"
                                                                        @click="keywordSearch(item.keyword)"
                                                                    >
                                                                        <span
                                                                            v-if="item.detected"
                                                                            class="keyword-icon"
                                                                            :key="'akwid' + item.index"
                                                                        >
                                                                            <i class="fa-thin fa-microchip-ai"></i>
                                                                        </span>
                                                                        <span
                                                                            v-if="!item.detected && hasDetectedLabels()"
                                                                            class="keyword-icon font-size-14"
                                                                            style="left: 3px"
                                                                            :key="'akwi' + item.index"
                                                                        >
                                                                            <i class="fa-light fa-user"></i>
                                                                        </span>
                                                                        {{ item.keyword }}
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="overflow-wrap: anywhere">
                                                                Filename: {{ asset.original_filename }}
                                                            </td>
                                                        </tr>
                                                        <tr v-if="asset.template">
                                                            <td>
                                                                Specification:
                                                                <span v-if="template">
                                                                    <span v-html="template.specification_html"></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Notes:
                                                                <span v-if="asset_details">
                                                                    <span v-html="asset_details.notes_html"></span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="!asset.template">
                                                            <td>
                                                                Requires approval before download:
                                                                {{ asset.restricted ? 'Yes' : 'No' }}
                                                            </td>
                                                        </tr>
                                                        <tr v-else>
                                                            <td>
                                                                Approval required:
                                                                <span v-if="template">
                                                                    {{ (template.approval == 'Y') ? 'Yes' : 'No' }}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Output options: {{ getOutputOptions() }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Visible to user
                                                                group{{ (userGroupsCount() == 1) ? '' : 's' }}:
                                                                {{ (asset_details ? asset_details.user_group_names : '') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Review date:
                                                                {{ (review_date_formatted ? review_date_formatted : '') }}
                                                            </td>
                                                        </tr>
                                                        <tr v-if="imageTaken()">
                                                            <td v-html="imageTaken()"></td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                v-html="addedBy()"
                                                                :style="(editedBy() || replacedBy() || archivedBy()) ? ''
                                                                    : 'border-bottom: 1px solid #eff2f7'"
                                                            ></td>
                                                        </tr>
                                                        <tr v-if="editedBy()">
                                                            <td
                                                                v-html="editedBy()"
                                                                :style="(replacedBy() || archivedBy()) ? ''
                                                                    : 'border-bottom: 1px solid #eff2f7'"
                                                            ></td>
                                                        </tr>
                                                        <tr v-if="replacedBy()">
                                                            <td
                                                                v-html="replacedBy()"
                                                                :style="archivedBy() ? ''
                                                                    : 'border-bottom: 1px solid #eff2f7'"
                                                            ></td>
                                                        </tr>
                                                        <tr v-if="archivedBy()">
                                                            <td
                                                                v-html="archivedBy()"
                                                                style="border-bottom: 1px solid #eff2f7"
                                                            ></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            
                                            <div
                                                v-if="(!asset.template && !asset.download_only
                                                    && $root.hasPermission('assets_view'))"
                                                class="row"
                                            >
                                                <div class="col">
                                                    <div class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            data-toggle="modal"
                                                            data-target="#asset-metadata-modal"
                                                        >
                                                            Metadata
                                                        </button>
                                                    </div>
                                                </div>
                                                <!--<div class="col-sm-6">
                                                    <div
                                                        v-if="($root.hasPermission('assets_download')
                                                            && $root.hasPermission('assets_view_attachments'))"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="goToLinkedFiles()"
                                                            :disabled="(linked_files.length == 0)"
                                                        >
                                                            {{ linked_files.length }}
                                                            attachment{{ (linked_files.length != 1) ? 's' : '' }}
                                                        </button>
                                                    </div>
                                                </div>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="hasAdminPermission()"
                                        :class="'tab-pane fade' + ((tab == 'admin') ? ' show active' : '')"
                                        id="f-admin"
                                        role="tabpanel"
                                        aria-labelledby="f-admin-tab"
                                    >
                                        <div id="asset-admin-options">
                                            <p>
                                                &nbsp;<!--ADMIN OPTIONS-->
                                                <span class="float-right">
                                                    {{ $root.ucfirst(asset.template ? $root.contents.template_title
                                                        : $root.contents.asset_title) }}: {{ asset.id }}
                                                </span>
                                            </p>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div v-if="hasAdminPermission('edit')" class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="editAsset()"
                                                        >
                                                            Edit {{ asset.template ? '' : $root.contents.asset_title }} info
                                                        </button>
                                                    </div>
                                                    <div v-if="hasAdminPermission('replace')" class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="replaceAsset()"
                                                        >
                                                            Replace {{ asset.template ? $root.contents.template_title
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                    </div>
                                                    <div v-if="hasAdminPermission('view_versions')" class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="showPreviousVersions()"
                                                            :disabled="(previous_versions.length == 0)"
                                                        >
                                                            {{ previous_versions.length }}
                                                            previous version{{ (previous_versions.length != 1) ? 's' : '' }}
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="(asset.template && hasAdminPermission('edit')
                                                            && hasImageFields(template))"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            type="button"
                                                            :class="'btn btn-block '
                                                                + (template.images_managed ? 'btn-secondary' : 'btn-danger')"
                                                            data-toggle="modal"
                                                            data-target="#manage-images-modal"
                                                            @click="resetManageImages()"
                                                        >
                                                            Manage images
                                                        </button>
                                                    </div>
                                                    <div v-if="hasAdminPermission('suspend')" class="form-group">
                                                        <div
                                                            v-if="isSuspended()"
                                                            class="form-group"
                                                            :key="'template-go-live'"
                                                        >
                                                            <button
                                                                type="button"
                                                                class="btn btn-danger btn-block"
                                                                @click="unsuspendAsset()"
                                                            >
                                                                <span v-if="suspending">
                                                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                                </span>
                                                                Go live
                                                            </button>
                                                        </div>
                                                        <div v-else class="form-group" :key="'template-suspend'">
                                                            <button
                                                                type="button"
                                                                class="btn btn-secondary btn-block"
                                                                @click="suspendAsset()"
                                                            >
                                                                <span v-if="suspending">
                                                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                                </span>
                                                                Suspend
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div v-if="hasAdminPermission('edit_price_panel')" class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            data-toggle="modal"
                                                            data-target="#price-panel-edit-modal"
                                                            @click="resetPricePanelEditForm()"
                                                        >
                                                            Edit price panel
                                                        </button>
                                                    </div>
                                                    <div v-if="!asset.template && $root.hasPermission('assets_edit')">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            data-toggle="modal"
                                                            data-target="#asset-metadata-modal"
                                                        >
                                                            Edit metadata
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div v-if="hasAdminPermission('attachments')" class="form-group">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            data-toggle="modal"
                                                            data-target="#asset-linked-file-add-form-modal"
                                                            @click.prevent="resetLinkedFileAddForm()"
                                                        >
                                                            Add attachment
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="!asset.archived && hasAdminPermission('archive')"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            v-if="!archiving"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="archiveAsset()"
                                                        >
                                                            Archive {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                        <button v-else type="button" class="btn btn-secondary btn-block">
                                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                            Archiving {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="asset.archived && hasAdminPermission('dearchive')"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            v-if="!archiving"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="deArchiveAsset()"
                                                            :disabled="!asset_details"
                                                        >
                                                            De-archive {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                        <button v-else type="button" class="btn btn-secondary btn-block">
                                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                            De-archiving {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                    </div>
                                                    <div v-if="hasAdminPermission('delete')" class="form-group">
                                                        <button
                                                            v-if="!deleting"
                                                            :key="'delete-asset'"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="deleteAsset()"
                                                        >
                                                            Delete {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                        <button
                                                            v-else
                                                            :key="'deleting-asset'"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                        >
                                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                            Deleting {{ asset.template ? ''
                                                                : $root.contents.asset_title }}
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="(asset.template && hasAdminPermission('edit')
                                                            && hasMultipleFields(template))"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            type="button"
                                                            :class="'btn btn-block '
                                                                + (template.fields_managed ? 'btn-secondary'
                                                                : 'btn-danger')"
                                                            @click="resetManageFieldOrder()"
                                                        >
                                                            Manage field order
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="hasAdminPermission('download_original')"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            v-if="!downloading_original"
                                                            :key="'download-template'"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            @click="downloadOriginal()"
                                                        >
                                                            Download
                                                            {{ asset.template ? $root.contents.template_title
                                                                : 'original file' }}
                                                        </button>
                                                        <button
                                                            v-else
                                                            :key="'downloading-template'"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                        >
                                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                            Downloading
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="hasAdminPermission('download_history')"
                                                        class="form-group"
                                                    >
                                                        <button
                                                            :key="'download-history'"
                                                            type="button"
                                                            class="btn btn-secondary btn-block"
                                                            data-toggle="modal"
                                                            data-target="#asset-download-history-modal"
                                                            @click="resetDownloadHistory()"
                                                        >
                                                            Download history
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="asset-edit" class="pr-3">
                                            <loading v-if="!asset.subfolders"></loading>
                                            <asset-edit-form
                                                v-if="(editing && asset.subfolders)"
                                                :data="asset"
                                                :template="template"
                                            ></asset-edit-form>
                                        </div>

                                        <div id="asset-replace">
                                            <p style="text-transform: uppercase">
                                                REPLACE
                                                {{ asset.template ? $root.contents.template_title
                                                    : $root.contents.asset_title }}
                                            </p>
                                            <template-replace-form
                                                v-if="asset.template"
                                                :data="asset"
                                                :template="template"
                                            ></template-replace-form>
                                            <!--:locked_profile_fields="locked_profile_fields"-->
                                            <asset-replace-form
                                                v-else
                                                :data="asset"
                                                :has_linked_files="(linked_files.length > 0)"
                                            ></asset-replace-form>
                                        </div>

                                        <div
                                            v-if="(asset.template && hasAdminPermission('edit')
                                                && hasMultipleFields(template))"
                                            id="manage-field-order"
                                        >
                                            <p>MANAGE FIELD ORDER</p>
                                            <manage-field-order
                                                :template="template"
                                                v-on:pageChanged="setPage"
                                            ></manage-field-order>
                                        </div>

                                        <div id="asset-previous-versions" class="pr-3">
                                            <div
                                                class="font-size-22 contact-links float-right"
                                                style="position: relative; top: -6px"
                                            >
                                                <a href="#" @click.prevent="showPreviousVersions(false)">
                                                    <i class="fal fa-times mr-1"></i>
                                                </a>
                                            </div>
                                            <p>PREVIOUS VERSIONS</p>
                                            <asset-previous-versions
                                                v-if="showing_previous_versions"
                                                :data="previous_versions"
                                                :approval_info="approvalInfo()"
                                                :approval_status="approval_status"
                                            ></asset-previous-versions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="position: absolute; right: 22px; top: 12px">
                    <button type="button" class="close" aria-label="Close" @click="close()">
                        <span aria-hidden="true" style="font-size: 38px"><i class="fal fa-times"></i></span>
                    </button>
                </div>
            </div>

            <div
                v-if="$root.hasPermission('assets_manage_attachments')"
                class="modal fade"
                id="asset-linked-file-add-form-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="asset-linked-file-add-form-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-xl">
                    <asset-linked-file-add-form></asset-linked-file-add-form>
                </div>
            </div>

            <div
                v-if="$root.hasPermission('assets_view_attachments')"
                class="modal fade"
                id="asset-previous-version-linked-files-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="asset-previous-version-linked-files-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5
                                v-if="previous_version_linked_files"
                                class="modal-title"
                                id="asset-previous-version-linked-files-label"
                            >
                                Attachments: {{ previous_version_linked_files.title }}
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click="previous_version_linked_files = null"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <asset-linked-files
                                v-if="previous_version_linked_files"
                                :data="previous_version_linked_files.items"
                                :asset_id="asset ? asset.id : null"
                                :approval_info="approvalInfo()"
                                :approval_status="approval_status"
                                :previous_version="true"
                            ></asset-linked-files>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="asset">
            <div
                v-if="$root.hasPermission('assets_view') && !asset.download_only"
                class="modal fade"
                id="asset-metadata-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="asset-metadata-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <asset-metadata :asset="asset"></asset-metadata>
                </div>
            </div>
        </div>

        <div
            v-if="($root.hasPermission('templates_edit') && hasImageFields(template))"
            class="modal fade"
            id="manage-images-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="manage-images-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <manage-images :template="template"></manage-images>
                <!--:locked_profile_fields="locked_profile_fields"-->
            </div>
        </div>

        <div
            v-if="$root.hasPermission('printing_manage_price_panels')"
            class="modal fade"
            id="price-panel-edit-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="price-panel-edit-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <price-panel-edit-modal-wrapper></price-panel-edit-modal-wrapper>
            </div>
        </div>

        <div
            v-if="hasAdminPermission('download_history')"
            class="modal fade"
            id="asset-download-history-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="asset-download-history-modal-label"
            aria-hidden="true"
        >
            <div :class="'modal-dialog modal-dialog-scrollable' + ($root.isFeatured('profiles') ? ' modal-lg' : '')">
                <asset-download-history :asset="asset"></asset-download-history>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['in_share', 'email'],
        data () {
            return {
                errors: false,
                loading: true,
                asset: null,
                image_src: null,
                video_src: null,
                video_width: 0,
                video_height: 0,
                audio_src: null,
                review_date_formatted: null,
                get_date_time_formatted_response: null,
                asset_details: null,
                image_taken_at_formatted: '',
                created_at_formatted: '',
                updated_at_formatted: '',
                replaced_at_formatted: '',
                archived_at_formatted: '',
                downloads_loading: false,
                download_options: [],
                downloading: null,
                deleting: false,
                editing: false,
                details_changed: false,
                replacing: false,
                managing_field_order: false,
                archiving: false,
                previous_versions: [],
                showing_previous_versions: false,
                linked_files: [],
                previous_version_linked_files: null,
                approval_status: 'approved',
                approval_expired: false,
                approval_expires_at_formatted: null,
                image_file_sizes_interval: null,
                social_media_expanded: false,
                tab: this.$root.client.asset_details_default_tab,
                reload_assets_on_close: false,
                redirect_to_home_on_close: false,
                template: null,
                showing_template_options: false,
                suspending: false,
                num_saved_artworks: 0,
                downloading_original: false,
                //locked_profile_fields: [],
                current_page: 0,
                image_src_array: [],
                item_to_view: null,
                no_profile_error: false,
                mobile_actions: false,
                // image editor
                editing_image: false,
                edit_image: null,
                editor_image: false,
                image_edit_mode: null,
                image_undo_list: [],
                image_redo_list: [],
                image_crop_preset: null,
                image_width: null,
                image_height: null,
                image_lock_ratio: true,
                image_degrees: 0,
                freeze_degrees: false,
                image_brightness: 50,
                freeze_brightness: false,
                image_greyscale: false,
                freeze_greyscale: false
            }
        },
        mounted () {
            var vm = this;
            vm.image_file_sizes_interval = setInterval(function () {
                if (!vm.editing_image || !vm.editor_image || (vm.image_undo_list.length == 0) || vm.isDisabled()) {
                    return null;
                }
                vm.$refs.imageEditor.setImageFileSizes();
            }, 5999);
        },
        beforeDestroy() {
            clearInterval(this.image_file_sizes_interval);
        },
        watch: {
            current_page: function (val) {
                if (val) {
                    this.getPreviewImage(val);
                }
            },
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'review_date') {
                            this.review_date_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'image_taken_at') {
                            this.image_taken_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'created_at') {
                            this.created_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'updated_at') {
                            this.updated_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'replaced_at') {
                            this.replaced_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'archived_at') {
                            this.archived_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'approval_expires_at') {
                            this.approval_expires_at_formatted = val.date_time_formatted;
                        }
                    }
                }
            },
            editing: function (val) {
                if (val) {
                    $('#asset-admin-options').fadeOut(350, function () {
                        $('#asset-edit').fadeIn(350);
                    });

                    return null;
                }
                $('#asset-edit').fadeOut(350, function () {
                    $('#asset-admin-options').fadeIn(350);
                });
            },
            replacing: function (val) {
                if (val) {
                    $('#asset-admin-options').fadeOut(350, function () {
                        $('#asset-replace').fadeIn(350);
                    });

                    return null;
                }
                $('#asset-replace').fadeOut(350, function () {
                    $('#asset-admin-options').fadeIn(350);
                });
            },
            managing_field_order: function (val) {
                if (val) {
                    $('#asset-admin-options').fadeOut(350, function () {
                        $('#manage-field-order').fadeIn(350);
                    });

                    return null;
                }
                $('#manage-field-order').fadeOut(350, function () {
                    $('#asset-admin-options').fadeIn(350);
                });
            },
            showing_previous_versions: function (val) {
                if (val) {
                    $('#asset-admin-options').fadeOut(350, function () {
                        $('#asset-previous-versions').fadeIn(350);
                    });

                    return null;
                }
                $('#asset-previous-versions').fadeOut(350, function () {
                    $('#asset-admin-options').fadeIn(350);
                });
            },
            social_media_expanded: function (val) {
                if (val) {
                    $('#social-media-downloads').slideDown(350);
                    if (this.$root.is_xl_screen) {
                        $('.download-options-container').animate({ scrollTop: '+=300' }, 700);

                        return null;
                    }
                    if (this.$root.is_md_screen) {
                        $('.download-options-container').animate({ scrollTop: '+=150' }, 700);

                        return null;
                    }
                    $('html, body').animate({ scrollTop: '+=300' }, 700);

                    return null;
                }
                $('#social-media-downloads').slideUp(350);
            },
            tab: function (val) {
                if (this.editing && this.details_changed) {
                    if (this.tab == 'admin') {
                        return null;
                    }
                    this.tab = 'admin';
                    this.$parent.$parent.confirm_delete = {
                        title: 'Edit info',
                        text: 'You have not saved your changes. Would you like to continue without saving?',
                        component: this,
                        action: 'set-tab-' + val,
                        cancel_action: 'save-details',
                        buttons: {
                            yes: 'Continue',
                            cancel: 'Save changes',
                            cancel_icon: 'fa-save'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }
                this.setTab();
            },
            // image editor
            editing_image: function (val) {
                if (val) {
                    if (this.approval_status == 'requires-approval') {
                        //this.showApprovalRequestForm();

                        return null;
                    }
                    setTimeout(function () {
                        $('.asset-image-editor').show();
                    }, 200);

                    return null;
                }
                $('.asset-image-editor').hide();
                if (this.image_edit_mode == 'crop') {
                    this.cancelCrop();
                }
                this.image_edit_mode = null;
            },
            image_edit_mode: function (val) {
                (val == 'crop') ? $('.crop-tools').slideDown(350) : $('.crop-tools').slideUp(350);
                (val == 'resize') ? $('.resize-tools').slideDown(350) : $('.resize-tools').slideUp(350);
                (val == 'flip') ? $('.flip-tools').slideDown(350) : $('.flip-tools').slideUp(350);
                (val == 'rotate') ? $('.rotate-tools').slideDown(350) : $('.rotate-tools').slideUp(350);
                (val == 'filter') ? $('.filter-tools').slideDown(350) : $('.filter-tools').slideUp(350);
            },
            /*image_crop_preset: function (val) {
                if (val) {
                    setTimeout(function () {
                        const scrollTo = $('.crop-tools').innerHeight();
                        $('.crop-tools').animate({ scrollTop: scrollTo }, 700);
                    }, 700);
                }
            },*/
            image_width: function (val) {
                if (!val || (val < this.imageMinWidth())) {
                    this.image_width = this.imageMinWidth();

                    return null;
                }
                if (val > this.imageMaxWidth()) {
                    this.image_width = this.imageMaxWidth();

                    return null;
                }
                if (this.editor_image && this.image_lock_ratio) {
                    const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                    this.image_height = Math.round(val / ratio);
                }
            },
            image_height: function (val) {
                if (!val || (val < this.imageMinHeight())) {
                    this.image_height = this.imageMinHeight();

                    return null;
                }
                if (val > this.imageMaxHeight()) {
                    this.image_height = this.imageMaxHeight();

                    return null;
                }
                if (this.editor_image && this.image_lock_ratio) {
                    const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                    this.image_width = Math.round(val * ratio);
                }
            },
            image_lock_ratio: function (val) {
                if (val) {
                    const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                    const height = Math.round(this.image_width / ratio);
                    if (height < 10) {
                        height = 10;
                    }
                    this.image_height = height;
                }
            },
            image_degrees: function (val, oldVal) {
                if (!val && (val !== 0)) {
                    this.image_degrees = 0;

                    return null;
                }
                if (val > 180) {
                    this.image_degrees = 180;

                    return null;
                }
                if (val < -180) {
                    this.image_degrees = -180;

                    return null;
                }
                if (this.freeze_degrees) {
                    this.freeze_degrees = false;

                    return null;
                }
                this.applyRotate(val, oldVal);
            },
            image_brightness: function (val, oldVal) {
                if (this.freeze_brightness) {
                    this.freeze_brightness = false;

                    return null;
                }
                this.setBrightness(val, oldVal);
            },
            image_greyscale: function (val, oldVal) {
                if (this.freeze_greyscale) {
                    this.freeze_greyscale = false;

                    return null;
                }
                this.setGreyscale(val, oldVal);
            }
        },
        methods: {
            setAsset: function (asset, openTab = null) {
                this.loading = true;
                // image editor
                this.editing_image = false;
                this.edit_image = null;
                this.editor_image = null;
                //
                if (!asset) {
                    this.asset = null;

                    return null;
                }
                if (
                    !this.$root.hasPermission('assets_view')
                    && !this.$root.hasPermission('templates_view')
                    && !this.in_share
                ) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }

                if (this.$root.redirect_to_home) {
                    this.redirect_to_home_on_close = true;
                    this.$root.redirect_to_home = false;
                } else {
                    this.redirect_to_home_on_close = false;
                }

                this.no_profile_error = false;
                this.errors = false;
                this.editing = false;
                this.details_changed = false;
                this.replacing = false;
                this.managing_field_order = false;
                this.asset = asset;
                this.review_date_formatted = null;
                if (!this.asset.review_date) {
                    this.review_date_formatted = '';
                } else {
                    this.$root.getDateTimeFormatted(this, 'review_date', this.asset.review_date, 'j M Y');
                }
                this.asset_details = null;
                this.template = null;
                //this.locked_profile_fields = [];
                this.getAssetDetails();
                this.image_taken_at_formatted = '';
                this.created_at_formatted = '';
                this.updated_at_formatted = '';
                this.replaced_at_formatted = '';
                this.archived_at_formatted = '';
                if (this.asset.image_taken_at) {
                    this.$root.getDateTimeFormatted(this, 'image_taken_at', this.asset.image_taken_at, 'g:ia d/m/Y');
                }
                this.$root.getDateTimeFormatted(this, 'created_at', this.asset.created_at, 'g:ia d/m/Y');
                this.$root.getDateTimeFormatted(this, 'updated_at', this.asset.updated_at, 'g:ia d/m/Y');
                if (this.asset.replaced_at) {
                    this.$root.getDateTimeFormatted(this, 'replaced_at', this.asset.replaced_at, 'g:ia d/m/Y');
                }
                if (this.asset.archived) {
                    this.$root.getDateTimeFormatted(this, 'archived_at', this.asset.archived_at, 'g:ia d/m/Y');
                }
                this.downloads_loading = true;
                this.download_options = [];
                this.approval_status = (
                    asset.restricted
                    && (
                        this.$root.hasPermission('assets_approval_required')
                        || this.$root.hasPermission('assets_logging_required')
                    )
                ) ? 'requires-approval' : 'approved';
                this.approval_expired = false;
                this.approval_expires_at_formatted = null;
                this.image_src = null;
                this.video_src = null;
                this.audio_src = null;
                this.current_page = 0;
                this.image_src_array = [null];
                var vm = this;
                setTimeout(function () {
                    vm.current_page = 1;
                }, 500);
                this.previous_versions = [];
                this.showing_previous_versions = false;
                this.linked_files = [];
                this.num_saved_artworks = 0;
                if (!asset.template) {
                    if (this.$root.hasPermission('assets_view_versions')) {
                        this.getPreviousVersions();
                    }
                    if (this.$root.hasPermission('assets_view_attachments')) {
                        this.getLinkedFiles();
                    }
                }
                
                if (!openTab) {
                    if (!asset.template) {
                        openTab = this.$root.client.asset_details_default_tab;
                    } else {
                        openTab = this.$root.client.template_details_default_tab;
                    }
                }
                this.tab = openTab;

                this.social_media_expanded = false;

                if (this.$parent.view_asset_from_selection || (this.$route.name == 'asset-approvals')) {
                    return null;
                }
                const itemIndex = this.getItemIndex();
                let totalItems = this.$parent.total_items || this.$parent.items.length;
                if (this.$parent.component_name == 'view-collection') {
                    totalItems = (this.$parent.total_items_assets > 0)
                        ? this.$parent.total_items_linked_files + this.$parent.total_items_assets
                        : this.$parent.linked_file_ids.length + this.$parent.asset_ids.length;
                }
                if (
                    ((itemIndex < 0) || (itemIndex > (this.$parent.items.length - 3)))
                    && (totalItems > this.$parent.items.length)
                ) {
                    this.$parent.getAssets(this.$parent.current_page + 1, false);
                }
            },
            prevPage: function () {
                if (!this.image_src || (this.current_page == 1)) {
                    return null;
                }
                this.current_page--;
            },
            nextPage: function () {
                if (!this.image_src || (this.current_page == this.asset.number_of_pages)) {
                    return null;
                }
                this.current_page++;
            },
            setPage: function (page) {
                this.current_page = page;
            },
            close: function () {
                if (this.editing && this.details_changed) {
                    this.$parent.$parent.confirm_delete = {
                        title: 'Edit info',
                        text: 'You have not saved your changes. Would you like to continue without saving?',
                        component: this,
                        action: 'close',
                        cancel_action: 'save-details',
                        buttons: {
                            yes: 'Continue',
                            cancel: 'Save changes',
                            cancel_icon: 'fa-save'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }
                this.$parent.view_asset = null;
                if (this.reload_assets_on_close) {
                    this.reload_assets_on_close = false;
                    const pages = ['assets', 'assets-filtered', 'view-collection', 'shared', 'asset-approvals'];
                    if (pages.indexOf(this.$route.name) > -1) {
                        this.$parent.getAssets(1, false);
                    }
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');
                    if (component) {
                        component.reloadAssets();
                    }

                    return null;
                }
                if (this.redirect_to_home_on_close && (this.$route.name != 'home')) {
                    this.$router.push({ name: 'home' });
                }
            },
            getPreviewImage: function (page = 1, forceSvg = false) {
                this.image_src = null;
                var vm = this;
                if (this.image_src_array[page]) {
                    setTimeout(function () {
                        vm.image_src = vm.image_src_array[vm.current_page];
                    }, 500);

                    return null;
                }

                /**
                 * Send request to API
                 */
                let size = 'mediumres';
                if (this.asset.file_type == 'image/gif') {
                    size = 'highres';
                }
                if ((this.asset.file_extension == 'svg')/* && forceSvg*/) {
                    size = 'svg';
                }
                const shareId = (!this.$root.isLoggedIn() && this.in_share) ? this.in_share : 0;
                const email = (!this.$root.isLoggedIn() && this.in_share && this.email) ? this.email : null;
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.asset.id
                    + '/preview/' + size;
                if (shareId || (page > 1)) {
                    url += '/' + ((page > 1) ? page : 0);
                    if (shareId) {
                        url += '/' + shareId;
                        if (email) {
                            url += '/' + email;
                        }
                    }
                }

                axios({
                    url: url,
                    headers: shareId ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        /*if ((vm.asset.file_extension == 'svg') && (response.data.image_src == '/images/file-icon.png')) {
                            vm.getPreviewImage(1, true);

                            return null;
                        }*/
                        vm.image_src = response.data.image_src;
                        vm.image_src_array[vm.current_page] = response.data.image_src;
                        if (vm.current_page > 1) {
                            return null;
                        }
                        $('.video-player').hide();
                        setTimeout(function () {
                            vm.video_width = $('.preview-image').outerWidth();
                            vm.video_height = $('.preview-image').outerHeight();
                            $('.video-player').show();
                        }, 200);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            keywordsCount: function () {
                if (
                    !this.asset.keywords
                    && (!this.$root.client.assets_use_detected_labels || !this.asset.detected_labels)
                ) {
                    return 0;
                }
                let numKeywords = 0;
                let keywords = [];
                if (this.$root.client.assets_use_detected_labels && this.asset.detected_labels) {
                    keywords = this.asset.detected_labels.split(',');
                    numKeywords = keywords.length;
                }
                if (this.asset.keywords) {
                    keywords = this.asset.keywords.split(',');
                    numKeywords += keywords.length;
                }

                return numKeywords;
            },
            getKeywords: function () {
                if (
                    !this.asset.keywords
                    && (!this.$root.client.assets_use_detected_labels || !this.asset.detected_labels)
                ) {
                    return [];
                }
                let allKeywords = [];
                let keywords = [];
                if (this.asset.keywords) {
                    keywords = this.asset.keywords.split(',');
                    keywords.forEach((item) => {
                        allKeywords.push({
                            keyword: item
                        });
                    });
                }
                if (this.$root.client.assets_use_detected_labels && this.asset.detected_labels) {
                    keywords = this.asset.detected_labels.split(',');
                    keywords.forEach((item) => {
                        allKeywords.push({
                            keyword: item,
                            detected: true
                        });
                    });
                }
                keywords = [];
                allKeywords.forEach((item, index) => {
                    item.index = index;
                    keywords.push(item);
                });

                return keywords;
            },
            hasDetectedLabels: function () {
                if (!this.$root.client.assets_use_detected_labels) {
                    return false;
                }
                if (!this.asset) {
                    return false;
                }
                if (!this.asset.detected_labels) {
                    return false;
                }

                return true;
            },
            getAssetDetails: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.asset.id;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.asset_details = response.data.resource;
                    vm.asset.subfolders = response.data.resource.subfolders;
                    vm.asset.user_groups = response.data.resource.user_groups;
                    vm.asset.custom_fields = response.data.resource.custom_fields;
                    if (vm.asset.template && vm.$root.hasPermission('templates_view')) {
                        vm.getTemplate(vm.asset.id);
                        /*if (vm.$root.isFeatured('profiles')) {
                            vm.getProfileFields();
                        }*/
                    } else {
                        vm.setTab();
                    }
                    if (vm.canDownload()) {
                        if (
                            response.data.resource.restricted
                            && (
                                vm.$root.hasPermission('assets_approval_required')
                                || vm.$root.hasPermission('assets_logging_required')
                            )
                        ) {
                            vm.approval_status = 'requires-approval';
                            vm.getAssetApproval();

                            return null;
                        }
                    }
                    vm.getDownloadOptions();
                    vm.getEditorImage();
                })
                .catch(function (error) {
                    //
                });
            },
            getTemplate: function (assetId) {
                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + assetId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template = response.data.template;
                    /*if (vm.suspending) {
                        vm.suspending = false;

                        return null;
                    }*/
                    vm.setTab();
                })
                .catch(function (error) {
                    if (error.response.status == 406) {
                        vm.errors = 'You do not have a ' + vm.$root.contents.profile_title + ' assigned.';
                        vm.no_profile_error = true;
                        // Stop loading saved artwork.
                        setTimeout(function () {
                            const savedArtworksComponent = vm.$root.findComponent(vm, 'saved-artworks');
                            if (savedArtworksComponent) {
                                savedArtworksComponent.loading = false;
                            }
                        }, 2000);
                    }
                    vm.setTab();
                });
            },
            getProfileFields: function () {
                return null;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.profile_fields.forEach((item) => {
                        if (item.title_suffix == '_Lx') {
                            vm.locked_profile_fields.push(item.type + item.title);
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            hasImageFields: function (template) {
                if (!template) {
                    return false;
                }
                if (!template.fields) {
                    return false;
                }
                const qrSuffixes = this.$root.client.artwork_generate_qr_codes ? ['_QR', '_LQR', '_LQRx'] : [];
                let numImageFields = 0;
                template.fields.forEach((item) => {
                    if (
                        (item.type == 'I')
                        && (
                            ((item.title_suffix != '_Lx') && (qrSuffixes.indexOf(item.title_suffix) < 0))
                            /*|| (this.locked_profile_fields.indexOf('I' + item.title) < 0)*/
                        )
                    ) {
                        numImageFields++;
                    }
                });

                return (numImageFields > 0);
            },
            hasMultipleFields: function (template) {
                if (!template) {
                    return false;
                }
                if (!template.fields) {
                    return false;
                }
                let numFields = 0;
                let pageNumber = 0;
                template.fields.forEach((item) => {
                    if (numFields < 2) {
                        if (pageNumber != item.page_number) {
                            numFields = 0;
                            pageNumber = item.page_number;
                        }
                        numFields++;
                    }
                });

                return (numFields > 1);
            },
            setTab: function () {
                if (!this.asset_details) {
                    // Set tab after asset details loaded.

                    return null;
                }
                if (this.asset.template && this.$root.hasPermission('templates_view') && !this.template) {
                    // Set tab after template loaded.

                    return null;
                }
                var openTab = this.tab;
                if (
                    ((openTab == 'edit-image') && !this.canEditImage())
                    || ((openTab == 'share') && (this.asset.archived || !this.$root.hasPermission('assets_send')))
                    || ((openTab == 'linked-files') && !this.canViewLinkedFiles())
                    || ((openTab == 'saved-artworks') && !this.canViewSavedArtworks())
                    || ((openTab == 'admin') && !this.hasAdminPermission())
                ) {
                    openTab = this.asset.template
                        ? this.$root.client.template_details_default_tab
                        : this.$root.client.asset_details_default_tab;
                }
                if (
                    ((openTab == 'download') && !this.canDownload())
                    || ((openTab == 'template-options') && !this.canViewTemplateOptions())
                ) {
                    openTab = 'info';
                }

                setTimeout(function () {
                    $('#f-' + openTab + '-tab').click();
                }, 500);

                this.editing_image = (openTab == 'edit-image');

                if (openTab == 'admin') {
                    this.resetAdminTab();
                }

                if (!this.loading && (openTab == 'template-options') && !this.isSuspended()) {
                    this.showTemplateOptions();
                }

                if (this.tab !== openTab) {
                    this.tab = openTab;
                }
                this.loading = false;
            },
            getOutputOptions: function () {
                if (!this.asset) {
                    return '';
                }
                if (!this.asset.template) {
                    if (this.asset.web_image) {
                        return 'Web image';
                    }
                    let options = [];
                    if (this.asset.downloadable) {
                        options.push('Download');
                    }
                    if (this.$root.isFeatured('printing') && this.asset.print_order) {
                        options.push('Order item');
                    }

                    return options.join(', ');
                }
                if (!this.template) {
                    return '';
                }
                if (this.template.output_view_only) {
                    return 'View only';
                }
                if (this.template.output_image_only) {
                    return 'Web image';
                }
                let options = [];
                if (this.$root.isFeatured('printing') && this.template.output_order) {
                    options.push('Order item');
                }
                if (this.template.output_desktop) {
                    options.push('Desktop print');
                }
                if (this.template.output_download) {
                    options.push('Download');
                }
                if (this.template.output_email) {
                    options.push('Email');
                }

                return options.join(', ');
            },
            getAssetApproval: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-requests/!pagination|!declined|rs::' + this.asset.id + '|kw::'
                    + btoa(this.$root.user.email);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.requests.forEach((item) => {
                        if (item.approved) {
                            if (vm.isDateInPast(item.expires_at.substr(0, 10))) {
                                vm.approval_expired = true;
                            } else {
                                vm.approval_status = 'approved';
                                vm.$root.getDateTimeFormatted(vm, 'approval_expires_at', item.expires_at, 'd/m/Y');
                            }
                        } else if (vm.approval_status != 'approved') {
                            vm.approval_status = 'awaiting-approval';
                        }
                    });
                    if ((vm.approval_status == 'approved') || (vm.approval_status == 'awaiting-approval')) {
                        vm.approval_expired = false;
                    }
                    /*if (vm.approval_status == 'requires-approval') {
                        vm.showApprovalRequestForm();
                    }*/
                    vm.getEditorImage();
                    if (vm.approval_status == 'awaiting-approval') {
                        vm.downloads_loading = false;

                        return null;
                    }
                    vm.getDownloadOptions();
                })
                .catch(function (error) {
                    vm.downloads_loading = false;
                });
            },
            getEditorImage: function () {
                if (!this.canEditImage()) {
                    return null;
                }
                /*if (this.approval_status != 'approved') {
                    return null;
                }*/

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.asset.id
                    + '/editable-image';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.edit_image = {
                            width: $('.asset-details .thumbnail').innerWidth(),
                            height: $('.asset-details .thumbnail').innerHeight(),
                            src: response.data.image_src.toString(),
                            download_filename: response.data.download_filename
                        }
                        if (vm.editing_image) {
                            $('.asset-image-editor').show();
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getDownloadOptions: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/download-options';
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.download_options = response.data.download_options;
                    if (vm.asset.file_type == 'video/mp4') {
                        vm.downloadAsset(vm.download_options[0], true);
                    }
                    if (vm.asset.file_type == 'video/quicktime') {
                        // If MOV stream the MP4 version
                        const filePath = vm.download_options[0].file_path.replace(/_.mov/g, '_.mp4');
                        const downloadOption = {
                            extension: 'mp4',
                            file_path: filePath,
                            file_type: 'video/mp4',
                            filename: vm.download_options[0].filename
                        };
                        vm.downloadAsset(downloadOption, true);
                    }
                    if (vm.asset.file_type == 'audio/mpeg') {
                        vm.downloadAsset(vm.download_options[0], false, true);
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.downloads_loading = false
                );
            },
            showApprovalRequestForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'asset-approval-request-form');
                if (component) {
                    component.resetForm(this.asset, this);
                    setTimeout(function() {
                        $('#asset-approval-request-form-modal').modal('show');
                    }, 200);
                }
            },
            downloadAsset: function (download, streamVideo = false, streamAudio = false) {
                if (
                    !streamVideo
                    && !streamAudio
                    && this.asset.restricted
                    && (this.approval_status == 'requires-approval')
                ) {
                    this.showApprovalRequestForm();
                    
                    return null;
                }
                if (!streamVideo && !streamAudio) {
                    this.downloading = download.file_path;
                }
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'resource',
                    item_id: this.asset.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension,
                    stream_video: streamVideo,
                    stream_audio: streamAudio
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/request-download-token';
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    if (response.data.stream_video) {
                        vm.video_src = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/stream-video/'
                            + response.data.token;

                        return null;
                    }
                    if (response.data.stream_audio) {
                        vm.audio_src = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/stream-audio/'
                            + response.data.token;

                        return null;
                    }
                    window.location.href = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error && vm.downloading) {
                        vm.$parent.$parent.message = {
                            type: 'error',
                            title: 'Download',
                            text: error.response.data.error
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() => {
                    vm.downloading = null;
                    vm.downloading_original = false;
                });
            },
            canEditImage: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (this.asset.archived) {
                    return false;
                }
                if (!this.$root.hasPermission('assets_edit_image')) {
                    return false;
                }
                const editable = ['image/jpeg', 'image/pjpeg', 'image/png'];

                return (editable.indexOf(this.asset.file_type) > -1);
            },
            canViewLinkedFiles: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.template) {
                    return false;
                }
                if (!this.$root.hasPermission('assets_view_attachments')) {
                    return false;
                }
                if (this.in_share) {
                    if (this.$root.share_info.external_view_only && this.asset.download_only) {
                        return false;
                    }
                }

                return true;
            },
            canViewSavedArtworks: function () {
                if (!this.asset) {
                    return false;
                }
                if (!this.asset.template || this.asset.archived) {
                    return false;
                }
                if (!this.$root.hasPermission('templates_use')) {
                    return false;
                }

                return true;
            },
            canViewPricePanel: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (this.asset.archived) {
                    return false;
                }
                if (!this.$root.user) {
                    return false;
                }
                if (!this.$root.user.currency) {
                    return false;
                }
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                if (!this.asset.price_panels) {
                    return false;
                }

                return (this.asset.price_panels.length > 0);
            },
            resetViewPricePanel: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'price-panel-view');
                if (component) {
                    component.reset(this.asset.price_panels);
                    $('#price-panel-view-modal').modal('show');
                }
            },
            hasAdminPermission: function (action = null) {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (!this.asset.template) {
                    if ((!action || (action == 'edit')) && this.$root.hasPermission('assets_edit')) {
                        return true;
                    }
                    if ((!action || (action == 'replace')) && this.$root.hasPermission('assets_replace')) {
                        return true;
                    }
                    if (
                        (!action || (action == 'suspend'))
                        && !this.asset.archived
                        && this.$root.hasPermission('assets_manage_suspension')
                    ) {
                        return true;
                    }
                    if ((!action || (action == 'view_versions')) && this.$root.hasPermission('assets_view_versions')) {
                        return true;
                    }
                    if (
                        (!action || (action == 'attachments'))
                        && this.$root.hasPermission('assets_manage_attachments')
                    ) {
                        return true;
                    }
                    if ((!action || (action == 'archive')) && this.$root.hasPermission('assets_archive')) {
                        return true;
                    }
                    if ((!action || (action == 'dearchive')) && this.$root.hasPermission('assets_manage_archive')) {
                        return true;
                    }
                    if ((!action || (action == 'delete')) && this.$root.hasPermission('assets_delete')) {
                        return true;
                    }
                    if (
                        (!action || (action == 'edit_price_panel'))
                        && this.$root.hasPermission('printing_manage_price_panels')
                        && this.asset.print_order
                    ) {
                        return true;
                    }
                    if (
                        (!action || (action == 'download_original'))
                        && this.$root.hasPermission('assets_edit')
                        && (this.download_options.length > 0)
                    ) {
                        return true;
                    }
                    if ((!action || (action == 'download_history')) && this.$root.hasPermission('reports_assets')) {
                        return true;
                    }

                    return false;
                }
                if (!this.template) {
                    return false;
                }
                if ((!action || (action == 'edit')) && this.$root.hasPermission('templates_edit')) {
                    return true;
                }
                if (
                    (!action || (action == 'replace'))
                    && !this.asset.archived
                    && this.$root.hasPermission('templates_replace')
                ) {
                    return true;
                }
                if (
                    (!action || (action == 'suspend'))
                    && !this.asset.archived
                    && this.$root.hasPermission('templates_manage_suspension')
                ) {
                    return true;
                }
                if ((!action || (action == 'archive')) && this.$root.hasPermission('templates_archive')) {
                    return true;
                }
                if ((!action || (action == 'dearchive')) && this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if ((!action || (action == 'delete')) && this.$root.hasPermission('templates_delete')) {
                    return true;
                }
                if (
                    (!action || (action == 'download_original'))
                    && this.$root.hasPermission('templates_download_original')
                ) {
                    return true;
                }
                if (
                    (!action || (action == 'edit_price_panel'))
                    && this.$root.hasPermission('printing_manage_price_panels')
                    && this.template.output_order
                ) {
                    return true;
                }

                return false;
            },
            adminButtonClass: function () {
                let className = 'nav-link';
                if (this.tab == 'admin') {
                    className += ' active';
                }
                if (this.asset) {
                    if (!this.asset.archived) {
                        if (this.isSuspended()) {
                            className += ' bg-danger text-white';
                        } else if (this.asset.template && this.template) {
                            if (!this.template.fields_managed || !this.template.images_managed) {
                                className += ' bg-danger text-white';
                            }
                        }
                    }
                }

                return className;
            },
            toggleAdmin: function () {
                if (this.tab == 'admin') {
                    if (this.editing) {
                        if (this.details_changed) {
                            this.$parent.$parent.confirm_delete = {
                                title: 'Edit info',
                                text: 'You have not saved your changes. Would you like to continue without saving?',
                                component: this,
                                action: 'set-tab-admin',
                                cancel_action: 'save-details',
                                buttons: {
                                    yes: 'Continue',
                                    cancel: 'Save changes',
                                    cancel_icon: 'fa-save'
                                }
                            };
                            $('#confirm-delete-modal').modal('show');

                            return null;
                        }
                        this.editing = false;

                        return null;
                    }
                    if (this.showing_previous_versions) {
                        this.showing_previous_versions = false;

                        return null;
                    }
                    if (this.replacing) {
                        this.replacing = false;

                        return null;
                    }
                    if (this.managing_field_order) {
                        this.managing_field_order = false;

                        return null;
                    }
                    this.tab = 'info';

                    return null;
                }
                this.tab = 'admin';
            },
            editAsset: function () {
                this.editing = true;
            },
            archiveAsset: function () {
                if (!this.asset) {
                    return null;
                }
                if (this.asset.template) {
                    const component = this.$root.findComponent(this.$parent.$parent, 'template-archive');
                    if (component) {
                        component.resetForm(this.asset, this.$parent);
                        setTimeout(function() {
                            $('#template-archive-modal').modal('show');
                        }, 200);
                    }

                    return null;
                }
                const component = this.$root.findComponent(this.$parent.$parent, 'asset-archive');
                if (component) {
                    component.resetForm(this.asset, this.$parent);
                    setTimeout(function() {
                        $('#asset-archive-modal').modal('show');
                    }, 200);
                }
            },
            deArchiveAsset: function () {
                if (this.asset.template) {
                    const component = this.$root.findComponent(this.$parent.$parent, 'template-de-archive');
                    if (component) {
                        component.resetForm(this.asset_details, this.$parent);
                        setTimeout(function() {
                            $('#template-de-archive-modal').modal('show');
                        }, 200);
                    }

                    return null;
                }
                const component = this.$root.findComponent(this.$parent.$parent, 'asset-de-archive');
                if (component) {
                    component.resetForm(this.asset_details, this.$parent);
                    setTimeout(function() {
                        $('#asset-de-archive-modal').modal('show');
                    }, 200);
                }
            },
            replaceAsset: function () {
                if (!this.asset) {
                    return null;
                }
                this.replacing = true;
            },
            suspendAsset: function () {
                if (!this.asset || this.suspending) {
                    return null;
                }
                this.suspending = true;

                /**
                 * Send suspend asset request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/suspend';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset.suspended = true;
                    vm.reload_assets_on_close = true;
                    if (vm.$root.selected_assets.indexOf(vm.asset.id) > -1) {
                        const component = vm.$root.findComponent(vm.$parent, 'asset-selection');
                        if (component) {
                            component.reloadAssets();
                        }
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.suspending = false
                );
            },
            unsuspendAsset: function () {
                if (!this.asset || this.suspending) {
                    return null;
                }
                this.suspending = true;

                /**
                 * Send unsuspend asset request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/unsuspend';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset.suspended = false;
                    vm.reload_assets_on_close = true;
                    if (vm.$root.selected_assets.indexOf(vm.asset.id) > -1) {
                        const component = vm.$root.findComponent(vm.$parent, 'asset-selection');
                        if (component) {
                            component.reloadAssets();
                        }
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.suspending = false
                );
            },
            downloadOriginal: function () {
                if (!this.asset) {
                    return null;
                }
                if (!this.download_options) {
                    return null;
                }
                if (this.download_options.length == 0) {
                    return null;
                }
                this.downloading_original = true;
                this.downloadAsset(this.download_options[0]);
            },
            resetManageImages: function () {
                const component = this.$root.findComponent(this, 'manage-images');
                if (component) {
                    component.reset();
                }
            },
            resetManageFieldOrder: function () {
                const component = this.$root.findComponent(this, 'manage-field-order');
                if (component) {
                    this.managing_field_order = true;
                    component.reset();
                }
            },
            deleteAsset: function (confirmed = false) {
                if (!this.asset) {
                    return null;
                }
                if (confirmed) {
                    this.errors = false;
                    this.deleting = true;

                    /**
                     * Send delete resource request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/resource/' + this.asset.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$root.folders_changed = true;

                        // Deselect asset
                        let selectedAssets = vm.$root.selected_assets;
                        let itemIndex = -1;
                        selectedAssets.forEach((item, index) => {
                            if (item == this.asset.id) {
                                itemIndex = index;
                            }
                        });
                        if (itemIndex > -1) {
                            selectedAssets.splice(itemIndex, 1);
                        }
                        // Reload assets
                        const pages = ['assets', 'assets-filtered', 'view-collection', 'shared', 'asset-approvals'];
                        if (pages.indexOf(vm.$route.name) > -1) {
                            vm.$parent.getAssets();

                            return null;
                        }
                        vm.$parent.view_asset = null;
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = "Error deleting " + vm.$root.contents.asset_title + ", please try again";
                    })
                    .finally(() =>
                        vm.deleting = false
                    );

                    return null;
                }

                const assetText = 'Are you sure you want to delete: ' + this.asset.title + '?'
                    + '<br><br>This action cannot be undone.';
                const templateText = 'Deleting the ' + this.$root.contents.template_title
                    + ' removes it and all user created versions.'

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete ' + (this.asset.template ? this.$root.contents.template_title
                        : this.$root.contents.asset_title),
                    text: this.asset.template ? templateText : assetText,
                    component: this,
                    action: 'delete-asset',
                    buttons: {
                        yes: this.asset.template ? 'Delete' : 'Yes',
                        cancel: 'Cancel'
                    }
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'delete-asset') {
                    this.deleteAsset(true);
                }
                if (action.substr(0, 8) == 'set-tab-') {
                    this.editing = false;
                    this.details_changed = false;
                    this.tab = action.substr(8);
                }
                if (action == 'view-item') {
                    this.editing = false;
                    this.details_changed = false;
                    this.viewItem(this.item_to_view);
                }
                if (action == 'close') {
                    this.editing = false;
                    this.details_changed = false;
                    this.close();
                }
                if (action == 'save-details') {
                    const component = this.$root.findComponent(this, 'asset-edit-form');
                    if (component) {
                        component.onSubmit();
                    }
                }
            },
            goToLinkedFiles: function () {
                //$('#f-linked-files-tab').click();
                this.tab = 'linked-files';
            },
            getPreviousVersions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/previous-versions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.previous_versions = response.data.previous_versions;
                    if (response.data.previous_versions.length == 0) {
                        if (vm.showing_previous_versions) {
                            vm.showPreviousVersions(false);
                        }
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            showPreviousVersions: function (show = true) {
                this.showing_previous_versions = show;
            },
            getLinkedFiles: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.asset.id + '/linked-files';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.linked_files = response.data.linked_files;
                })
                .catch(function (error) {
                    //
                });
            },
            canOrderPrint: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (this.asset.archived || this.asset.template) {
                    return false;
                }
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                /*if (this.download_options.length == 0) {
                    return false;
                }*/

                return this.asset.print_order;
            },
            orderPrint: function () {
                if (this.asset.suspended) {
                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'print-order-form');
                if (component) {
                    let orderItem = this.asset;
                    orderItem.file = this.download_options[0];
                    component.resetForm(orderItem);
                    $('#print-order-modal').modal('show');
                }
            },
            isSuspended: function () {
                if (!this.asset) {
                    return true;
                }

                return this.asset.suspended ? true : false;
            },
            canViewTemplateOptions: function () {
                if (!this.asset) {
                    return false;
                }
                if (!this.asset.template) {
                    return false;
                }

                return (!this.asset.archived && this.$root.hasPermission('templates_use'));
            },
            canDownload: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.template || (!this.asset.downloadable && !this.asset.web_image)) {
                    return false;
                }
                if (!this.in_share && !this.$root.hasPermission('assets_download')) {
                    return false;
                }
                if (this.in_share) {
                    if (
                        this.$root.share_info.external_view_only
                        && (!this.$root.isLoggedIn() || this.asset.download_only)
                    ) {
                        return false;
                    }
                }

                return true;
            },
            canShare: function () {
                if (!this.asset) {
                    return false;
                }
                if (this.asset.download_only) {
                    return false;
                }
                if (this.asset.archived/* || !this.asset.downloadable*/) {
                    return false;
                }

                return this.$root.hasPermission('assets_send');
            },
            canDownloadLinkedFiles: function () {
                if (this.approval_status == 'awaiting-approval') {
                    return null;
                }

                return (this.$root.hasPermission('assets_view_attachments') && (this.linked_files.length > 0));
            },
            resetLinkedFileAddForm: function () {
                const component = this.$root.findComponent(this, 'asset-linked-file-add-form');
                if (component) {
                    component.resetForm(this.asset.id);
                }
            },
            resetPricePanelEditForm: function () {
                const component = this.$root.findComponent(this, 'price-panel-edit-modal-wrapper');
                if (component) {
                    component.reset(this.asset.price_panels);
                }
            },
            subfoldersCount: function () {
                if (!this.asset.subfolders) {
                    return 0;
                }

                return this.asset.subfolders.length;
            },
            userGroupsCount: function () {
                if (!this.asset.user_groups) {
                    return 0;
                }

                return this.asset.user_groups.length;
            },
            resetAdminTab: function () {
                this.editing = false;
                this.editing_image = false;
                this.replacing = false;
                this.managing_field_order = false;
                this.showing_previous_versions = false;
            },
            approvalInfo: function (imageEditor = false) {
                if (imageEditor) {
                    if (this.approval_status == 'awaiting-approval') {
                        return 'Your request to edit and download this ' + this.$root.contents.asset_title
                            + ' is awaiting approval';
                    }

                    return null;
                }
                if (!this.asset.restricted) {
                    return null;
                }
                if (this.approval_expired) {
                    if (this.$root.assets_from_approval_email.indexOf(this.asset.id) > -1) {
                        return 'Your approval to download this ' + this.$root.contents.asset_title + ' has expired';
                    }

                    return null;
                }
                if (this.approval_status == 'requires-approval') {
                    return null;
                }
                if (this.approval_status == 'awaiting-approval') {
                    return 'Your request to download this ' + this.$root.contents.asset_title + ' is awaiting approval';
                }

                let message = 'You can download this ' + this.$root.contents.asset_title;
                if (this.approval_expires_at_formatted) {
                    message += ' until midnight on ' + this.approval_expires_at_formatted;
                }

                return message;
            },
            keywordSearch: function (keyword) {
                if (!this.$root.isLoggedIn()) {
                    return null;
                }
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'keyword',
                    value: keyword,
                    text: '&quot;' + keyword.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'asset_filters'
                });

                if (this.$route.name == 'assets') {
                    this.$parent.getAssets();
                    console.log('get assets: search by keyword');

                    return null;
                }
                this.$router.push({ name: 'assets' });
            },
            getCustomFieldValue: function (customField) {
                if (!customField.value) {
                    return null;
                }
                if (customField.type == 'multiselect') {
                    const valueTrimmed = customField.value.substr(1, (customField.value.length - 2));
                    const values = valueTrimmed.split('][');
                    
                    return values.join(', ');
                }

                return customField.value;
            },
            getAssetCustomFields: function () {
                if (!this.asset.custom_fields) {
                    return [];
                }
                let items = [];
                this.asset.custom_fields.forEach((item) => {
                    if (item.asset_info) {
                        items.push(item);
                    }
                });

                return items;
            },
            imageTaken: function () {
                if (!this.image_taken_at_formatted) {
                    return null;
                }

                return 'Created: ' + this.image_taken_at_formatted;
            },
            addedBy: function () {
                if (!this.asset_details) {
                    return 'Added: ' + this.created_at_formatted;
                }
                if (!this.asset_details.uploader_name) {
                    return 'Added: ' + this.created_at_formatted;
                }

                return 'Added by: ' + this.asset_details.uploader_name + ' &nbsp; ' + this.created_at_formatted;
            },
            editedBy: function () {
                if (!this.updated_at_formatted || (this.updated_at_formatted == this.created_at_formatted)) {
                    return null;
                }
                if (!this.asset_details) {
                    return 'Edited: ' + this.updated_at_formatted;
                }
                if (!this.asset_details.editor_name) {
                    return 'Edited: ' + this.updated_at_formatted;
                }

                return 'Edited by: ' + this.asset_details.editor_name + ' &nbsp; ' + this.updated_at_formatted;
            },
            replacedBy: function () {
                if (!this.replaced_at_formatted) {
                    return null;
                }
                if (!this.asset_details) {
                    return 'Replaced: ' + this.replaced_at_formatted;
                }
                if (!this.asset_details.replacer_name) {
                    return 'Replaced: ' + this.replaced_at_formatted;
                }

                return 'Replaced by: ' + this.asset_details.replacer_name + ' &nbsp; ' + this.replaced_at_formatted;
            },
            archivedBy: function () {
                if (!this.archived_at_formatted) {
                    return null;
                }
                if (!this.asset_details) {
                    return 'Archived: ' + this.archived_at_formatted;
                }
                if (!this.asset_details.archiver_name) {
                    return 'Archived: ' + this.archived_at_formatted;
                }

                return 'Archived by: ' + this.asset_details.archiver_name + ' &nbsp; ' + this.archived_at_formatted;
            },
            isDateInPast: function (dateToCheck) {
                const today = new Date();
                const month = ('0' + (today.getMonth() + 1)).slice(-2);
                const day = ('0' + today.getDate()).slice(-2);

                return (dateToCheck < (today.getFullYear() + '-' + month + '-' + day));
            },
            prevItem: function () {
                const itemIndex = this.getItemIndex();
                if (itemIndex < 1) {
                    return null;
                }
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');
                    return component.assets[itemIndex - 1]
                }
                
                return this.$parent.items[itemIndex - 1];
            },
            nextItem: function () {
                const itemIndex = this.getItemIndex();
                if (itemIndex < 0) {
                    return null;
                }
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');

                    return (itemIndex < (component.assets.length - 1)) ? component.assets[itemIndex + 1] : null;
                }
                
                return (itemIndex < (this.$parent.items.length - 1)) ? this.$parent.items[itemIndex + 1] : null;
            },
            getItemIndex: function () {
                if (!this.asset) {
                    return -1;
                }
                let itemIndex = -1;
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');
                    if (!component) {
                        return -1;
                    }
                    if (component.assets.length == 0) {
                        return -1;
                    }
                    component.assets.forEach((item, index) => {
                    if (item.id == this.asset.id) {
                            itemIndex = index;
                        }
                    });

                    return itemIndex;
                }

                if (this.$parent.items.length == 0) {
                    return -1;
                }
                this.$parent.items.forEach((item, index) => {
                    if (item.id == this.asset.id) {
                        itemIndex = index;
                    }
                });

                return itemIndex;
            },
            viewItem: function (item) {
                if (!item) {
                    return null;
                }

                if (this.editing && this.details_changed) {
                    this.item_to_view = item;
                    this.$parent.$parent.confirm_delete = {
                        title: 'Edit info',
                        text: 'You have not saved your changes. Would you like to continue without saving?',
                        component: this,
                        action: 'view-item',
                        cancel_action: 'save-details',
                        buttons: {
                            yes: 'Continue',
                            cancel: 'Save changes',
                            cancel_icon: 'fa-save'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }

                this.$parent.view_asset_tab = this.tab;
                this.$parent.view_asset = item;
            },
            isSelected: function () {
                if (this.isCollection()) {
                    return (this.$root.collection_selected_assets.indexOf(this.asset.id) > -1);
                }

                return (this.$root.selected_assets.indexOf(this.asset.id) > -1);
            },
            selectAsset: function (select = true) {
                if (select) {
                    if (this.isCollection()) {
                        // Do anything?

                        return null;
                    }
                    if (this.$root.current_collection) {
                        this.$root.current_collection = null;
                        this.$root.selected_assets = [this.asset.id];
                        /*var vm = this;
                        setTimeout(function () {
                            vm.selectAsset();
                        }, 200);*/

                        return null;
                    }
                    this.$root.selected_assets.push(this.asset.id);

                    return null;
                }

                if (this.isCollection()) {
                    this.$parent.removeAssetsFromCollection([this.asset.id], true);
                    this.$parent.view_asset = null;

                    return null;
                }
                let selectedAssets = this.$root.selected_assets;
                const itemIndex = selectedAssets.indexOf(this.asset.id);
                if (itemIndex > -1) {
                    selectedAssets.splice(itemIndex, 1);
                }
            },
            addToCollection: function () {
                if (this.$root.current_collection) {
                    this.$root.selected_assets.push(this.asset.id);

                    return null;
                }
                const assetSelectionComponent = this.$root.findComponent(this.$parent, 'asset-selection');
                if (!assetSelectionComponent) {
                    return null;
                }
                const component = this.$root.findComponent(assetSelectionComponent, 'add-to-collection');
                if (component) {
                    component.reset([this.asset.id]);
                    $('#add-to-collection-modal').modal('show');
                }
            },
            isCollection: function () {
                return (this.$route.name == 'view-collection');
            },
            editArtwork: function () {
                if (this.loading) {
                    return null;
                }
                if (this.tab == 'template-options') {
                    if (!this.isSuspended()) {
                        this.showTemplateOptions();
                    }

                    return null;
                }
                this.tab = 'template-options';
            },
            showTemplateOptions: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'template-options');
                    if (component) {
                        this.showing_template_options = true;
                        component.reset(this.asset, null, null, this);
                    }
                }
            },
            isCollaborator: function () {
                if (!this.isCollection()) {
                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (!component) {
                    return false;
                }
                if (component.collaborators.length == 0) {
                    return false;
                }
                let found = false;
                component.collaborators.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        found = true;
                    }
                });

                return found;
            },
            doNothing: function () {
                return null;
            },
            getEditorImageFileSize: function (fileType) {
                if (!this.editor_image) {
                    return '';
                }
                const bytes = this.$refs.imageEditor.getImageFileSize(fileType);

                return this.$root.formatFileSize(bytes);
            },
            cropButtonStyle: function (preset) {
                if (this.image_crop_preset == preset) {
                    return 'btn btn-light crop-button-active btn-block';
                }

                return 'btn btn-outline-light btn-block';
            },
            cropCustomPresetIcon: function (preset) {
                if ((preset.width / preset.height) > 1.75) {
                    return 'fal fa-rectangle-wide font-size-20';
                }
                if ((preset.width / preset.height) > 1.01) {
                    return 'fal fa-rectangle-landscape font-size-20';
                }
                if ((preset.width / preset.height) < 0.58) {
                    return 'fal fa-rectangle-wide fa-rotate-90 font-size-20';
                }
                if ((preset.width / preset.height) < 0.99) {
                    return 'fal fa-rectangle-portrait font-size-20';
                }

                return 'fal fa-square font-size-20';
            },
            showCropGroup: function (group) {
                if (!this.image_crop_preset) {
                    if (!this.editor_image) {
                        return false;
                    }
                    if (group == 'default') {
                        return true;
                    }
                    let sizeOk = false;
                    this.$root.app.image_presets.groups.forEach((item) => {
                        if (item.group == group) {
                            item.presets.forEach((preset) => {
                                if (
                                    (this.editor_image.max_width >= preset.width)
                                    && (this.editor_image.max_height >= preset.height)
                                ) {
                                    sizeOk = true;
                                }
                            });
                        }
                    });

                    return sizeOk;
                }
                if (group == 'default') {
                    const defaultPresets = ['custom', 2, 3, 4, 5, 6];
                    if (defaultPresets.indexOf(this.image_crop_preset) > -1) {
                        return true;
                    }
                }
                let groupFound = false;
                this.$root.app.image_presets.groups.forEach((item) => {
                    if (item.group == group) {
                        item.presets.forEach((preset) => {
                            if (this.image_crop_preset == item.group + preset.name) {
                                groupFound = true;
                            }
                        });
                    }
                });

                return groupFound;
            },
            showCropPreset: function (group, preset) {
                if (this.image_crop_preset) {
                    if (this.image_crop_preset != group + preset.name) {
                        return false;
                    }
                }
                if ((this.editor_image.max_width >= preset.width) && (this.editor_image.max_height >= preset.height)) {
                    return true;
                }

                return false;
            },
            resetSupportForm: function (messageId) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    if (messageId == 'no-profile') {
                        component.resetForm(
                            'users',
                            'Please assign a ' + this.$root.contents.profile_title + ' to my account'
                        );
                    }
                }
            },
            canSelect: function () {
                if (!this.asset || this.editing_image) {
                    return false;
                }
                if (this.isCollection()) {
                    return false;
                }
                if (this.asset.archived || this.in_share || this.isSelected()) {
                    return false;
                }
                if ((this.$route.name == 'asset-approvals')) {
                    return false;
                }

                return true;
            },
            canDeselect: function () {
                if (!this.asset || this.editing_image) {
                    return false;
                }
                if (this.isCollection()) {
                    return false;
                }
                if (this.asset.archived || !this.isSelected()) {
                    return false;
                }
                if ((this.$route.name == 'asset-approvals')) {
                    return false;
                }

                return true;
            },
            toggleMobileActions: function () {
                this.mobile_actions = !this.mobile_actions;
                /*if (this.asset_menu_mobile_full) {
                    $('#' + this.prefix + 'asset-info' + this.id).addClass('hide');
                    $('#' + this.prefix + 'asset-menu' + this.id).addClass('full');

                    return null;
                }
                $('#' + this.prefix + 'asset-info' + this.id).removeClass('hide');
                $('#' + this.prefix + 'asset-menu' + this.id).removeClass('full');*/
            },
            resetDownloadHistory: function () {
                const component = this.$root.findComponent(this, 'asset-download-history');
                if (component) {
                    component.reset();
                }
            },
            // image editor
            onImageLoaded: function (event) {
                this.editor_image = {
                    original_width: event.newWidth,
                    original_height: event.newHeight,
                };
                this.imageReset(false);

                if (this.$root.edit_image_crop_preset) {
                    this.initCropPreset(this.$root.edit_image_crop_item, this.$root.edit_image_crop_preset);
                    this.$root.edit_image_crop_item = null;
                    this.$root.edit_image_crop_preset = null;
                }
            },
            onImageEditorError: function (event) {
                console.log(event);
                this.editor_image = null;
            },
            isDisabled: function (mode = null) {
                if (this.approval_status != 'approved') {
                    return true;
                }
                if (!this.editor_image) {
                    return true;
                }
                if (this.image_edit_mode == mode) {
                    return false;
                }
                if ((this.image_edit_mode == 'crop') || (this.image_edit_mode == 'resize')) {
                    return true;
                }

                return false;
            },
            imageUndo: function () {
                if (this.image_undo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_undo_list.length - 1;
                this.$refs.imageEditor.undo(this.image_undo_list[lastItemIndex].steps);

                this.image_redo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_undo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_undo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_undo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_undo_list[lastItemIndex].max_height;
                this.image_width = this.image_undo_list[lastItemIndex].width;
                this.image_height = this.image_undo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_undo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_undo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_undo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_undo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_undo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_undo_list[lastItemIndex].greyscale;
                }
                this.image_undo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();
            },
            imageReset: function (updateEditor = true) {
                if (updateEditor) {
                    this.$refs.imageEditor.reset();
                }
                this.image_edit_mode = null;
                this.image_width = this.editor_image.original_width;
                this.image_height = this.editor_image.original_height;
                this.editor_image.max_width = this.editor_image.original_width;
                this.editor_image.max_height = this.editor_image.original_height;
                this.image_lock_ratio = true;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                if (this.image_brightness != 50) {
                    this.freeze_brightness = true;
                    this.image_brightness = 50;
                }
                if (this.image_greyscale != false) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = false;
                }
                $('.btn-secondary').blur();
                this.image_undo_list = [];
                this.image_redo_list = [];
            },
            imageRedo: function () {
                if (this.image_redo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_redo_list.length - 1;
                this.$refs.imageEditor.redo(this.image_redo_list[lastItemIndex].steps);

                this.image_undo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_redo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_redo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_redo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_redo_list[lastItemIndex].max_height;
                this.image_width = this.image_redo_list[lastItemIndex].width;
                this.image_height = this.image_redo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_redo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_redo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_redo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_redo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_redo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_redo_list[lastItemIndex].greyscale;
                }
                this.image_redo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();
            },
            initCrop: function (preset, ratio = 0, width = null, height = null) {
                if (preset) {
                    this.image_edit_mode = 'crop';
                    if (preset === true) {
                        setTimeout(function () {
                            $('.crop-tools').animate({ scrollTop: 0 }, 10);
                        }, 200);

                        return null;
                    }
                    this.image_crop_preset = preset;
                    this.$refs.imageEditor.initCrop(ratio, width, height);

                    return null;
                }
                this.cancelCrop();
            },
            initCropCustom: function () {
                if (this.image_crop_preset == 'custom') {
                    return null;
                }
                this.image_crop_preset = 'custom';
                this.$refs.imageEditor.initCropCustom();
            },
            initCropPreset: function (item, preset) {
                 if (this.asset.restricted && (this.approval_status == 'requires-approval')) {
                    this.showApprovalRequestForm();
                    
                    return null;
                }
                if (!this.editing_image) {
                    //$('#f-edit-image-tab').click();
                    this.editing_image = true;
                    this.tab = 'edit-image';
                    if (!item) {
                        return null;
                    }
                    var vm = this;
                    var cropPresetItem = item;
                    var cropPreset = preset;
                    setTimeout(function () {
                        vm.initCropPreset(cropPresetItem, cropPreset);
                    }, 880);

                    return null;
                }
                this.initCrop(
                    item.group + preset.name,
                    (preset.width / preset.height),
                    preset.width,
                    preset.height
                );
            },
            applyCrop: function () {
                this.image_redo_list = [];
                if (this.image_crop_preset == 'custom') {
                    this.$refs.imageEditor.applyCropCustom();

                    return null;
                }
                this.$refs.imageEditor.applyCrop();
            },
            cancelCrop: function () {
                if (!this.$refs.imageEditor) {
                    return null;
                }
                this.$refs.imageEditor.cancelCrop();
                this.image_crop_preset = null;
                this.image_edit_mode = null;
            },
            onImageCropped: function (event) {
                this.image_undo_list.push({
                    edit_mode: null,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: (isNaN(this.image_crop_preset) && (this.image_crop_preset != 'custom')) ? 2 : 1
                });
                this.image_crop_preset = null;
                this.image_edit_mode = null;
                this.editor_image.max_width = event.width;
                this.editor_image.max_height = event.height;
                if (this.image_width > this.editor_image.max_width) {
                    this.image_width = this.editor_image.max_width;
                }
                if (this.image_height > this.editor_image.max_height) {
                    this.image_height = this.editor_image.max_height;
                }
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
            },
            initResize: function () {
                this.editor_image.temp_width = this.image_width;
                this.editor_image.temp_height = this.image_height;
                this.image_edit_mode = (this.image_edit_mode == 'resize') ? null : 'resize';
            },
            imageMinWidth: function () {
                if (!this.editor_image || !this.image_lock_ratio) {
                    return 1;
                }
                const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                const minWidth = Math.ceil(1 * ratio);

                return (minWidth >= 1) ? minWidth : 1;
            },
            imageMinHeight: function () {
                if (!this.editor_image || !this.image_lock_ratio) {
                    return 1;
                }
                const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                const minHeight = Math.ceil(1 / ratio);

                return (minHeight >= 1) ? minHeight : 1;
            },
            imageMaxWidth: function () {
                if (!this.editor_image) {
                    return 100;
                }
                if (!this.image_lock_ratio) {
                    return this.editor_image.max_width;
                }
                const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                const maxWidth = Math.floor(this.editor_image.max_height * ratio);

                return (maxWidth <= this.editor_image.max_width) ? maxWidth : this.editor_image.max_width;
            },
            imageMaxHeight: function () {
                if (!this.editor_image) {
                    return 100;
                }
                if (!this.image_lock_ratio) {
                    return this.editor_image.max_height;
                }
                const ratio = (this.editor_image.max_width / this.editor_image.max_height);
                const maxHeight = Math.floor(this.editor_image.max_width / ratio);

                return (maxHeight <= this.editor_image.max_height) ? maxHeight : this.editor_image.max_height;
            },
            applyResize: function () {
                this.image_undo_list.push({
                    edit_mode: null,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.editor_image.temp_width,
                    height: this.editor_image.temp_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.imageEditor.resize(this.image_width, this.image_height);
                this.image_edit_mode = null;
                this.editor_image.max_width = this.image_width;
                this.editor_image.max_height = this.image_height;
            },
            cancelResize: function () {
                this.image_edit_mode = null;
                this.image_width = this.editor_image.temp_width;
                this.image_height = this.editor_image.temp_height;
            },
            initFlip: function () {
                this.image_edit_mode = (this.image_edit_mode == 'flip') ? null : 'flip';
            },
            applyFlip: function (direction) {
                this.image_undo_list.push({
                    edit_mode: 'flip',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.imageEditor.applyFlip(direction);
            },
            initRotate: function () {
                this.image_edit_mode = (this.image_edit_mode == 'rotate') ? null : 'rotate';
            },
            applyRotate: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'rotate',
                    degrees: oldRange,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.imageEditor.rotate(range);
            },
            initFilters: function () {
                this.image_edit_mode = (this.image_edit_mode == 'filter') ? null : 'filter';
            },
            setBrightness: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'filter',
                    degrees: this.image_degrees,
                    brightness: oldRange,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                const level = ((range - 50) / 50);
                this.$refs.imageEditor.setBrightness(level);
            },
            setGreyscale: function (val, oldVal) {
                this.image_undo_list.push({
                    edit_mode: 'filter',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: oldVal,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.$refs.imageEditor.setGreyscale(val);
            }
        }
    }
</script>
<style scoped>
    .mt-2r {
        margin-top: 2rem;
    }

    .asset-details {
        display: none;
    }

    .asset-preview .thumbnail {
        height: 726px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    .asset-preview .thumbnail img {
        max-height: 724px;
    }

    .asset-preview .thumbnail .image-loading {
        position: absolute;
        height: 30px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .asset-preview .thumbnail .video-player {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .audio-player {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    #asset-edit {
        display: none;
        width: calc(100% + 15px);
        max-height: 548px;
        overflow-y: auto;
    }

    #asset-replace, #asset-previous-versions, #manage-field-order {
        display: none;
    }

    #asset-information,
    .download-options-container,
    .share-options-container,
    #asset-previous-versions,
    .attachments-container,
    .artworks-container {
        width: calc(100% + 15px);
        max-height: 563px;
        overflow-y: auto;
    }

    #asset-details-prev-button, #asset-details-next-button {
        opacity: 1;
    }

    #asset-details-prev-button.disabled, #asset-details-next-button.disabled {
        color: #000;
        opacity: 0.5;
    }

    .asset-image-editor {
        display: none;
    }

    .crop-tools, .resize-tools, .flip-tools, .rotate-tools, .filter-tools {
        display: none;
    }

    .size-centre-point {
        position: absolute;
        bottom: 7px;
        right: 0;
        color: #e6e9ec;
        font-size: 16px;
        z-index: 0;
    }

    .default-centre-point {
        position: absolute;
        bottom: 7px;
        left: calc(50% - 8px);
        color: #e6e9ec;
        font-size: 16px;
        z-index: 0;
    }

    .crop-button-active {
        background-color: #d6ddea;
    }

    .nav-pills.ad .nav-item .nav-link {
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        font-size: 36px;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    .nav-pills.ad .nav-item .nav-link.collection .stacked {
        position: absolute;
        left: 8px;
        bottom: 11px;
        font-size: 23px;
    }

    .paginator {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 200px;
        bottom: 1px;
    }

    .paginator .input-group-prepend {
        background-color: white;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .paginator .input-group-append {
        background-color: white;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .mobile-toggle {
        display: none;
    }

    .mobile-toggle a {
        font-size: 27px;
        line-height: 1.7;
    }

    .keyword-icon {
        font-size: 16px;
        line-height: 16px;
        position: absolute;
        top: 1px;
        left: 1px;
        height: 19px;
    }

    @media (max-width: 1539px) {
        .nav-pills.ad .nav-item .nav-link {
            padding: 0.1rem 0.4rem;
            font-size: 27px;
            width: 45px;
            height: 45px;
        }

        .nav-pills.ad .nav-item .nav-link.collection .stacked {
            left: 7px;
            top: 14px;
            font-size: 16px;
        }

    }

    @media (max-width: 1199px) {
        .asset-preview .thumbnail {
            height: 412px;
        }

        .asset-preview .thumbnail img {
            max-height: 410px;
        }

        #asset-edit {
            max-height: 274px;
        }

        #asset-information,
        .download-options-container,
        .share-options-container,
        #asset-previous-versions,
        .attachments-container,
        .artworks-container {
            max-height: 264px;
        }
    }

    @media (max-width: 991px) {
        .mobile-toggle {
            display: inline;
        }
    }

    @media (max-width: 767px) {
        .asset-preview .thumbnail {
            height: 382px;
        }

        .asset-preview .thumbnail img {
            max-height: 380px;
        }

        #asset-information,
        #asset-edit,
        .download-options-container,
        .share-options-container,
        #asset-previous-versions,
        .artworks-container {
            max-height: none;
        }

        .attachments-container {
            max-height: none;
        }
    }

    @media (max-width: 575px) {
        .asset-preview .thumbnail {
            height: 274px;
        }

        .asset-preview .thumbnail img {
            max-height: 272px;
        }
    }
</style>
