<template>
    <div class="container-fluid" id="template-add-form">
        <div class="card">
            <div class="card-body" style="position: relative">
                <div v-if="system_errors" class="alert alert-danger" role="alert">
                    {{ system_errors }}
                </div>
                <div v-if="updated" class="alert alert-success" role="alert">
                    <div class="row">
                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                        <div class="col" v-html="updated"></div>
                    </div>
                </div>

                <div class="row" :style="'display: ' + ((updated || system_errors) ? 'none' : 'flex')">
                    <div :class="'col-' + (((uploaded_files.length > 0) && canSubmit()) ? '6' : '12')">
                        <div class="dropzone-container">
                            <vue-dropzone
                                ref="templateDropzone"
                                id="template-dropzone"
                                :options="dropzone_options"
                                v-on:vdropzone-sending="onSendingFile"
                                v-on:vdropzone-upload-progress="onProgressUpdate"
                                v-on:vdropzone-success="onFileUploaded"
                                v-on:vdropzone-error="onUploadError"
                                v-on:vdropzone-drag-over="onDragOver"
                                v-on:vdropzone-drag-leave="onDragLeave"
                                v-on:vdropzone-drop="onDragLeave"
                                v-on:vdropzone-file-added="onFileAdded"
                                :useCustomSlot=true
                            >
                                <div v-if="(uploaded_files.length > 0)">
                                    <div
                                        v-for="file in uploaded_files"
                                        :key="file.id"
                                        :class="'dz-uploaded-file p-2 mb-1' + (file.checked ? ' checked' : '')"
                                        :id="file.id"
                                    >
                                        <div class="dz-thumbnail">
                                            <span v-if="!file.preview_image">
                                                <img
                                                    :src="$root.imagePlaceholder(file.filename)"
                                                    class="img-fluid"
                                                    alt=""
                                                >
                                            </span>
                                            <img
                                                v-else
                                                :src="file.preview_image"
                                                class="img-fluid"
                                                alt=""
                                                v-on:mouseover="viewImageIcon(file.id, true)"
                                                v-on:mouseout="viewImageIcon(file.id, false)"
                                            >
                                            <div
                                                v-if="file.preview_image"
                                                class="view-image-icon"
                                                :id="'view-image-icon' + file.id"
                                                v-b-tooltip.hover
                                                title="Enlarge"
                                                @click="viewImage(file)"
                                                v-on:mouseover="viewImageIcon(file.id, true)"
                                                v-on:mouseout="viewImageIcon(file.id, false)"
                                            >
                                                <i class="fal fa-expand-arrows"></i>
                                            </div>
                                        </div>
                                        <div
                                            v-if="(hasErrors(file.id) && file.temp_file)"
                                            :key="'incomplete-' + file.id"
                                            class="dz-upload-info mt-2 text-danger"
                                        >
                                            <span v-b-tooltip.hover title="Form incomplete - see highlighted fields">
                                                <i class="far fa-exclamation-triangle"></i>
                                                <span class="d-lg-none d-xl-inline">Form incomplete</span>
                                                <span class="d-none d-lg-inline d-xl-none">Incomplete</span>
                                            </span>
                                        </div>
                                        <div
                                            v-if="(!file.temp_file || !file.template.processed)"
                                            :key="'upload-info-' + file.id"
                                            class="dz-upload-info mt-2"
                                            :id="'upload-info' + file.id"
                                        >
                                            <i class="fal fa-clock mr-1"></i> Pending upload
                                        </div>
                                        <div class="mt-2" style="height: 25px">
                                            <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                            <div class="dz-remove">
                                                <a
                                                    v-if="!updating"
                                                    href="#"
                                                    v-b-tooltip.hover
                                                    title="Delete"
                                                    @click.prevent="removeUploadedFile(file.id)"
                                                >
                                                    <i class="fal fa-trash-alt"></i>
                                                </a>
                                                <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                            </div>
                                        </div>
                                        <div
                                            class="dz-progress mt-2"
                                            :id="'upload-progress' + file.id"
                                            style="width: 100%"
                                        >
                                            <div class="dz-upload"></div>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    href="#"
                                    class="dz-add-files"
                                    id="template-add-files"
                                    @click.prevent="doNothing()"
                                    :style="dzAddFilesStyle()"
                                >
                                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                    Click or drop file here to upload. Must be a PDF document containing editable fields.
                                </a>
                            </vue-dropzone>
                        </div>
                    </div>
                    <div
                        class="col-6"
                        :style="'display: ' + (((uploaded_files.length > 0) && canSubmit()) ? 'block' : 'none')"
                    >
                        <div v-if="((uploaded_files.length > 0) && canSubmit())" class="mt-3 mt-lg-0">
                            <div v-if="loading_edit_form"><loading></loading></div>

                            <div v-else>
                                <ul class="nav nav-tabs" role="tablist">
                                    <li v-if="!hasTemplateFieldErrors(selected_file.template)" class="nav-item">
                                        <a
                                            class="nav-link active"
                                            id="template-form-tab"
                                            data-toggle="tab"
                                            href="#template-form"
                                            role="tab"
                                            aria-controls="template-form"
                                            aria-selected="true"
                                            @click.prevent="tab = 'form'"
                                        >{{ $root.ucfirst($root.contents.template_title) }} details</a>
                                    </li>
                                    <li
                                        v-if="(selected_file.template.processed
                                            && hasTemplateFields(selected_file.template))
                                            || hasTemplateFieldErrors(selected_file.template)"
                                        class="nav-item"
                                    >
                                        <a
                                            :class="'nav-link'
                                                + (selected_file.checked || hasTemplateFieldErrors(selected_file.template)
                                                ? '' : ' text-muted') + (hasTemplateFieldErrors(selected_file.template)
                                                ? ' active' : '')"
                                            id="template-fields-tab"
                                            :data-toggle="(selected_file.checked ? 'tab' : '')"
                                            href="#template-fields"
                                            role="tab"
                                            aria-controls="template-fields"
                                            :aria-selected="hasTemplateFieldErrors(selected_file.template)
                                                || (tab == 'fields') ? 'true' : 'false'"
                                            @click.prevent="tab = 'fields'"
                                        >
                                            <div
                                                v-if="(opening_tab == 'fields')"
                                                :key="'template-fields-tab-opening'"
                                                class="mr-1"
                                                style="display: inline-block"
                                            >
                                                <loading align="left" size="1"></loading>
                                            </div>
                                            <div
                                                v-if="((opening_tab != 'fields')
                                                    && (hasTemplateFieldErrors(selected_file.template) !== false))"
                                                :key="'template-fields-tab-errors'"
                                                class="mr-1 text-danger"
                                                style="display: inline-block"
                                                v-b-tooltip.hover
                                                title="Some fields have errors"
                                            >
                                                <i class="far fa-exclamation-triangle"></i>
                                            </div>
                                            Field ordering
                                        </a>
                                    </li>
                                    <li
                                        v-if="(selected_file.template.processed
                                            && hasTemplateFields(selected_file.template, 'I'))
                                            && !hasTemplateFieldErrors(selected_file.template)"
                                        class="nav-item"
                                    >
                                        <a
                                            :class="'nav-link' + (selected_file.checked ? '' : ' text-muted')"
                                            id="template-images-tab"
                                            :data-toggle="(selected_file.checked ? 'tab' : '')"
                                            href="#template-images"
                                            role="tab"
                                            @click.prevent="tab = 'images'"
                                        >
                                            <div
                                                v-if="(opening_tab == 'images')"
                                                class="mr-1"
                                                style="display: inline-block"
                                            >
                                                <loading align="left" size="1"></loading>
                                            </div>
                                            Manage images
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content forms pt-3 pl-3 pr-3">
                                    <div
                                        v-if="!hasTemplateFieldErrors(selected_file.template)"
                                        class="tab-pane fade show active"
                                        id="template-form"
                                        role="tabpanel"
                                        aria-labelledby="template-form-tab"
                                    >
                                        <form v-on:submit.prevent="doNothing()">
                                            <div v-for="file in uploaded_files" :key="file.id">
                                                <div v-if="file.id == selected_file.id">
                                                    <div v-if="(uploaded_files.length > 1)" class="form-group details-for">
                                                        <label>Details for: {{ file.filename }}</label>
                                                    </div>

                                                    <div
                                                        v-if="(errors && hasErrors(file.id))"
                                                        v-html="errors"
                                                        class="alert alert-danger form-errors"
                                                        role="alert"
                                                    ></div>

                                                    <div class="form-group" :id="'title-fg' + file.id">
                                                        <label
                                                            :for="'title' + file.id"
                                                            :id="'title-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Title *
                                                        </label>
                                                        <input
                                                            v-model="file.title"
                                                            type="text"
                                                            :id="'title' + file.id"
                                                            class="form-control mandatory-field"
                                                            :readonly="(updating && !validating)"
                                                            :placeholder="placeholder(file, 'title')"
                                                            v-on:focus="placeholder(file, 'title', true)"
                                                            v-on:blur="placeholder(file, 'title', true)"
                                                            v-on:keyup="onFieldChange(file, 'title', null, true)"
                                                            v-on:change="onFieldChange(file, 'title', null, true)"
                                                        >
                                                    </div>
                                                    <div
                                                        v-if="$root.client.show_asset_description"
                                                        class="form-group"
                                                        :id="'description-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'description' + file.id"
                                                            :id="'description-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            {{ $root.ucfirst($root.contents.description_title) }}
                                                            <span v-if="$root.client.asset_description_required">*</span>
                                                        </label>
                                                        <input
                                                            v-model="file.description"
                                                            type="text"
                                                            :id="'description' + file.id"
                                                            class="form-control mandatory-field"
                                                            :readonly="(updating && !validating)"
                                                            :placeholder="placeholder(file, 'description')"
                                                            v-on:focus="placeholder(file, 'description', true)"
                                                            v-on:blur="placeholder(file, 'description', true)"
                                                            v-on:keyup="onFieldChange(file, 'description', null,
                                                                $root.client.asset_description_required)"
                                                            v-on:change="onFieldChange(file, 'description', null,
                                                                $root.client.asset_description_required)"
                                                        >
                                                    </div>
                                                    <div class="form-group" :id="'keywords-fg' + file.id">
                                                        <span
                                                            class="float-right contact-links font-size-18"
                                                            :id="'keywords-tooltip-' + file.id"
                                                            style="position: relative; top: -3px"
                                                        >
                                                            <i class="fal fa-question-circle"></i>
                                                        </span>
                                                        <b-tooltip
                                                            :target="'keywords-tooltip-' + file.id"
                                                            triggers="hover focus"
                                                        >
                                                            Please note that words you enter into the title and other fields will
                                                            already be counted as keywords.
                                                            <br><br>Please add additional keywords here to help more accurate
                                                            searching for users.
                                                        </b-tooltip>
                                                        <label
                                                            :for="'keywords' + file.id"
                                                            :id="'keywords-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Keywords *
                                                        </label>
                                                        <keywords-field-with-assistant
                                                            v-model="file.keywords"
                                                            :value="file.keywords"
                                                            :field_id="'keywords' + file.id"
                                                            field_class="form-control mandatory-field"
                                                            :readonly="(updating && !validating)"
                                                            placeholder="No keywords added"
                                                            keyword_placeholder="Add keywords manually - separate with commas"
                                                            :add_current_keyword="(updating || selecting_file || opening_tab)"
                                                            :keyword_categories="keyword_categories"
                                                            v-on:change="onFieldChange(file, 'keywords', null, true)"
                                                            :no_header="true"
                                                        ></keywords-field-with-assistant>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        :id="'asset_type_id-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'asset_type_id' + file.id"
                                                            :id="'asset_type_id-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            {{ $root.ucfirst($root.contents.asset_title) }} type *
                                                        </label>
                                                        <searchable-option-list
                                                            :key="'asset_type_id'"
                                                            v-model="file.asset_type_id"
                                                            :value="file.asset_type_id"
                                                            :options="asset_types"
                                                            :field_id="'asset_type_id' + file.id"
                                                            field_class="mandatory-field"
                                                            :readonly="(updating && !validating)"
                                                            v-on:change="onFieldChange(file, 'asset_type_id', null, true)"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <!--<div class="form-group" :id="'restricted-fg' + file.id">
                                                        <label>
                                                            {{ $root.ucfirst($root.contents.template_title) }} requires approval before
                                                            download
                                                        </label>
                                                        <div class="form-control" style="height: auto">
                                                            <div class="mr-4" style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'restricted0'"
                                                                        v-model="file.restricted"
                                                                        type="radio"
                                                                        :id="'restricted' + file.id + '-0'"
                                                                        class="custom-control-input"
                                                                        :value="0"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'restricted')"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'restricted' + file.id + '-0'"
                                                                    >No</label>
                                                                </div>
                                                            </div>
                                                            <div style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'restricted1'"
                                                                        v-model="file.restricted"
                                                                        type="radio"
                                                                        :id="'restricted' + file.id + '-1'"
                                                                        class="custom-control-input"
                                                                        :value="1"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'restricted')"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'restricted' + file.id + '-1'"
                                                                    >Yes</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>-->
                                                    <div
                                                        class="form-group"
                                                        :id="'folder_id-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'folder_id' + file.id"
                                                            :id="'folder_id-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            {{ $root.ucfirst($root.contents.folder_title) }} *
                                                        </label>
                                                        <searchable-option-list
                                                            :key="'folder_id'"
                                                            v-model="file.folder_id"
                                                            :value="file.folder_id"
                                                            :options="folders"
                                                            :field_id="'folder_id' + file.id"
                                                            field_class="mandatory-field"
                                                            :readonly="(updating && !validating)"
                                                            v-on:change="onFieldChange(file, 'folder_id', null, true)"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <div
                                                        v-if="hasSubfolders(file.folder_id)"
                                                        class="form-group"
                                                        :id="'subfolders-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'subfolders' + file.id"
                                                            :id="'subfolders-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            {{ $root.ucfirst($root.contents.subfolders_title) }}
                                                        </label>
                                                        <searchable-option-list
                                                            :key="'subfolders'"
                                                            v-model="file.subfolders"
                                                            :value="(file.subfolders || [])"
                                                            :multiple="true"
                                                            :options="getSubfolderOptions(file.folder_id)"
                                                            :field_id="'subfolders' + file.id"
                                                            :field_class="'form-control pb-2'"
                                                            :readonly="(updating && !validating)"
                                                            :placeholder="'You can choose more than one'"
                                                            v-on:change="onFieldChange(file, 'subfolders')"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <div v-for="cf in custom_fields" :key="cf.id">
                                                        <div
                                                            v-if="hasCustomField(file.folder_id, cf)"
                                                            class="form-group"
                                                            :id="'cf' + cf.id + '-fg' + file.id"
                                                        >
                                                            <label
                                                                :for="'cf' + cf.id  + file.id"
                                                                :id="'cf' + cf.id + '-label' + file.id"
                                                                class="mandatory-label"
                                                            >
                                                                {{ cf.name }} <span v-if="cf.required">*</span>
                                                            </label>
                                                            <input
                                                                v-if="(cf.type == 'text')"
                                                                v-model="file.custom_fields[cf.id]"
                                                                type="text"
                                                                class="form-control mandatory-field"
                                                                :id="'cf' + cf.id + file.id"
                                                                :readonly="(updating && !validating)"
                                                                v-on:keyup="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                                v-on:change="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                            >
                                                            <textarea
                                                                v-if="(cf.type == 'textarea')"
                                                                v-model="file.custom_fields[cf.id]"
                                                                class="form-control mandatory-field"
                                                                :id="'cf' + cf.id + file.id"
                                                                rows="4"
                                                                :readonly="(updating && !validating)"
                                                                v-on:keyup="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                                v-on:change="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                            ></textarea>
                                                            <searchable-option-list
                                                                :key="'cf' + cf.id"
                                                                v-if="(cf.type == 'multiselect')"
                                                                v-model="file.custom_fields[cf.id]"
                                                                :value="(file.custom_fields[cf.id] || [])"
                                                                :multiple="true"
                                                                :options="getCustomFieldOptions(cf)"
                                                                :field_id="'cf' + cf.id + file.id"
                                                                :field_class="'form-control mandatory-field pb-2'"
                                                                :readonly="(updating && !validating)"
                                                                v-on:change="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                            ></searchable-option-list>
                                                            <searchable-option-list
                                                                :key="'cf' + cf.id"
                                                                v-if="(cf.type == 'singleselect')"
                                                                v-model="file.custom_fields[cf.id]"
                                                                :value="file.custom_fields[cf.id]"
                                                                :options="getCustomFieldOptions(cf)"
                                                                :field_id="'cf' + cf.id + file.id"
                                                                field_class="mandatory-field"
                                                                :readonly="(updating && !validating)"
                                                                v-on:change="onFieldChange(file, 'custom_fields',
                                                                    cf.id, cf.required)"
                                                            ></searchable-option-list>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="(user_groups.length > 0)"
                                                        class="form-group"
                                                        :id="'user_groups-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'user_groups' + file.id"
                                                            :id="'user_groups-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            User group(s)
                                                            <span v-if="isUserGroupRequired()">*</span>
                                                        </label>
                                                        <searchable-option-list
                                                            :key="'user_groups'"
                                                            v-model="file.user_groups"
                                                            :value="(file.user_groups || [])"
                                                            :multiple="true"
                                                            :options="getUserGroupOptions()"
                                                            :field_id="'user_groups' + file.id"
                                                            :field_class="'form-control  mandatory-field pb-2'"
                                                            :readonly="(updating && !validating)"
                                                            :placeholder="'You can choose more than one'"
                                                            v-on:change="onFieldChange(file, 'user_groups')"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        :id="'specification-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'specification' + file.id"
                                                            :id="'specification-label' + file.id"
                                                        >
                                                            Specification
                                                        </label>
                                                        <textarea
                                                            v-model="file.template.specification"
                                                            :id="'specification' + file.id"
                                                            class="form-control"
                                                            rows="4"
                                                            :readonly="(updating && !validating)"
                                                            v-on:keyup="onFieldChange(file, 'specification')"
                                                            v-on:change="onFieldChange(file, 'specification')"
                                                        ></textarea>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        :id="'notes-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'notes' + file.id"
                                                            :id="'notes-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Notes
                                                        </label>
                                                        <textarea
                                                            v-model="file.notes"
                                                            :id="'notes' + file.id"
                                                            class="form-control mandatory-field"
                                                            rows="4"
                                                            :readonly="(updating && !validating)"
                                                            :placeholder="placeholder(file, 'notes')"
                                                            v-on:focus="placeholder(file, 'notes', true)"
                                                            v-on:blur="placeholder(file, 'notes', true)"
                                                            v-on:keyup="onFieldChange(file, 'notes')"
                                                            v-on:change="onFieldChange(file, 'notes')"
                                                        ></textarea>
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        :id="'review_date-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'review_date' + file.id"
                                                            :id="'review_date-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Review date (optional)
                                                        </label>
                                                        <div class="input-group">
                                                            <b-form-datepicker
                                                                v-model="file.review_date"
                                                                :id="'review_date' + file.id"
                                                                class="form-control"
                                                                start-weekday="1"
                                                                locale="en-GB"
                                                                :min="today()"
                                                                :hideHeader="true"
                                                                :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                                                :disabled="(updating_review_date || (updating && !validating))"
                                                            ></b-form-datepicker>
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-light dropdown-toggle date-presets-button"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                    :disabled="(updating && !validating)"
                                                                >
                                                                    Preset periods
                                                                    <i
                                                                        class="fas fa-chevron-down d-none d-xl-inline-block"
                                                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                                                    ></i>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                    <a
                                                                        v-for="preset in review_date_presets"
                                                                        :key="preset.month"
                                                                        class="dropdown-item"
                                                                        href="#"
                                                                        @click.prevent="setDate(file, 'review_date', preset.month)"
                                                                    >{{ preset.text }}</a>
                                                                </div>
                                                            </div>
                                                            <button
                                                                v-if="(file.review_date && (!updating || validating))"
                                                                class="btn btn-link clear-review-date"
                                                                @click="clearReviewDate(file)"
                                                            >
                                                                <i class="far fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" :id="'approval-fg' + file.id">
                                                        <label
                                                            :for="'approval' + file.id + '-N'"
                                                            :id="'approval-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Activate artwork approval? *
                                                        </label>
                                                        <div
                                                            :id="'approval' + file.id"
                                                            class="form-control mandatory-field"
                                                            style="height: auto"
                                                        >
                                                            <div class="mr-4" style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'approvalN'"
                                                                        v-model="file.template.approval"
                                                                        type="radio"
                                                                        :id="'approval' + file.id + '-N'"
                                                                        class="custom-control-input"
                                                                        value="N"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'approval', null
                                                                            , true)"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'approval' + file.id + '-N'"
                                                                    >No</label>
                                                                </div>
                                                            </div>
                                                            <div style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'approvalY'"
                                                                        v-model="file.template.approval"
                                                                        type="radio"
                                                                        :id="'approval' + file.id + '-Y'"
                                                                        class="custom-control-input"
                                                                        value="Y"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'approval', null
                                                                            , true)"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'approval' + file.id + '-Y'"
                                                                    >Yes</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--<div class="form-group" :id="'stock_item-fg' + file.id">
                                                        <label class="mandatory-label">
                                                            Stock item? *
                                                        </label>
                                                        <div
                                                            :id="'stock_item' + file.id"
                                                            class="form-control mandatory-field"
                                                            style="height: auto"
                                                        >
                                                            <div class="mr-4" style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'stock_item0'"
                                                                        v-model="file.template.stock_item"
                                                                        type="radio"
                                                                        :id="'stock_item' + file.id + '-0'"
                                                                        class="custom-control-input"
                                                                        :value="0"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'stock_item', null
                                                                            , true)"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'stock_item' + file.id + '-0'"
                                                                    >No</label>
                                                                </div>
                                                            </div>
                                                            <div style="display: inline-block">
                                                                <div class="custom-control custom-radio">
                                                                    <input
                                                                        :key="'stock_item1'"
                                                                        v-model="file.template.stock_item"
                                                                        type="radio"
                                                                        :id="'stock_item' + file.id + '-1'"
                                                                        class="custom-control-input"
                                                                        :value="1"
                                                                        :disabled="(updating && !validating)"
                                                                        @click="onFieldChange(file, 'stock_item', null
                                                                            , true)"
                                                                    >
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="'stock_item' + file.id + '-1'"
                                                                    >Yes</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="file.template.stock_item"
                                                        class="form-group"
                                                        :id="'stock_level-fg' + file.id"
                                                    >
                                                        <label :for="'stock_level' + file.id">
                                                            Stock level
                                                        </label>
                                                        <input
                                                            v-model="file.template.stock_level"
                                                            type="number"
                                                            min="0"
                                                            :id="'stock_level' + file.id"
                                                            class="form-control"
                                                            :readonly="(updating && !validating)"
                                                            v-on:keyup="onFieldChange(file, 'stock_level')"
                                                            v-on:change="onFieldChange(file, 'stock_level')"
                                                        >
                                                    </div>
                                                    <div class="form-group" :id="'stock_code-fg' + file.id">
                                                        <label :for="'stock_code' + file.id">
                                                            Stock code
                                                        </label>
                                                        <input
                                                            v-model="file.template.stock_code"
                                                            type="text"
                                                            :id="'stock_code' + file.id"
                                                            class="form-control"
                                                            :readonly="(updating && !validating)"
                                                            v-on:keyup="onFieldChange(file, 'stock_code')"
                                                            v-on:change="onFieldChange(file, 'stock_code')"
                                                        >
                                                    </div>-->
                                                    <div
                                                        class="form-group"
                                                        :id="'output_options-fg' + file.id"
                                                    >
                                                        <label
                                                            :for="'output_options' + file.id"
                                                            :id="'output_options-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Output options *
                                                        </label>
                                                        <searchable-option-list
                                                            :key="'output_options'"
                                                            v-model="output_options"
                                                            :multiple="true"
                                                            :value="getOutputOptionValues(file)"
                                                            :options="getOutputOptions(file)"
                                                            :field_id="'output_options' + file.id"
                                                            field_class="form-control pb-2 mandatory-field"
                                                            :placeholder="'You can choose more than one'"
                                                            :readonly="(updating && !validating)"
                                                            v-on:change="onFieldChange(file, 'output_options', null
                                                                , true)"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <div
                                                        v-if="(getOutputOptionValues(file).indexOf(0) > -1)"
                                                        class="form-group"
                                                        :id="'price_panels-fg' + file.id"
                                                    >
                                                        <label
                                                            :id="'price_panels-label' + file.id"
                                                            class="mandatory-label"
                                                        >
                                                            Price panel(s) *
                                                        </label>
                                                        <div
                                                            :id="'price_panels' + file.id"
                                                            class="form-control pb-0 mandatory-field"
                                                            style="height: auto"
                                                        >
                                                            <div v-if="file.price_panels.length > 0" class="form-group">
                                                                <div
                                                                    v-for="price_panel in file.price_panels"
                                                                    :key="price_panel.id"
                                                                    style="padding-top: 2px; padding-bottom: 2px"
                                                                >
                                                                    {{ price_panel.title }}
                                                                    <span class="float-right ml-2">
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-secondary btn-sm"
                                                                            v-b-tooltip.hover.left
                                                                            title="Remove"
                                                                            @click="removePricePanel(file,
                                                                                price_panel.id)"
                                                                            :disabled="(updating && !validating)"
                                                                            style="padding-top: 1px; padding-bottom: 1px"
                                                                        >
                                                                            <i class="far fa-trash-alt"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="form-group"
                                                                :id="'price_panel_type_id-fg' + file.id"
                                                            >
                                                                <label>Add price panel</label>
                                                                <searchable-option-list
                                                                    :key="'price_panel_type_id' + file.id"
                                                                    v-model="file.price_panel_type_id"
                                                                    :value="file.price_panel_type_id"
                                                                    :options="price_panel_types"
                                                                    :field_id="'price_panel_type_id' + file.id"
                                                                    search_placeholder="Select price panel type"
                                                                    :readonly="(updating && !validating)"
                                                                    v-on:change="onFieldChange(file,
                                                                        'price_panel_type_id', null, true)"
                                                                ></searchable-option-list>
                                                            </div>
                                                            <div
                                                                v-if="file.price_panel_type_id"
                                                                class="form-group"
                                                                :id="'price_panel_id-fg' + file.id"
                                                            >
                                                                <searchable-option-list
                                                                    :key="'price_panel_id'"
                                                                    v-model="file.price_panel_id"
                                                                    :multiple="true"
                                                                    :value="getPricePanelIds(file)"
                                                                    :options="getPricePanelOptions(
                                                                        file.price_panel_type_id)"
                                                                    :field_id="'price_panel_id' + file.id"
                                                                    search_placeholder="Select price panel to add"
                                                                    :readonly="(updating && !validating)"
                                                                    v-on:change="onFieldChange(file, 'price_panel_id',
                                                                        null, true)"
                                                                ></searchable-option-list>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div
                                        v-if="(selected_file.template.processed
                                            && hasTemplateFields(selected_file.template))
                                            || hasTemplateFieldErrors(selected_file.template)"
                                        :class="'tab-pane fade'
                                            + ((hasTemplateFieldErrors(selected_file.template) || (tab == 'fields'))
                                            ? ' show active' : '')"
                                        id="template-fields"
                                        role="tabpanel"
                                        aria-labelledby="template-fields-tab"
                                    >
                                        <div v-for="file in uploaded_files" :key="'fields' + file.id">
                                            <div v-if="file.id == selected_file.id">
                                                <div
                                                    v-if="(hasTemplateFieldErrors(file.template) !== false)"
                                                    class="alert alert-danger"
                                                    role="alert"
                                                >Some fields below have errors</div>
                                                <p v-else>
                                                    Drag &amp; drop fields into a logical order for the edit form
                                                </p>

                                                <div class="form-group">
                                                    <searchable-option-list
                                                        v-model="file.template.current_page"
                                                        :value="file.template.current_page"
                                                        :options="templatePages(file.template)"
                                                        :field_id="'current_page' + file.id"
                                                        :readonly="((updating && !validating)
                                                            || (templatePages(file.template).length == 1))"
                                                        search_placeholder="Select page"
                                                        v-on:change="selecting_template_page = true"
                                                    ></searchable-option-list>
                                                </div>

                                                <hr v-if="(file.template.current_page !== null)">

                                                <div
                                                    v-if="(!selecting_template_page
                                                        && (file.template.current_page !== null))"
                                                >
                                                    <draggable
                                                        v-model="file.template.fields[file.template.current_page]"
                                                        ghost-class="vue-drag-ghost"
                                                        tag="div"
                                                        animation="200"
                                                        class="form-group"
                                                    >
                                                        <div
                                                            v-for="item in
                                                                file.template.fields[file.template.current_page]"
                                                            :key="'template-field' + item.id"
                                                            :class="'form-control mb-1'
                                                                + (item.error ? ' field-error' : '')"
                                                            style="height: auto; cursor: move"
                                                        >
                                                            <div class="row">
                                                                <div class="col">
                                                                    {{ item.title }}
                                                                    <span v-if="item.group_id" class="small text-muted">
                                                                        (GID: {{ item.group_id }})
                                                                    </span>
                                                                    <p v-if="item.error" class="small text-danger mb-0">
                                                                        {{ item.error }}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="col-2 font-size-18 contact-links"
                                                                    v-b-tooltip.hover
                                                                    :title="((item.type == 'T') ? 'Text' : 'Image')
                                                                        + ' field'"
                                                                >
                                                                    <i
                                                                        :class="'fal '
                                                                            + ((item.type == 'T')
                                                                                ? 'fa-input-text' : 'fa-image')
                                                                            + ' float-right'"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </draggable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="(selected_file.template.processed
                                            && hasTemplateFields(selected_file.template, 'I')
                                            && !hasTemplateFieldErrors(selected_file.template))"
                                        class="tab-pane fade"
                                        id="template-images"
                                        role="tabpanel"
                                        aria-labelledby="template-images-tab"
                                    >
                                        <div v-for="file in uploaded_files" :key="'images' + file.id">
                                            <div v-if="file.id == selected_file.id">
                                                <div class="form-group">
                                                    <searchable-option-list
                                                        v-model="file.template.current_page"
                                                        :value="file.template.current_page"
                                                        :options="templatePages(file.template, 'I')"
                                                        :field_id="'current_page_images' + file.id"
                                                        :readonly="(updating && !validating)
                                                            || manage_images_updating_selected_images"
                                                        search_placeholder="Select page"
                                                        v-on:change="selecting_template_page = true"
                                                    ></searchable-option-list>
                                                    <!-- || (templatePages(file.template, 'I').length == 1)-->
                                                </div>
                                                <div
                                                    v-if="(!selecting_template_page
                                                        && (file.template.current_page !== null))"
                                                >
                                                    <div v-if="templateImages(file.template)" class="form-group">
                                                        <searchable-option-list
                                                            v-model="file.template.current_image"
                                                            :value="file.template.current_image"
                                                            :options="templateImages(file.template)"
                                                            :field_id="'current_image' + file.id"
                                                            :readonly="(updating && !validating)
                                                                || manage_images_updating_selected_images"
                                                            search_placeholder="Select image field"
                                                            v-on:change="selecting_template_image = true"
                                                        ></searchable-option-list>
                                                        <!-- || (templateImages(file.template).length == 1)-->
                                                    </div>
                                                    <div v-else>
                                                        There are no image fields on this page
                                                    </div>
                                                    <div
                                                        v-if="(templateImages(file.template)
                                                            && !selecting_template_image && file.template.current_image)"
                                                    >
                                                        <div
                                                            v-for="image in templateImages(file.template)"
                                                            :key="image.id"
                                                        >
                                                            <div v-if="(image.id == file.template.current_image)">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div
                                                                            v-if="$root.isFeatured('profiles')"
                                                                            class="form-group"
                                                                        >
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input
                                                                                    v-model="image.profile_images"
                                                                                    type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    :id="'profile_images' + image.id"
                                                                                    :disabled="(updating && !validating)"
                                                                                    v-on:change="onTemplateImageChange(
                                                                                        file.template, image)"
                                                                                >
                                                                                <label
                                                                                    class="custom-control-label"
                                                                                    :for="'profile_images' + image.id"
                                                                                >
                                                                                    {{ $root.ucfirst(
                                                                                        $root.contents.profile_title) }}
                                                                                    images
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input
                                                                                    v-model="image.user_images"
                                                                                    type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    :id="'user_images' + image.id"
                                                                                    :disabled="(updating && !validating)"
                                                                                    v-on:change="onTemplateImageChange(
                                                                                        file.template, image)"
                                                                                >
                                                                                <label
                                                                                    class="custom-control-label"
                                                                                    :for="'user_images' + image.id"
                                                                                >Allow user images</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <div class="custom-control custom-checkbox">
                                                                                <input
                                                                                    v-model="image.image_editing"
                                                                                    type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    :id="'image_editing' + image.id"
                                                                                    :disabled="(updating && !validating)"
                                                                                    v-on:change="onTemplateImageChange(
                                                                                        file.template, image)"
                                                                                >
                                                                                <label
                                                                                    class="custom-control-label"
                                                                                    :for="'image_editing' + image.id"
                                                                                >Allow image editing</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="form-group">
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-secondary btn-block"
                                                                                :disabled="((updating && !validating)
                                                                                    || manage_images_updating_selected_images)"
                                                                                @click.prevent="chooseAsset(image)"
                                                                            >
                                                                                <i class="far fa-images mr-1"></i>
                                                                                Choose {{ $root.contents.asset_title }}s
                                                                            </button>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-secondary btn-block"
                                                                                :disabled="((updating && !validating)
                                                                                    || manage_images_updating_selected_images)"
                                                                                data-toggle="modal"
                                                                                data-target="#template-image-upload-modal"
                                                                                @click.prevent="resetImageUpload()"
                                                                            >
                                                                                <i class="far fa-cloud-upload mr-1"></i>
                                                                                Upload images
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="(image.selected_images.length > 0)">
                                                                    <hr class="mt-0">
                                                                    <p>
                                                                        {{ image.selected_images.length }} SELECTED
                                                                        IMAGE{{ (image.selected_images.length == 1) ? ''
                                                                            : 'S' }}
                                                                    </p>
                                                                    <div
                                                                        v-if="!manage_images_updating_selected_images"
                                                                        :key="'selected-' + image.selected_images.length"
                                                                        class="pt-3 pl-3 pr-3 mb-3"
                                                                        :style="selected_images_styles"
                                                                    >
                                                                        <div class="row">
                                                                            <div
                                                                                v-for="item in image.selected_images"
                                                                                :key="item.id"
                                                                                :class="manageImagePreviewClass()"
                                                                            >
                                                                                <manage-image-preview
                                                                                    :data="item"
                                                                                    :readonly="(updating && !validating)"
                                                                                    v-on:preview="onTemplateImagePreviewed"
                                                                                    v-on:remove="onTemplateImageRemoved"
                                                                                ></manage-image-preview>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <loading v-if="manage_images_updating_selected_images">
                                                                </loading>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else><loading></loading></div>
                    </div>
                </div>

                <div
                    class="text-right"
                    :style="'position: relative; display: '
                        + (((uploaded_files.length > 0) && canSubmit()
                        && !hasTemplateFieldErrors(this.selected_file.template)) ? 'block' : 'none')"
                >
                    <hr>

                    <div class="progress-display">
                        <span v-if="(uploading || updating)" v-html="uploadProgress()"></span>
                        <div v-if="uploading" class="asset-overall-progress">
                            <div class="asset-overall-upload"></div>
                        </div>
                    </div>
                    <div v-if="(uploading || updating)" class="mb-2 d-block d-lg-none"></div>

                    <span v-if="!updating" class="d-none d-lg-inline">
                        <span>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="onSubmit()"
                                :disabled="!canSubmit()"
                            >
                                <span
                                    v-if="((submitButtonText() == 'Submit') || (submitButtonText() == 'Finish'))"
                                ><i class="far fa-check mr-1"></i></span>
                                {{ submitButtonText() }}
                            </button>
                        </span>
                    </span>
                    <span v-else class="d-none d-lg-inline">
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ submitButtonText() }}</span>
                        </button>
                    </span>

                    <div class="row no-gutters d-lg-none" style="width: 100%; margin: 0">
                        <div class="col">
                            <span v-if="!updating" class="button-holder">
                                <span class="button-holder">
                                    <button
                                        type="button"
                                        class="btn btn-primary submit-form"
                                        @click="onSubmit()"
                                        :disabled="!canSubmit()"
                                    >
                                        <span
                                            v-if="((submitButtonText() == 'Submit') || (submitButtonText() == 'Finish'))"
                                        ><i class="far fa-check mr-1"></i></span>
                                        {{ submitButtonText() }}
                                    </button>
                                </span>
                            </span>
                            <span v-else class="button-holder">
                                <button type="button" class="btn btn-primary submit-form">
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">{{ submitButtonText() }}</span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    import draggable from 'vuedraggable';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone,
            draggable,
        },
        data () {
            return {
                component_name: 'template-add-form',
                errors: false,
                system_errors: false,
                uploading: false,
                updating: false,
                updated: false,
                message: false,
                uploaded_files: [],
                upload_progress: {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                },
                selecting_file: false,
                selected_file: null,
                edit_request_file: null,
                loading_edit_form: false,
                num_assets_to_create: 0,
                assets_created: [],
                assets_failed: [],
                asset_types: [],
                folders: [],
                review_date_presets: [
                    {
                        month: 0,
                        text: 'Today'
                    },
                    {
                        month: 1,
                        text: 'In a month'
                    },
                    {
                        month: 6,
                        text: 'In 6 months'
                    },
                    {
                        month: 12,
                        text: 'In a year'
                    },
                    {
                        month: 24,
                        text: 'In 2 years'
                    },
                    {
                        month: 36,
                        text: 'In 3 years'
                    },
                    {
                        month: 60,
                        text: 'In 5 years'
                    },
                ],
                validating: false,
                block_file_select: false,
                user_groups: [],
                custom_fields: [],
                updating_review_date: false,
                keyword_categories: [],
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: '#template-add-files',   //, #template-add-more-files
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                selecting_template_page: false,
                selecting_template_image: false,
                on_close_redirect_to: null,
                tab: 'form',
                opening_tab: null,
                preview_resize_interval: null,
                manage_images_selected_asset_ids: null,
                manage_images_updating_selected_images: false,
                output_options: [],
                price_panel_types: [],
                selected_images_styles: 'max-height: 456px; overflow-x: hidden; overflow-y: auto; '
                    + 'background-color: #e4e4e4',
                //locked_profile_fields: []
            }
        },
        mounted () {
            var vm = this;
            $(window).bind('beforeunload', function(eventObject) {
                let returnValue = undefined;
                if (vm.uploaded_files.length > 0) {
                    returnValue = "Are you sure you want to leave this page? Any uploads will be cancelled.";
                }
                eventObject.returnValue = returnValue;

                return returnValue;
            });
        },
        beforeDestroy() {
            clearInterval(this.preview_resize_interval);
        },
        watch: {
            selecting_template_page: function (val) {
                if (val) {
                    if (this.tab == 'images') {
                        this.selected_file.template.current_image = null;
                    }
                    var vm = this;
                    setTimeout(function () {
                        if (vm.tab == 'images') {
                            const templateImages = vm.templateImages(vm.selected_file.template);
                            if (templateImages) {
                                if (templateImages.length == 1) {
                                    vm.selected_file.template.current_image = templateImages[0].id;
                                }
                            }
                        }
                        vm.selecting_template_page = false;
                    }, 500);
                }
            },
            selecting_template_image: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.selecting_template_image = false;
                    }, 500);
                }
            },
            uploading: function (val) {
                if (!val && (this.uploaded_files.length > 1)) {
                    $('.asset-overall-upload').css('width', '100%');
                    let bytesTotal = 0;
                    this.uploaded_files.forEach((item) => {
                        bytesTotal += item.file_size;
                    });
                    this.upload_progress.bytes_total = bytesTotal;
                }
            },
            assets_created: function (val) {
                if (val.length > 0) {
                    if (val.length == this.num_assets_to_create) {
                        if (this.validating) {
                            this.validating = false;
                            this.createAssets();
                        } else {
                            $('#message-modal').modal('hide');

                            const resources = (this.num_assets_to_create === 1) 
                                ? this.$root.ucfirst(this.$root.contents.template_title) 
                                : this.$root.ucfirst(this.$root.contents.template_title) + 's';
                            this.updated = resources + ' added successfully';
                            this.$root.folders_changed = true;
                            this.uploaded_files = [];
                            $('.dropzone-container').hide();
                            var vm = this;
                            
                            setTimeout(function () {
                                vm.$parent.hideTemplateAddForm();
                                let assetIds = [];
                                vm.assets_created.forEach((item) => {
                                    assetIds.push(item.asset_id);
                                });
                                if (assetIds.length == 1) {
                                    vm.$root.redirect_to_home = true;
                                }
                                setTimeout(function () {
                                    vm.$router.push({
                                        name: 'assets-filtered',
                                        params: { filters: 'id::' + assetIds.join(',') } 
                                    });
                                }, 200);
                            }, 2000);
                        }
                    } else if ((val.length + this.assets_failed.length) == this.num_assets_to_create) {
                        this.onAssetsCreated();
                    }
                }
            },
            assets_failed: function (val) {
                if (val.length > 0) {
                    if ((val.length + this.assets_created.length) == this.num_assets_to_create) {
                        this.onAssetsCreated();
                    }
                }
            },
            tab: function (val) {
                if (!this.selected_file) {
                    this.tab = 'form';
                    this.opening_tab = null;

                    return null;
                }

                this.selected_file.template.current_page = null;
                this.selected_file.template.current_image = null;
                if (val == 'fields') {
                    const templatePages = this.templatePages(this.selected_file.template);
                    if (templatePages.length == 1) {
                        this.selected_file.template.current_page = templatePages[0].id;
                    }
                }
                if (val == 'images') {
                    const templatePages = this.templatePages(this.selected_file.template, 'I');
                    if (templatePages.length == 1) {
                        this.selected_file.template.current_page = templatePages[0].id;
                        const templateImages = this.templateImages(this.selected_file.template);
                        if (templateImages.length == 1) {
                            this.selected_file.template.current_image = templateImages[0].id;
                        }
                    }
                }

                this.selecting_template_page = true;
                if ((val == 'form') || this.selected_file.checked || this.opening_tab) {
                    return null;
                }

                if ((val == 'fields') && this.hasTemplateFieldErrors(this.selected_file.template)) {
                    return null;
                }

                this.opening_tab = val;
                this.tab = 'form';
                this.editFile(this.selected_file, true);
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.system_errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                this.message = false;
                this.uploaded_files = [];
                this.upload_progress = {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                };
                this.selecting_file = false;
                this.selected_file = null;
                this.loading_edit_form = false;
                this.tab = 'form';
                this.manage_images_selected_asset_ids = null;
                this.manage_images_updating_selected_images = false;
                this.output_options = [];
                this.opening_tab = null;
                if (this.$refs.templateDropzone) {
                    this.$refs.templateDropzone.removeAllFiles();
                }
                this.getAssetTypes();
                this.getFolders();
                this.getUserGroups();
                this.getCustomFields();
                this.getKeywordSuggestions();
                this.getPricePanels();
                $('.dropzone-container').show();
                if (!this.preview_resize_interval) {
                    var vm = this;
                    this.preview_resize_interval = setInterval(function () {
                        vm.resizePreviews();
                    }, 200);
                }
            },
            onClose: function (confirmed, redirectTo = null) {
                if (!confirmed && (this.uploaded_files.length > 0)) {
                    this.on_close_redirect_to = redirectTo;
                    this.$parent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"> '
                            + 'Are you sure you want to leave this page? Any uploads will be cancelled. '
                            + '</div> </div>',
                        component: this,
                        action: 'close-modal',
                        buttons: {
                            yes: 'Leave',
                            cancel: 'Stay'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }
                this.$parent.hideTemplateAddForm();
                this.uploaded_files = [];

                if (redirectTo) {
                    if (redirectTo.name == 'logout') {
                        const component = this.$root.findComponent(this.$parent, 'main-nav');
                        if (component) {
                            component.logout();
                        }

                        return null;
                    }
                    this.$router.push(redirectTo);
                }
            },
            editFile: function(file, validate = false, delay = true) {
                if (this.block_file_select) {
                    return null;
                }
                if (this.uploaded_files.indexOf(file) < 0) {
                    return null;
                }

                this.selecting_file = true;
                var vm = this;

                if (validate && delay) {
                    var eFile = file;
                    var eValidate = validate;
                    setTimeout(function () {
                        vm.editFile(eFile, eValidate, false);
                    }, 200);

                    return null;
                }
                if (this.opening_tab) {
                    if (!validate) {
                        this.opening_tab = null;
                    }
                } else {
                    this.loading_edit_form = true;
                }

                if (this.selected_file && !this.updating) {
                    if (validate) {
                        this.edit_request_file = file;
                        // Validate current form before loading the next one.
                        this.assets_created = [];
                        this.assets_failed = [];
                        this.createAsset(this.selected_file, true);

                        return null;
                    }
                }

                this.selecting_file = false;
                this.selected_file = file;
                this.selected_file.price_panel_type_id = null;
                this.selected_file.price_panel_id = [];
                this.tab = 'form';
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                
                setTimeout(function () {
                    vm.loading_edit_form = false;

                    // Highlight errors
                    setTimeout(function () {
                        let firstErrorField = null;
                        vm.assets_failed.forEach((item) => {
                            if (item.file_id == file.id) {
                                item.error_fields.forEach((field) => {
                                    if (!firstErrorField) {
                                        firstErrorField = field;
                                    }
                                    if (field == 'price_panel_id') {
                                        $('#price_panels-label' + file.id).addClass('text-danger');
                                        $('#price_panels' + file.id).addClass('field-error');
                                    } else {
                                        $('#' + field + '-label' + file.id).addClass('text-danger');
                                        $('#' + field + file.id).addClass('field-error');
                                    }
                                });
                            }
                        });
                    }, 500);
                }, 500);

                return false;
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                this.block_file_select = true;

                this.$parent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');

                var vm = this;
                setTimeout(function () {
                    vm.block_file_select = false;
                }, 1000);
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    if ($('#' + fileId + ' .dz-thumbnail img').outerHeight() < 500) {
                        $('#view-image-icon' + fileId).show();
                    }
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                //formData.append('create_preview', '1100x1100');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let bytesTotal = 0;
                let bytesSentTotal = 0;
                let html = '';
                this.uploaded_files.forEach((item) => {
                    bytesTotal += item.file_size;
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        bytesSentTotal += bytesSent;
                        html = (progress < 100)
                            ? 'Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%'
                            : 'Previewing...';
                        $('#upload-info' + item.id).html(html);
                        $('#upload-progress' + item.id + ' > div').css('width', progress + '%');
                    } else if (item.upload.progress == 100) {
                        bytesSentTotal += item.file_size;
                    }
                });
                this.upload_progress.progress = ((bytesTotal * bytesSentTotal) > 0)
                    ? ((bytesSentTotal / bytesTotal) * 100) : 0;
                this.upload_progress.bytes_total = bytesTotal;
                this.upload_progress.bytes_sent = bytesSentTotal;
                $('.asset-overall-upload').css('width', this.upload_progress.progress + '%');
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-progress' + file.upload.uuid + ' > div').css('width', '100%');
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        this.uploaded_files[itemIndex].preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    this.$refs.templateDropzone.removeFile(file);

                    this.uploaded_files[itemIndex].template = {
                        processed: false,
                        fields: [],
                        current_page: null,
                        current_image: null,
                        specification: null,
                        approval: null,
                        //stock_item: null,
                        stock_item: false,
                        stock_level: 0,
                        stock_code: null,
                        output_order: false,
                        output_desktop: false,
                        output_download: false,
                        output_email: false,
                        output_image_only: false,
                        output_view_only: false,
                        //price_panel_type_id: null,
                        //price_panel_id: null
                    };

                    // Process template.
                    if (this.uploaded_files[itemIndex].file_type == 'application/pdf') {
                        this.getUploadInfo(this.uploaded_files[itemIndex].id);
                        //this.processTemplate(this.uploaded_files[itemIndex]);
                    } else {
                        this.uploaded_files[itemIndex].template.processed = true;
                        $('#upload-info' + file.upload.uuid).hide();
                        this.showNonEditableError();
                    }
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/1100x1100';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }
                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                    }

                    vm.processTemplate(vm.uploaded_files[itemIndex]);
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getOutputOptions: function (file) {
                if (!file.template) {
                    return [];
                }
                return [
                    {
                        id: 0,
                        name: 'Order item',
                        disabled: (
                            !this.$root.isFeatured('printing')
                            || (this.output_options.indexOf(4) > -1)
                            || (this.output_options.indexOf(5) > -1)
                        )
                    },
                    {
                        id: 1,
                        name: 'Desktop print',
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 2,
                        name: 'Download',
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 3,
                        name: 'Email',
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 4,
                        name: 'Web image',
                        disabled: (this.output_options.indexOf(5) > -1)
                    },
                    {
                        id: 5,
                        name: 'View only',
                        disabled: (this.output_options.indexOf(4) > -1)
                    }
                ];
            },
            getOutputOptionValues: function (file) {
                if (!file.template) {
                    return [];
                }
                if (file.template.output_image_only) {
                    return [4];
                }
                if (file.template.output_view_only) {
                    return [5];
                }

                let values = [];
                if (file.template.output_order) {
                    values.push(0);
                }
                if (file.template.output_desktop) {
                    values.push(1);
                }
                if (file.template.output_download) {
                    values.push(2);
                }
                if (file.template.output_email) {
                    values.push(3);
                }

                return values;
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                this.upload_progress.progress = 0;
                if ($('#message-modal').hasClass('show') && (this.$parent.message.title == 'Submit forms')) {
                    $('#message-modal').modal('hide');
                }
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    let title = 'Upload error';
                    let error = null;
                    if (!file.accepted) {
                        title = 'File type not accepted'
                        error = 'Invalid file type: ' + file.name + '<br><br>Please choose a PDF document.';
                        this.resetForm();
                    }
                    if (!error) {
                        error = 'Something went wrong, please try again';
                    }
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: title,
                        text: error
                    };
                    $('#message-modal').modal('show');
                }
                this.uploading = false;
                console.log(file, message);
            },
            showNonEditableError: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: 'error',
                    title: 'Upload error',
                    text: 'The uploaded file does not contain any editable field.'
                };
                $('#message-modal').modal('show');
                this.resetForm();
            },
            onDragOver: function (event) {
                $('#template-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#template-dropzone').removeClass('dz-drag-highlight');
            },
            onFileAdded: function (file) {
                if (this.updating || (this.uploaded_files.length > 0)) {
                    this.$refs.templateDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.templateDropzone.removeFile(file);
                    this.$parent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                this.message = false;
                this.uploading = true;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null),
                    asset_type_id: null,
                    folder_id: null,
                    restricted: 0,
                    custom_fields: {},
                    keywords: '',
                    user_groups: this.isUserGroupRequired() ? [] : [-1],
                    template: {
                        processed: false,
                        crop_marks: false,
                        fields: []
                    },
                    asset_id: null,
                    price_panel_type_id: null,
                    price_panel_id: [],
                    price_panels: []
                };
                if (this.$root.client.asset_upload_populate_title) {
                    newFile.title = this.createTitleFromFilename(file.name);
                }
                this.uploaded_files.push(newFile);
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);

                if (this.uploaded_files.length == 1) {
                    this.editFile(this.uploaded_files[0]);

                    return null;
                }
            },
            doNothing: function () {
                return null;
            },
            hasErrors: function (fileId) {
                if (this.assets_failed.length == 0) {
                    return false;
                }
                let errors = false;
                this.assets_failed.forEach((item) => {
                    if (item.file_id == fileId) {
                        errors = true;
                    }
                });

                return errors;
            },
            removeUploadedFile: function(fileId, selectNextFile = true, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        // Since only 1 file allowed to upload just reset form.
                        this.resetForm();

                        return null;
                    }
                    this.block_file_select = true;

                    this.$parent.confirm_delete = {
                        title: 'Delete ' + this.$root.contents.template_title,
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');

                    var vm = this;
                    setTimeout(function () {
                        vm.block_file_select = false;
                    }, 1000);
                }
            },
            confirmDelete: function (action) {
                if (action == 'close-modal') {
                    this.onClose(true, this.on_close_redirect_to);

                    return null;
                }
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true, true);
                }
            },
            placeholder: function (file, field, update = false) {
                const focused = $('#' + field + file.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + file.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'title':
                        text = 'Give the ' + this.$root.contents.template_title + ' a title';
                        break;
                    case 'description':
                        text = (this.$root.contents.description_title == 'description')
                            ? 'Any other details/description?' : '';
                        break;
                    case 'notes':
                        text = 'Any notes like captions, photographer, special instructions etc?';
                        break;
                }
                if (update) {
                    $('#' + field + file.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            onFieldChange: function (file, field, property = null, mandatory = false, delay = true) {
                var vm = this;
                if (delay) {
                    var cFile = file;
                    var cField = field;
                    var cProperty = property;
                    var cMandatory = mandatory;
                    setTimeout(function () {
                        vm.onFieldChange(cFile, cField, cProperty, cMandatory, false);
                    }, 100);

                    return null;
                }

                if (field == 'folder_id') {
                    file.subfolders = null;
                    file.custom_fields = {};
                }

                if (mandatory) {
                    file.checked = false;
                }

                if (field == 'custom_fields') {
                    field = 'cf' + property;
                }

                if (field == 'output_options') {
                    if (this.output_options.indexOf(4) > -1) {
                        file.template.output_order = false;
                        file.template.output_desktop = false;
                        file.template.output_download = false;
                        file.template.output_email = false;
                        file.template.output_image_only = true;
                        file.template.output_view_only = false;
                        this.output_options = [4];
                    } else if (this.output_options.indexOf(5) > -1) {
                        file.template.output_order = false;
                        file.template.output_desktop = false;
                        file.template.output_download = false;
                        file.template.output_email = false;
                        file.template.output_image_only = false;
                        file.template.output_view_only = true;
                        this.output_options = [5];
                    } else {
                        file.template.output_order = (this.output_options.indexOf(0) > -1);
                        file.template.output_desktop = (this.output_options.indexOf(1) > -1);
                        file.template.output_download = (this.output_options.indexOf(2) > -1);
                        file.template.output_email = (this.output_options.indexOf(3) > -1);
                        file.template.output_image_only = false;
                        file.template.output_view_only = false;
                    }
                    if (!file.template.output_order) {
                        file.price_panel_type_id = null;
                        file.price_panel_id = [];
                        file.price_panels = [];
                    }
                }

                if (field == 'price_panel_type_id') {
                    file.price_panel_id = this.getPricePanelIds(file);

                    return null;
                }

                if (field == 'price_panel_id') {
                    const pricePanelIds = this.getPricePanelsValue(file.price_panels);
                    file.price_panel_id.forEach((item) => {
                        if (pricePanelIds.indexOf(item) < 0) {
                            file.price_panels.push({ id: item, title: this.getPricePanelTitle(item) });
                        }
                    });
                    let pricePanelsToRemove = [];
                    this.getPricePanelOptions(file.price_panel_type_id).forEach((item) => {
                        if ((pricePanelIds.indexOf(item.id) > -1) && (file.price_panel_id.indexOf(item.id) < 0)) {
                            pricePanelsToRemove.push(item.id);
                        }
                    });
                    pricePanelsToRemove.forEach((item) => {
                        this.removePricePanel(file, item);
                    });

                    field = 'price_panels';
                }

                this.$root.unHighlightErrors(field, file.id);
            },
            hasSubfolders: function (folderId) {
                if (!this.folders || !folderId) {
                    return false;
                }
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return false;
                }
                if (!this.folders[itemIndex].subfolders) {
                    return false;
                }

                return (this.folders[itemIndex].subfolders.length > 0);
            },
            getSubfolderOptions: function (folderId) {
                if (!this.folders || !folderId) {
                    return null;
                }
                let options = [];
                this.folders.forEach((folderItem) => {
                    if (folderItem.id == folderId) {
                        if (folderItem.subfolders) {
                            folderItem.subfolders.forEach((item) => {
                                if (!item.archived) {
                                    options.push({ id: item.id, name: item.name });
                                }
                            });
                        }
                    }
                });
                if (options.length < 0) {
                    return null;
                }

                return options;
            },
            hasCustomField: function (folderId, customField) {
                if (!folderId) {
                    return false;
                }
                if (!customField.folders) {
                    return true;
                }
                if (customField.folders.length == 0) {
                    return true;
                }

                return (customField.folders.indexOf(folderId) > -1);
            },
            getCustomFieldOptions: function (customField) {
                const customFieldOptions = (customField.options || []);
                let options = [];
                customFieldOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text
                    });
                });

                return options;
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: '<strong>All users</strong>'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            isUserGroupRequired: function () {
                if (!this.$root.client.asset_user_group_required) {
                    return false;
                }

                return (this.user_groups.length > 0);
            },
            today: function () {
                return new Date();
            },
            setDate: function (model, dateField, monthsToAdd) {
                this.updating_review_date = true;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + monthsToAdd);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                model[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                this.updating_review_date = false;
            },
            clearReviewDate: function (file) {
                this.updating_review_date = true;
                file.review_date = null;

                var vm = this;
                setTimeout(function () {
                    vm.updating_review_date = false;
                }, 200);
            },
            getAssetTypes: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/!pagination|active';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            /*assetTypeRestricted: function (assetTypeId) {
                let restricted = 0;
                this.asset_types.forEach((item) => {
                    if (item.id == assetTypeId) {
                        restricted = item.restricted;
                    }
                });

                return restricted;
            },*/
            getFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|!archived';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getCustomFields: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let customFields = [];
                    response.data.custom_fields.forEach((item) => {
                        if (item.area != 'A') {
                            customFields.push(item);
                        }
                    });
                    vm.custom_fields = customFields;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            processTemplate: function (file) {
                $('#upload-info' + file.id).html('Detecting editable fields...');
                /**
                 * Send request to API
                 */
                const data = {
                    id: file.id,
                    file: file.temp_file
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/process';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.uploaded_files.forEach((item) => {
                        if (item.id == response.data.file_id) {
                            if (!response.data.editable) {
                                vm.showNonEditableError();

                                return null;
                            }
                            item.template.fields = response.data.fields;
                            item.template.current_page = null;
                            item.template.current_image = null;
                            item.template.crop_marks = response.data.crop_marks || false;
                            item.template.processed = true;
                            $('#upload-info' + item.id).hide();

                            const page = vm.hasTemplateFieldErrors(item.template);
                            if (page !== false) {
                                vm.showTemplateFieldErrors(page);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    if (!error.response.data.file_id) {
                        vm.system_errors = error.response.data.error || 'Something went wrong, pleaase try again.';
                        vm.uploaded_files = [];

                        return null;
                    }
                    vm.uploaded_files.forEach((item) => {
                        if (item.id == error.response.data.file_id) {
                            item.template.processed = true;
                            $('#upload-info' + item.id).hide();
                        }
                    });
                })
                .finally(() => {
                    if ($('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('hide');
                    }
                });
            },
            hasTemplateFieldErrors: function (template) {
                if (!template.processed || (template.fields.length == 0)) {
                    return false;
                }
                if (template.fields[0].number_of_fields == 0) {
                    return false;
                }
                let hasErrors = false;
                template.fields.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((field) => {
                            if ((hasErrors === false) && field.error) {
                                hasErrors = page;
                            }
                        });
                    }
                });

                return hasErrors;
            },
            /*hasMultipleTemplateFields: function (template) {
                let numFields = 0;
                template.fields.forEach((fields, page) => {
                    if (page > 0) {
                        numFields += fields.length;
                    }
                });

                return (numFields > 1);
            },*/
            hasTemplateFields: function (template, type = null) {
                return (this.templatePages(template, type).length > 0);
            },
            templatePages: function (template, type = null) {
                if (!template.processed || (template.fields.length == 0)) {
                    return [];
                }
                if (template.fields[0].number_of_fields == 0) {
                    return [];
                }
                const qrSuffixes = this.$root.client.artwork_generate_qr_codes ? ['_QR', '_LQR', '_LQRx'] : [];
                let pages = [];
                template.fields.forEach((fields, page) => {
                    if (page > 0) {
                        if (!type) {
                            if (fields.length > 1) {
                                pages.push({ id: page, name: 'Page ' + page });
                            }
                        } else {
                            let fieldFound = false;
                            fields.forEach((field) => {
                                if (field.type == type) {
                                    let suffix = null;
                                    let parts = field.title.split('_');
                                    if (parts.length > 1) {
                                        suffix = '_' + parts[parts.length - 1];
                                    }
                                    if (
                                        (field.type != 'I')
                                        || ((suffix != '_Lx') && (qrSuffixes.indexOf(suffix) < 0))
                                        /*|| (this.locked_profile_fields.indexOf('I' + title) < 0)*/
                                    ) {
                                        fieldFound = true;
                                    }
                                }
                            });
                            if (fieldFound) {
                                pages.push({ id: page, name: 'Page ' + page });
                            }
                        }
                    }
                });

                return pages;
            },
            templateImages: function (template) {
                if (!template.processed || (template.fields.length == 0)) {
                    return null;
                }
                if (template.fields[0].number_of_fields == 0) {
                    return null;
                }
                if (template.current_page === null) {
                    return null;
                }
                const qrSuffixes = this.$root.client.artwork_generate_qr_codes ? ['_QR', '_LQR', '_LQRx'] : [];
                let images = [];
                template.fields.forEach((fields, page) => {
                    if ((page > 0) && (page == template.current_page)) {
                        fields.forEach((item) => {
                            if (item.type == 'I') {
                                //let title = item.title.substr(0, item.title.length - 3);
                                //let suffix = item.title.substr(-3);
                                let suffix = null;
                                let parts = item.title.split('_');
                                if (parts.length > 1) {
                                    suffix = '_' + parts[parts.length - 1];
                                }
                                if (
                                    (suffix != '_Lx') && (qrSuffixes.indexOf(suffix) < 0)
                                    /*|| (this.locked_profile_fields.indexOf('I' + title) < 0)*/
                                ) {
                                    if (!('name' in item)) {
                                        item.name = item.title.toString();
                                    }
                                    if (!('profile_images' in item)) {
                                        item.profile_images = false;
                                    }
                                    if (!('user_images' in item)) {
                                        item.user_images = false;
                                    }
                                    if (!('image_editing' in item)) {
                                        item.image_editing = false;
                                    }
                                    if (!('selected_images' in item)) {
                                        item.selected_images = [];
                                    }
                                    images.push(item);
                                }
                            }
                        });
                    }
                });

                return (images.length > 0) ? images : null;
            },
            onTemplateImageChange: function (template, image) {
                template.fields.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((item) => {
                            if ((item.type == 'I') && (item.title == image.title) && (item.id != image.id)) {
                                item.profile_images = image.profile_images;
                                item.user_images = image.user_images;
                                item.image_editing = image.image_editing;
                            }
                        });
                    }
                });
            },
            uploadProgress: function () {
                if (this.message) {
                    return this.message;
                }
                /*const percent = this.upload_progress.progress.toFixed(1);

                return '<div class="pb-2" style="text-align: center">Uploading ' + ((percent < 100) ? percent : '100')
                    + '%</div>';*/
            },
            showTemplateFieldErrors: function (page) {
                if (!this.selected_file) {
                    return null;
                }
                if (this.tab != 'fields') {
                    this.tab = 'fields';
                    $('#template-fields-tab').click();

                    var vm = this;
                    setTimeout(function () {
                        vm.selected_file.template.current_page = page;
                    }, 1000);
                }
                this.selected_file.template.current_page = page;
                $('html, body').animate({ scrollTop: 0 }, 700);
            },
            onSubmit: function() {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (!this.selected_file) {
                    return null;
                }

                if (this.uploading || !this.selected_file.template.processed) {
                    this.showPleaseWaitMessage();

                    return null;
                }

                const page = this.hasTemplateFieldErrors(this.selected_file.template);
                if (page !== false) {
                    this.showTemplateFieldErrors(page);

                    return null;
                }

                if (this.selected_file.template.fields.length > 0) {
                    if (this.selected_file.template.fields[0].number_of_fields > 0) {
                        if ((this.tab == 'form') && this.hasTemplateFields(this.selected_file.template)) {
                            this.tab = 'fields';
                            $('#template-fields-tab').click();
                            $('html, body').animate({ scrollTop: 0 }, 700);

                            return null;
                        }
                        if ((this.tab != 'images') && this.hasTemplateFields(this.selected_file.template, 'I')) {
                            this.tab = 'images';
                            $('#template-images-tab').click();
                            $('html, body').animate({ scrollTop: 0 }, 700);

                            return null;
                        }
                    }
                }

                this.updating = true;
                this.validating = (this.uploaded_files.length > 1);
                this.createAssets();
            },
            showPleaseWaitMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Submit form',
                    loading: true,
                    text: '<div style="text-align: center">Please wait until the file is uploaded and processed</div>'
                };
                $('#message-modal').modal('show');
            },
            createAssets: function () {
                this.num_assets_to_create = this.uploaded_files.length;
                this.assets_created = [];
                this.assets_failed = [];

                if (this.validating) {
                    this.message = 'Checking forms';
                    this.uploaded_files.forEach((file) => {
                        this.createAsset(file, true);
                    });

                    return null;
                }

                if (this.num_assets_to_create > 1) {
                    this.showDoNotCloseMessage();
                }

                this.message = '<span class="loading mr-1"><i class="fal fa-spinner-third fa-spin"></i></span> '
                    + 'Adding ' + this.$root.contents.template_title;
                if (this.num_assets_to_create != 1) {
                    this.message += 's';
                }
                
                var vm = this;
                vm.uploaded_files.forEach((file, index) => {
                    setTimeout(function () {
                        vm.createAsset(file);
                    }, (index * 1000 + 100));
                });
            },
            createAsset: function (file, preValidate = false) {
                if (!preValidate && file.asset_id) {
                    this.checkFile(file.id, true);
                    this.createTemplate(file.id, file.asset_id);

                    return null;
                }
                /**
                 * Send request to API.
                 */
                let data = { template: true };
                const excludeFields = [
                    'preview_image',
                    'upload',
                    'checked',
                    'template',
                    'asset_id',
                    'price_panel_type_id',
                    'price_panel_id'
                ];
                for (const [key, value] of Object.entries(file)) {
                    if (excludeFields.indexOf(key) < 0) {
                        switch (key) {
                            case 'custom_fields':
                                data[key] = this.getCustomFieldsValue(value);
                                break;
                            case 'price_panels':
                                if (this.$root.isFeatured('printing') && file.template.output_order) {
                                    data.price_panels = this.getPricePanelsValue(value);
                                }
                                break;
                            default:
                                data[key] = value;
                        }
                    }
                }

                let path = '/' + this.$root.app.client_id + '/resource';
                if (preValidate) {
                    path += '/validate';
                }
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const templateErrors = vm.validateTemplateFormFields(response.data.file_id);
                    if (templateErrors) {
                        vm.handleFormFieldErrors(response.data.file_id, null, templateErrors);

                        return null;
                    }
                    vm.checkFile(response.data.file_id, true);
                    if ((response.data.message == 'Validation OK.') && !vm.validating) {
                        if (vm.opening_tab) {
                            var tabToOpen = vm.opening_tab;
                            vm.opening_tab = null;
                            setTimeout(function () {
                                //if (tabToOpen != 'images') {
                                    $('#template-' + tabToOpen + '-tab').click();
                                //}
                                vm.tab = tabToOpen;
                            }, 200);

                            return null;
                        }
                        vm.editFile(vm.edit_request_file);

                        return null;
                    }

                    if (vm.validating) {
                        vm.assets_created.push({
                            file_id: response.data.file_id/*,
                            asset_id: response.data.asset_id*/
                        });
                        vm.message = 'Form ' + (vm.assets_created.length + vm.assets_failed.length) + ' of '
                            + vm.num_assets_to_create + ' checked';
                        
                        return null;
                    }

                    vm.createTemplate(response.data.file_id, response.data.asset_id);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();

                            return null;
                        }
                        if (error.response.data.error) {
                            const fileId = error.response.data.file_id;
                            vm.handleFormFieldErrors(fileId, error.response.data, vm.validateTemplateFormFields(fileId));
                        }
                    }
                });
            },
            validateTemplateFormFields: function (fileId) {
                let errorFields = [];
                this.uploaded_files.forEach((file) => {
                    if (file.id == fileId) {
                        if (file.template.approval === null) {
                            errorFields.push('approval');
                        }
                        if (
                            !file.template.output_order
                            && !file.template.output_desktop
                            && !file.template.output_download
                            && !file.template.output_email
                            && !file.template.output_image_only
                            && !file.template.output_view_only
                        ) {
                            errorFields.push('output_options');
                        }
                        if (file.template.output_order && (file.price_panels.length == 0)) {
                            errorFields.push('price_panels');
                        }
                    }
                });

                return (errorFields.length > 0) ? errorFields : null;
            },
            handleFormFieldErrors: function (fileId, assetErrors, templateErrors) {
                let errorFields = [];
                let showErrors = false;
                if (assetErrors) {
                    if (typeof assetErrors.error === 'string') {
                        this.errors = assetErrors.error;
                    } else if (typeof assetErrors.error === 'object') {
                        for (const [key, value] of Object.entries(assetErrors.error)) {
                            errorFields.push(key);
                        }
                    }
                    
                    if (assetErrors.message) {
                        if (assetErrors.message == 'Validation failed.') {
                            showErrors = true;
                        }
                    }
                }
                if (templateErrors) {
                    templateErrors.forEach((field) => {
                        errorFields.push(field);
                    });
                    showErrors = true;
                }

                this.checkFile(fileId, false);
                $('#template-form-tab').click();
                this.assets_failed.push({
                    file_id: fileId,
                    error_fields: errorFields
                });
                if (this.validating) {
                    this.message = 'Form ' + (this.assets_created.length + this.assets_failed.length) + ' of '
                        + this.num_assets_to_create + ' checked';
                }
                if (showErrors) {
                    if (this.assets_failed.length == 1) {
                        this.uploaded_files.forEach((file) => {
                            if (file.id == fileId) {
                                this.editFile(file);
                            }
                        });
                    }
                    this.errors = 'The fields highlighted below are required.';
                }
            },
            createTemplate: function (fileId, assetId) {
                let template = null;
                this.uploaded_files.forEach((item) => {
                    if (item.id == fileId) {
                        template = item.template;
                    }
                });
                if (!template) {
                    this.errors = 'File not found: ' + fileId;
                    this.assets_failed.push({
                        file_id: fileId,
                        error_fields: []
                    });

                    return null;
                }
                if (!template.processed) {
                    this.errors = 'File not processed: ' + fileId;
                    this.assets_failed.push({
                        file_id: fileId,
                        error_fields: []
                    });

                    return null;
                }

                let fields = [];
                if (template.fields.length > 0) {
                    if (template.fields[0].number_of_fields > 0) {
                        template.fields.forEach((pageFields, pageNum) => {
                            if (pageNum > 0) {
                                pageFields.forEach((field, sortOrder) => {
                                    let fieldData = {
                                        type: field.type,
                                        title: field.title,
                                        page_number: pageNum,
                                        sort_order: sortOrder
                                    };
                                    if (field.type == 'T') {
                                        fieldData.styles = field.styles;
                                        fieldData.repeatable = field.repeatable;
                                        fieldData.group_id = field.group_id;
                                    } else {
                                        fieldData.profile_images = field.profile_images || false;
                                        fieldData.user_images = field.user_images || false;
                                        fieldData.image_editing = field.image_editing || false;
                                        if (field.selected_images) {
                                            let selectedImages = [];
                                            field.selected_images.forEach((image) => {
                                                if (image.id > 0) {
                                                    selectedImages.push({ asset_id: image.id });
                                                } else {
                                                    selectedImages.push({
                                                        filename: image.filename,
                                                        file_type: image.file_type,
                                                        file_size: image.file_size,
                                                        temp_file: image.temp_file
                                                    });
                                                }
                                            });
                                            if (selectedImages.length > 0) {
                                                fieldData.selected_images = selectedImages;
                                            }
                                        }
                                    }
                                    fields.push(fieldData);
                                });
                            }
                        });
                    }
                }

                const data = {
                    id: fileId,
                    resource_id: assetId,
                    crop_marks: template.crop_marks,
                    editable: (fields.length > 0),
                    specification: template.specification,
                    approval: template.approval,
                    stock_item: template.stock_item,
                    stock_level: template.stock_level,
                    stock_code: template.stock_code,
                    output_order: template.output_order,
                    output_desktop: template.output_desktop,
                    output_download: template.output_download,
                    output_email: template.output_email,
                    output_image_only: template.output_image_only,
                    output_view_only: template.output_view_only,
                    //price_panel_type_id: template.output_order ? template.price_panel_type_id : null,
                    //price_panel_id: template.output_order ? template.price_panel_id : null,
                    fields: fields,
                    fields_managed: true,
                    images_managed: true
                };

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_created.push({
                        file_id: response.data.file_id,
                        asset_id: response.data.asset_id
                    });
                    vm.message = '<span class="loading mr-1"><i class="fal fa-spinner-third fa-spin"></i></span> '
                        + vm.$root.ucfirst(vm.$root.contents.template_title) + ' '
                        + vm.assets_created.length + ' of ' + vm.num_assets_to_create + ' added';
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        const fileId = error.response.data.file_id;
                        vm.checkFile(fileId, false);
                        vm.errors = error.response.data.error;
                        vm.assets_failed.push({
                            file_id: fileId,
                            error_fields: []
                        });
                    }
                });
            },
            showDoNotCloseMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Submit forms',
                    loading: true,
                    text: '<div style="text-align: center">'
                        + 'Your files are processing please do not close this window until this completes</div>'
                };
                $('#message-modal').modal('show');
            },
            checkFile: function (fileId, check) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                this.uploaded_files.forEach((item) => {
                    if (item.id == fileId) {
                        item.checked = check;
                    }
                });
            },
            getCustomFieldsValue: function (customFields) {
                if (!customFields) {
                    return null;
                }
                let customFieldsValue = {};
                let customFieldValue = null;
                let customFieldOptions = [];
                for (const [key, value] of Object.entries(customFields)) {
                    this.custom_fields.forEach((item) => {
                        if (key == item.id) {
                            customFieldValue = value;
                            if (item.type == 'multiselect') {
                                customFieldValue = [];
                                if (value.length > 0) {
                                    customFieldOptions = (item.options || []);
                                    customFieldOptions.forEach((option, optionIndex) => {
                                        if (value.indexOf(optionIndex) > -1) {
                                            customFieldValue.push(option.value);
                                        }
                                    });
                                }
                            }
                            if (item.type == 'singleselect') {
                                customFieldOptions = (item.options || []);
                                customFieldOptions.forEach((option, optionIndex) => {
                                    if (value == optionIndex) {
                                        customFieldValue = option.value;
                                    }
                                });
                            }
                            customFieldsValue[key] = customFieldValue;
                        }
                    });
                }

                return customFieldsValue;
            },
            onAssetsCreated: function () {
                this.updating = false;
                if (this.validating) {
                    this.validating = false;

                    return null;
                }
                $('#message-modal').modal('hide');
                const resources = (this.num_assets_to_create === 1) ? this.$root.contents.template_title
                    : this.$root.contents.template_title + 's';
                if (this.errors) {
                    this.errors += '<br>';
                } else {
                    this.errors = '';
                }
                this.$root.folders_changed = true;

                if (this.assets_created.length > 0) {
                    this.assets_created.forEach((item) => {
                        this.removeUploadedFile(item.file_id, false, true);
                    });
                }
                this.editFile(this.uploaded_files[0]);
            },
            resizePreviews: function () {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                if (this.uploaded_files.length > 1) {
                    // Reset all?
                    return null;
                }
                let height = 200;
                const file = this.uploaded_files[0];
                if (file.preview_image) {
                    if (file.preview_image.indexOf('data:') == 0) {
                        let width = ($('#' + file.id + ' .dz-thumbnail').innerWidth() > 750)
                            ? 750 : $('#' + file.id + ' .dz-thumbnail').innerWidth();
                        const ratio = $('#' + file.id + ' .dz-thumbnail img').outerWidth()
                            / $('#' + file.id + ' .dz-thumbnail img').outerHeight();
                        height = Math.round(width / ratio);
                        if (height > 700) {
                            width = Math.round(700 * ratio);
                            height = 700;
                        }
                    }
                }
                $('#' + file.id + ' .dz-thumbnail').css('height', height);
                $('#' + file.id + ' .dz-thumbnail img').css('max-height', height);
                /*if (height < 500) {
                    $('#view-image-icon' + file.id).show();
                }*/
            },
            submitButtonText: function () {
                if ((this.uploaded_files.length > 0) && this.selected_file) {
                    if (this.selected_file.template.processed && (this.selected_file.template.fields.length > 0)) {
                        if (this.selected_file.template.fields[0].number_of_fields > 0) {
                            if ((this.tab == 'form') && this.hasTemplateFields(this.selected_file.template)) {
                                return 'Go to field ordering';
                            }

                            return ((this.tab != 'images') && this.hasTemplateFields(this.selected_file.template, 'I'))
                                ? 'Go to manage images' : 'Finish';
                        }
                    }
                }

                return 'Submit';
            },
            chooseAsset: function (image) {
                let selectedImages = [];
                image.selected_images.forEach((item) => {
                    if (item.id > 0) {
                        selectedImages.push(item.id);
                    }
                });

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    'Choose ' + this.$root.contents.asset_title + 's',
                    this,
                    'template-images',
                    'asset',
                    selectedImages,
                    ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf', 'image/svg', 'image/svg+xml'],
                    'Only ' + this.$root.contents.folders_title
                        + ' with suitable files will appear in the drop down menu',
                    true
                );
                $('#select-item-modal').modal('show');
            },
            manageImageIndex: function () {
                if (!this.selected_file) {
                    return -1;
                }
                if (!this.selected_file.template) {
                    return -1;
                }
                if (this.selected_file.template.current_page === null) {
                    return -1;
                }
                let imageIndex = -1;
                this.selected_file.template.fields[this.selected_file.template.current_page].forEach((item, index) => {
                    if (item.id == this.selected_file.template.current_image) {
                        imageIndex = index;
                    }
                });

                return imageIndex;
            },
            syncTemplateFieldImages: function (template, currentField) {
                template.fields.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((item) => {
                            if (
                                (item.title == currentField.title)
                                && (item.id != currentField.id)
                                && (item.type == 'I')
                            ) {
                                let selectedImages = [];
                                currentField.selected_images.forEach((image) => {
                                    selectedImages.push(image);
                                });
                                item.selected_images = selectedImages;
                            }
                        });
                    }
                });
            },
            selectedItem: function (field, itemType, value) {
                this.manage_images_updating_selected_images = true;
                field = this.selected_file.template
                    .fields[this.selected_file.template.current_page][this.manageImageIndex()];

                // Remove asset images.
                let imagesToRemove = [];
                field.selected_images.forEach((item) => {
                    if ((item.id > 0) && (value.indexOf(item.id) < 0)) {
                        imagesToRemove.push(item.id);
                    }
                });
                if (imagesToRemove.length > 0) {
                    imagesToRemove.forEach((item) => {
                        this.removeSelectedImage(item);
                    });
                    this.syncTemplateFieldImages(this.selected_file.template, field);
                }

                if ((value.length > 0) && (this.manageImageIndex() > -1)) {
                    this.manage_images_selected_asset_ids = value;
                    this.getSelectedAssets();

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            getSelectedAssets: function () {
                const field = this.selected_file.template
                    .fields[this.selected_file.template.current_page][this.manageImageIndex()];
                
                let getAssetIds = [];
                this.manage_images_selected_asset_ids.forEach((assetId) => {
                    let itemIndex = -1;
                    field.selected_images.forEach((item, index) => {
                        if (item.id == assetId) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        getAssetIds.push(assetId);
                    }
                });
                if (getAssetIds.length == 0) {
                    this.syncTemplateFieldImages(this.selected_file.template, field);
                    this.manage_images_updating_selected_images = false;

                    return null;
                }

                /**
                 * Send get assets request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::'
                    + getAssetIds.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let selectedImages = [];
                    vm.selected_file.template
                        .fields[vm.selected_file.template.current_page][vm.manageImageIndex()]
                        .selected_images
                        .forEach((image) => {
                            selectedImages.push(image);
                        });
                    response.data.resources.data.forEach((item) => {
                        item.preview = null;
                        if (selectedImages.length == 0) {
                            selectedImages.push(item);
                        } else {
                            let images = [];
                            let added = false;
                            selectedImages.forEach((image) => {
                                if (!added && (item.id > image.id)) {
                                    images.push(item);
                                    added = true;
                                }
                                images.push(image);
                            });
                            if (!added) {
                                images.push(item);
                            }
                            selectedImages = images;
                        }
                    });
                    vm.selected_file.template
                        .fields[vm.selected_file.template.current_page][vm.manageImageIndex()]
                        .selected_images = selectedImages;
                    vm.getSelectedAssets();
                })
                .catch(function (error) {
                    //
                });
            },
            onTemplateImagePreviewed: function (event) {
                this.selected_file.template
                    .fields[this.selected_file.template.current_page][this.manageImageIndex()]
                    .selected_images.forEach((item) => {
                        if (item.id == event.id) {
                            item.preview = event.src;
                        }
                    });
            },
            addUploadedImages: function (images) {
                this.manage_images_updating_selected_images = true;
                const field = this.selected_file.template
                    .fields[this.selected_file.template.current_page][this.manageImageIndex()];

                let nextId = 0;
                field.selected_images.forEach((item) => {
                        if (item.id < nextId) {
                            nextId = item.id;
                        }
                    });
                nextId--;
                images.forEach((item) => {
                    item.id = nextId;
                    field.selected_images.unshift(item);
                    nextId--;
                });
                this.syncTemplateFieldImages(this.selected_file.template, field);

                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            onTemplateImageRemoved: function (event) {
                this.manage_images_updating_selected_images = true;
                this.removeSelectedImage(event.id, true);
                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            removeSelectedImage: function (id, sync = false) {
                const field = this.selected_file.template
                    .fields[this.selected_file.template.current_page][this.manageImageIndex()];

                let itemIndex = -1;
                field.selected_images.forEach((item, index) => {
                    if (item.id == id) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    field.selected_images.splice(itemIndex, 1);
                }
                if (sync) {
                    this.syncTemplateFieldImages(this.selected_file.template, field);
                }
            },
            manageImagePreviewClass: function () {
                if (this.$root.is_4xl_screen) {
                    return 'col-3';
                }
                if (this.$root.is_xxl_screen) {
                    return 'col-4';
                }
                
                return 'col-md-6 col-12';
            },
            resetImageUpload: function () {
                const component = this.$root.findComponent(this.$parent, 'template-image-upload');
                if (component) {
                    component.reset(this);
                }
            },
            getPricePanels: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.price_panel_types = response.data.price_panel_types;
                })
                .catch(function (error) {
                    //
                });
            },
            getPricePanelOptions: function (panelTypeId) {
                let options = [];
                this.price_panel_types.forEach((type) => {
                    if (type.id == panelTypeId) {
                        type.price_panels.forEach((item) => {
                            options.push({
                                id: item.id,
                                name: item.title
                            });
                        });
                    }
                });

                return options;
            },
            getProfileFields: function () {
                return null;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.profile_fields.forEach((item) => {
                        if (item.title_suffix == '_Lx') {
                            vm.locked_profile_fields.push(item.type + item.title);
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            canSubmit: function () {
                if ((this.uploaded_files.length == 0) || !this.selected_file) {
                    return false;
                }

                if (!this.selected_file.template.processed) {
                    return false;
                }

                return (this.selected_file.template.fields.length > 0);
            },
            getPricePanelsValue: function (pricePanels) {
                let pricePanelIds = [];
                pricePanels.forEach((item) => {
                    pricePanelIds.push(item.id);
                });

                return pricePanelIds;
            },
            getPricePanelTitle: function (panelId) {
                let title = '';
                this.price_panel_types.forEach((type) => {
                    type.price_panels.forEach((item) => {
                        if (item.id == panelId) {
                            title = item.title;
                        }
                    });
                });

                return title;
            },
            getPricePanelIds: function (file) {
                if (!file.price_panel_type_id) {
                    return [];
                }
                const pricePanelIds = this.getPricePanelsValue(file.price_panels);
                const pricePanels = this.getPricePanelOptions(file.price_panel_type_id);
                let ids = [];
                pricePanels.forEach((item) => {
                    if (pricePanelIds.indexOf(item.id) > -1) {
                        ids.push(item.id);
                    }
                });

                return ids;
            },
            removePricePanel: function (file, panelId) {
                let itemIndex = -1;
                file.price_panels.forEach((item, index) => {
                    if (item.id == panelId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    file.price_panels.splice(itemIndex, 1);
                }

                let pricePanelIds = [];
                file.price_panel_id.forEach((item) => {
                    if (item != panelId) {
                        pricePanelIds.push(item);
                    }
                });
                file.price_panel_id = pricePanelIds;
            },
            createTitleFromFilename: function (filename) {
                if (!filename) {
                    return null;
                }
                let parts = filename.split('.');
                parts.pop();
                filename = parts.join('.');
                if (!filename) {
                    return null;
                }

                return filename.replace(/-/g, ' ').replace(/_/g, ' ');
            }
        }
    }
</script>
<style scoped>
    #template-add-form {
        display: none;
        max-width: 1600px;
    }

    #template-add-form .tab-content.forms {
        min-height: 70px;
    }

    #template-dropzone {
        min-height: 300px;
    }

    #template-add-files {
        padding-top: 135px;
    }

    #template-dropzone .dz-uploaded-file {
        cursor: default;
    }

    #template-dropzone .dz-uploaded-file .dz-thumbnail {
        display: block;
        width: 100%;
        margin-right: 0;
        height: 200px;
    }

    #template-dropzone .dz-uploaded-file .dz-thumbnail img {
        max-height: 200px;
    }

    #template-dropzone .dz-uploaded-file .dz-thumbnail .view-image-icon {
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        padding: 6px 12px;
    }

    #template-dropzone .dz-uploaded-file .dz-size {
        position: relative;
        float: left;
        bottom: 0;
        right: 0;
        margin-top: 4px;
    }

    #template-dropzone .dz-uploaded-file .dz-remove {
        position: relative;
        float: right;
        bottom: 0;
        right: 0;
    }

    #template-dropzone .dz-uploaded-file .dz-upload-info {
        position: relative;
        left: 0;
        bottom: 0;
        display: block;
    }

    .dz-uploaded-file.checked {
        background-color: #eff2f7;
    }

    .clear-review-date {
        position: absolute;
        top: 2px;
        right: 127px;
    }

    #template-dropzone .dz-uploaded-file .dz-progress {
        position: relative;
        bottom: 0;
        right: 0;
    }

    .button-holder {
        margin: 0;
    }

    .left-buttons {
        position: absolute;
        left: 0;
        white-space: nowrap;
    }

    .progress-display {
        position: absolute;
        left: 280px;
        width: calc(100% - 560px);
        text-align: center;
    }

    @media (max-width: 575px) {
        #template-dropzone {
            min-height: auto;
        }
    }

    @media (max-width: 991px) {
        .button-holder, #template-add-more-files {
            display: block;
            width: 100%
        }

        .left-buttons {
            position: static;
        }

        .progress-display {
            position: relative;
            left: 0px;
            bottom: 0px;
            display: block;
            width: 100%;
            margin: 0;
        }
    }
</style>
