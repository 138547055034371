<template>
    <div :class="(is_modal ? 'modal-content' : 'share-options-container pr-3')" :key="'as-' + (is_modal ? 'm' : 'nm')">
        <div v-if="is_modal" class="modal-header">
            <h5 class="modal-title" id="asset-share-modal-label">{{ getTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <p v-else>
            &nbsp;<!--SHARE OPTIONS-->
            <span v-if="asset" class="float-right">
                {{ $root.ucfirst(asset.template ? $root.contents.template_title
                    : $root.contents.asset_title) }}: {{ asset.id }}
            </span>
        </p>

        <div :class="(is_modal ? 'modal-body' : '')">
            <div v-if="(sent && (type != 'link'))" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>

            <div v-if="(!sent || (type == 'link'))">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'email') ? ' active' : '')"
                            :id="(is_modal ? '' : 'nm-') + 'share-email-tab'"
                            data-toggle="tab"
                            :href="'#' + (is_modal ? '' : 'nm-') + 'share-email'"
                            role="tab"
                            :aria-controls="(is_modal ? '' : 'nm-') + 'share-email'"
                            :aria-selected="((type == 'email') ? 'true' : 'false')"
                            @click.prevent="type = 'email'"
                        >Email</a>
                    </li>
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'link') ? ' active' : '')"
                            :id="(is_modal ? '' : 'nm-') + 'share-link-tab'"
                            data-toggle="tab"
                            :href="'#' + (is_modal ? '' : 'nm-') + 'share-link'"
                            role="tab"
                            :aria-controls="(is_modal ? '' : 'nm-') + 'share-link'"
                            :aria-selected="((type == 'link') ? 'true' : 'false')"
                            @click.prevent="type = 'link'"
                        >Get a link</a>
                    </li>
                    <!--<li v-if="!isRestricted()" class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'embed') ? ' active' : '')"
                            :id="(is_modal ? '' : 'nm-') + 'share-embed-tab'"
                            data-toggle="tab"
                            :href="'#' + (is_modal ? '' : 'nm-') + 'share-embed'"
                            role="tab"
                            :aria-controls="(is_modal ? '' : 'nm-') + share-embed'"
                            :aria-selected="((type == 'embed') ? 'true' : 'false')"
                            @click.prevent="type = 'embed'"
                        >Embed</a>
                    </li>-->
                    <li v-if="!isRestricted()" class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'dropbox') ? ' active' : '')"
                            :id="(is_modal ? '' : 'nm-') + 'share-dropbox-tab'"
                            data-toggle="tab"
                            :href="'#' + (is_modal ? '' : 'nm-') + 'share-dropbox'"
                            role="tab"
                            :aria-controls="(is_modal ? '' : 'nm-') + 'share-dropbox'"
                            :aria-selected="((type == 'dropbox') ? 'true' : 'false')"
                            @click.prevent="type = 'dropbox'"
                        >Dropbox</a>
                    </li>
                </ul>

                <div v-if="errors" v-html="errors" class="alert alert-danger mt-3 mb-0" role="alert"></div>

                <div class="tab-content pt-3">
                    <div
                        :class="'tab-pane fade' + ((type == 'email') ? ' show active' : '')"
                        :id="(is_modal ? '' : 'nm-') + 'share-email'"
                        role="tabpanel"
                        :aria-labelledby="(is_modal ? '' : 'nm-') + 'share-email-tab'"
                    >
                        <form>
                            <div class="form-group">
                                <label
                                    :for="(is_modal ? '' : 'nm-') + 'share-email-subject'"
                                    :id="(is_modal ? '' : 'nm-') + 'share-email-subject-label'"
                                    class="mandatory-label"
                                >Subject line *</label>
                                <input
                                    v-model="email_subject"
                                    type="text"
                                    :id="(is_modal ? '' : 'nm-') + 'share-email-subject'"
                                    class="form-control mandatory-field"
                                    :readonly="sending"
                                    v-on:keyup="$root.unHighlightErrors((is_modal ? '' : 'nm-') + 'share-email-subject')"
                                    v-on:change="$root.unHighlightErrors((is_modal ? '' : 'nm-')
                                        + 'share-email-subject')"
                                >
                            </div>
                            <div class="form-group dropdown">
                                <label
                                    :id="(is_modal ? '' : 'nm-') + 'share-email-recipients-label'"
                                    class="mandatory-label"
                                >Recipient email(s) *</label>
                                <div
                                    class="form-control mandatory-field pb-0"
                                    :id="(is_modal ? '' : 'nm-') + 'share-email-recipients'"
                                    @click="focusEmailRecipientKeyword()"
                                    style="height: auto; min-height: 38px"
                                >
                                    <span
                                        v-for="item in email_recipients"
                                        :key="item.email"
                                        :class="emailRecipientClass(item.email)"
                                        v-b-tooltip.hover
                                        title=""
                                    >
                                        <span v-if="!item.name">{{ item.email }}</span>
                                        <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                        <a
                                            v-if="!sending"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove"
                                            @click.prevent="removeEmailRecipient(item)"
                                        >
                                            <i class="far fa-times"></i>
                                        </a>
                                        <span v-if="sending">
                                            <i class="far fa-times"></i>
                                        </span>
                                    </span>

                                    <div style="display: inline-block; position: relative">
                                        <input
                                            v-model="email_recipient_keyword"
                                            type="text"
                                            :id="(is_modal ? '' : 'nm-') + 'share-email-recipient-keyword'"
                                            class="form-control px-1 mb-2 share-email-recipient-keyword"
                                            v-on:focus="searchEmailRecipient(true)"
                                            v-on:blur="focusEmailRecipientKeyword(false)"
                                            style="width: 280px; max-width: calc(100% - 40px)"
                                        >
                                        <button
                                            v-if="email_recipient_keyword"
                                            type="button"
                                            class="btn btn-secondary btn-sm"
                                            v-b-tooltip.hover
                                            title="Add recipient"
                                            @click="addEmailRecipient()"
                                            :disabled="sending"
                                            style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                        >
                                            <i class="far fa-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="dropdown-menu share-email-recipient-dropdown"
                                    style="width: 280px; max-height: 230px; overflow-y: auto"
                                >
                                    <div v-for="item in email_recipient_results" :key="item.id">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click.prevent="addEmailRecipient(item)"
                                        >
                                            {{ item.first_name }} {{ item.last_name }}
                                            <p class="small mb-1">{{ item.email }}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label :for="(is_modal ? '' : 'nm-') + 'share-email-message'">Message</label>
                                <textarea
                                    v-model="email_message"
                                    :id="(is_modal ? '' : 'nm-') + 'share-email-message'"
                                    class="form-control"
                                    :readonly="sending"
                                    style="height: 120px"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <label :for="(is_modal ? '' : 'nm-') + 'share-email-link-expires'">Expires</label>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="email_link_expires"
                                        :id="(is_modal ? '' : 'nm-') + 'share-email-link-expires'"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            Preset periods
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in link_expiry_date_presets"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('email_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        v-model="email_external_view_only"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="(is_modal ? '' : 'nm-') + 'share-email-external-view-only'"
                                        :disabled="sending"
                                    >
                                    <label
                                        class="custom-control-label"
                                        :for="(is_modal ? '' : 'nm-') + 'share-email-external-view-only'"
                                    >
                                        View only for non-registered users
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        :class="'tab-pane fade' + ((type == 'link') ? ' show active' : '')"
                        :id="(is_modal ? '' : 'nm-') + 'share-link'"
                        role="tabpanel"
                        :aria-labelledby="(is_modal ? '' : 'nm-') + 'share-link-tab'"
                    >
                        <div v-if="sent">
                            <form>
                                <div class="form-group">
                                    <label :for="(is_modal ? '' : 'nm-') + 'share-link-generated'">
                                        Link to {{ $root.contents.asset_title }}
                                    </label>
                                    <div class="input-group">
                                        <input
                                            v-model="sent"
                                            type="text"
                                            :id="(is_modal ? '' : 'nm-') + 'share-link-generated'"
                                            class="form-control"
                                            :readonly="true"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary"
                                                type="button"
                                                @click="copyLinkToClipBoard()"
                                            >
                                                {{ copy_link_button_text }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <form v-else>
                            <!--<div class="form-group">
                                <label :for="(is_modal ? '' : 'nm-') + 'share-link-subject'">Title for reference</label>
                                <input
                                    v-model="link_subject"
                                    type="text"
                                    :id="(is_modal ? '' : 'nm-') + 'share-link-subject'"
                                    class="form-control"
                                    :readonly="sending"
                                >
                            </div>-->
                            <div class="form-group">
                                <label>Access options</label>
                                <div class="form-control" style="height: auto">
                                    <div class="mb-2">
                                        <div
                                            class="custom-control custom-radio"
                                            :id="(is_modal ? '' : 'nm-') + 'share-link-access-none-radio'"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                :id="(is_modal ? '' : 'nm-') + 'share-link-access-none'"
                                                class="custom-control-input"
                                                value="none"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                :for="(is_modal ? '' : 'nm-') + 'share-link-access-none'"
                                            >
                                                Anyone (no tracking will be available)
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            :target="(is_modal ? '' : 'nm-') + 'share-link-access-none-radio'"
                                            triggers="hover"
                                        >
                                            This {{ $root.contents.asset_title }} is
                                            {{ (asset.restricted ? 'restricted' : 'suspended') }} therefore an external
                                            link to it cannot be created
                                        </b-tooltip>
                                    </div>
                                    <div class="mb-2">
                                        <div
                                            class="custom-control custom-radio"
                                            :id="(is_modal ? '' : 'nm-') + 'share-link-access-email-radio'"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                :id="(is_modal ? '' : 'nm-') + 'share-link-access-email'"
                                                class="custom-control-input"
                                                value="email"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                :for="(is_modal ? '' : 'nm-') + 'share-link-access-email'"
                                            >
                                                Anyone (recipient required to enter their email address)
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            :target="(is_modal ? '' : 'nm-') + 'share-link-access-email-radio'"
                                            triggers="hover"
                                        >
                                            This {{ $root.contents.asset_title }} is
                                            {{ (asset.restricted ? 'restricted' : 'suspended') }} therefore an external
                                            link to it cannot be created
                                        </b-tooltip>
                                    </div>
                                    <div class="mb-2">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                :id="(is_modal ? '' : 'nm-') + 'share-link-access-user'"
                                                class="custom-control-input"
                                                value="user"
                                                :disabled="sending"
                                            >
                                            <label 
                                                class="custom-control-label"
                                                :for="(is_modal ? '' : 'nm-') + 'share-link-access-user'"
                                            >
                                                Only registered users (they will be required to sign in)
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        v-if="($root.client.share_link_3rd_party && share_link_3rd_party_platform)"
                                        class="mb-2"
                                    >
                                        <div
                                            class="custom-control custom-radio"
                                            :id="(is_modal ? '' : 'nm-') + 'share-link-access-3rd_party-radio'"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                :id="(is_modal ? '' : 'nm-') + 'share-link-access-3rd_party'"
                                                class="custom-control-input"
                                                value="3rd_party"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                :for="(is_modal ? '' : 'nm-') + 'share-link-access-3rd_party'"
                                            >
                                                Get link for {{ share_link_3rd_party_platform }}
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            :target="(is_modal ? '' : 'nm-') + 'share-link-access-3rd_party-radio'"
                                            triggers="hover"
                                        >
                                            This {{ $root.contents.asset_title }} is
                                            {{ (asset.restricted ? 'restricted' : 'suspended') }} therefore an external
                                            link to it cannot be created
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label :for="(is_modal ? '' : 'nm-') + 'share-link-link-expires'">Expires</label>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="link_link_expires"
                                        :id="(is_modal ? '' : 'nm-') + 'share-link-link-expires'"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            Preset periods
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in link_expiry_date_presets"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('link_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="link_access != 'user'" class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        v-model="link_external_view_only"
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="(is_modal ? '' : 'nm-') + 'share-link-external-view-only'"
                                        :disabled="sending"
                                    >
                                    <label
                                        class="custom-control-label"
                                        :for="(is_modal ? '' : 'nm-') + 'share-link-external-view-only'"
                                    >
                                        View only for non-registered users
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--<div
                        :class="'tab-pane fade' + ((type == 'embed') ? ' show active' : '')"
                        :id="(is_modal ? '' : 'nm-') + 'share-embed'"
                        role="tabpanel"
                        :aria-labelledby="(is_modal ? '' : 'nm-') + 'share-embed-tab'"
                    >
                        <p>
                            Embed TBD...
                        </p>
                    </div>-->
                    <div
                        :class="'tab-pane fade' + ((type == 'dropbox') ? ' show active' : '')"
                        :id="(is_modal ? '' : 'nm-') + 'share-dropbox'"
                        role="tabpanel"
                        :aria-labelledby="(is_modal ? '' : 'nm-') + 'share-dropbox-tab'"
                    >
                        <p>
                            Coming soon
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!sent || (type == 'link'))" :class="(is_modal ? 'modal-footer' : '')">
            <span v-if="(type == 'email')">
                <span v-if="!sending">
                    <span>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="onSubmit()"
                        >
                            <i class="far fa-paper-plane mr-1"></i> Send
                        </button>
                    </span>
                </span>
                <span v-else>
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Send</span>
                    </button>
                </span>
            </span>
            <span v-if="(type == 'link')">
                <span>
                    <span v-if="(!sent && !sending)">
                        <span>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="onSubmit()"
                            >
                                <i class="far fa-link mr-1"></i> Generate link
                            </button>
                        </span>
                    </span>
                    <span v-if="sending">
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Generate link</span>
                        </button>
                    </span>
                    <span v-if="sent">
                        <span>
                            <span>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="done()"
                                >
                                    <i class="far fa-check mr-1"></i> Done
                                </button>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['is_modal', 'share_asset'],
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                asset: {
                    title: '...'
                },
                type: 'email',
                email_subject: null,
                email_recipients: [],
                email_message: null,
                email_link_expires: null,
                email_external_view_only: false,
                email_recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                email_recipient_results: [],
                keypress_time: 0,
                updating_link_expiry_date: false,
                link_expiry_date_presets: [
                    {
                        value: '1d',
                        text: 'In 1 day'
                    },
                    {
                        value: '7d',
                        text: 'In 1 week'
                    },
                    {
                        value: '14d',
                        text: 'In 2 weeks'
                    },
                    {
                        value: '1m',
                        text: 'In 1 month'
                    },
                    {
                        value: '1y',
                        text: 'In 1 year'
                    }
                ],
                link_subject: null,
                link_link_expires: null,
                link_external_view_only: false,
                copy_link_button_text: 'Copy link',
                link_access: 'none',
                highlight_emails: [],
                get_content_response: null,
                share_link_3rd_party_platform: '',
                validating: false,
                leave_errors: false
            }
        },
        mounted () {
            if (this.share_asset) {
                this.reset(this.share_asset);
            }
            if (this.$root.client.share_link_3rd_party) {
                this.$root.getContent(this, 'share_link_3rd_party_platform');
            }
        },
        watch: {
            share_asset: function (val) {
                if (val) {
                    this.reset(val);
                }
            },
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            },
            type: function (val) {
                if (this.leave_errors) {
                    this.leave_errors = false;

                    return null;
                }
                this.errors = false;
                if (this.sent) {
                    this.reset(this.asset, false);
                }
            },
            email_recipient_keyword: function (val) {
                this.$root.unHighlightErrors((this.is_modal ? '' : 'nm-') + 'share-email-recipients');
                if (val) {
                    this.searchEmailRecipient(true);

                    return null;
                }
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'share_link_3rd_party_platform') {
                            this.share_link_3rd_party_platform = val.content;
                        }
                    }
                }
            },
            link_access: function (val) {
                if (val == 'user') {
                    this.link_external_view_only = false;
                }
            }/*,
            email_recipient_results: function (val) {
                if (val.length > 0) {
                    this.block_recipient_keyword_auto_process = true;
                    
                    return null;
                }
                this.block_recipient_keyword_auto_process = false;
            }*/
        },
        methods: {
            reset: function (asset, first = true) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.validating = false;
                this.copy_link_button_text = 'Copy link';
                if (!first) {
                    return null;
                }
                this.asset = asset;
                const itemName = this.isLinkedFile() ? 'file' : this.$root.contents.asset_title;
                this.email_subject = this.$root.ucfirst(itemName) + ' from ' + this.$root.user.first_name + ' '
                    + this.$root.user.last_name;
                this.email_message = this.$root.user.first_name + ' ' + this.$root.user.last_name + ' has shared a '
                    + itemName + ' with you';
                this.email_recipients = [];
                this.email_link_expires = null;
                this.email_external_view_only = false;
                this.email_recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
                this.link_subject = this.email_subject;
                this.link_link_expires = null;
                this.link_external_view_only = false;
                this.link_access = (asset.restricted || asset.suspended) ? 'user' : 'none';
                this.highlight_emails = [];
                this.setDate('email_link_expires', '1m');
                this.setDate('link_link_expires', '1m');
                this.type = 'email';
                $('#' + (this.is_modal ? '' : 'nm-') + 'share-email-tab').click();
            },
            getTitle: function () {
                const itemName = this.isLinkedFile() ? 'attachment' : this.$root.contents.asset_title;

                return 'Share this ' + itemName + ': ' + this.asset.title;
            },
            onSubmit: function (validate = false) {
                this.errors = false;
                this.highlight_emails = [];
                this.sending = true;
                this.validating = validate;

                let data = {
                    type: this.type
                };
                if (this.isLinkedFile()) {
                    data.collection_id = this.asset.collection_id;
                    data.linked_files = [Math.abs(this.asset.id)];
                } else {
                    data.resource_id = this.asset.id;
                }

                if (this.type == 'email') {
                    data.subject = this.email_subject;
                    data.recipients = this.email_recipients;
                    if (this.email_recipient_keyword) {
                        data.recipients.push({email: this.email_recipient_keyword});
                    }
                    if (this.email_message) {
                        data.message = this.email_message;
                    }
                    if (this.email_link_expires) {
                        data.link_expires = this.email_link_expires;
                    }
                    data.external_view_only = this.email_external_view_only;
                    data.app_info = {
                        app_url: this.$root.$data.app.app_url
                    }
                }
                if (this.type == 'link') {
                    if (this.email_subject) {
                        data.subject = this.email_subject;
                    }
                    if (this.link_link_expires) {
                        data.link_expires = this.link_link_expires;
                    }
                    data.external_view_only = this.link_external_view_only;
                    if ((this.link_access != 'none') && (this.link_access != '3rd_party')) {
                        data.type += ('_' + this.link_access);
                    }
                }

                /**
                 * Send share asset request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                if (validate) {
                    url += '/validate';
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.validating) {
                        vm.validating = false;

                        return null;
                    }
                    const type = response.data.type.split('_');
                    if (vm.type != type[0]) {
                        $('#' + (vm.is_modal ? '' : 'nm-') + 'share-' + type[0] + '-tab').click();
                        vm.type = type[0];
                    }
                    if (vm.type == 'email') {
                        const itemName = vm.isLinkedFile() ? 'Attachment'
                            : vm.$root.ucfirst(vm.$root.contents.asset_title);
                        vm.sent = itemName + ' has been emailed.';

                        // Close after 4 seconds automatically.
                        setTimeout(function () {
                            if (vm.is_modal) {
                                $('#asset-share-modal').modal('hide');

                                return null;
                            }
                            vm.reset(vm.asset);
                        }, 4000);

                        return null;
                    }
                    if (vm.type == 'link') {
                        vm.sent = vm.$root.app.app_url + '/shared/' + response.data.access_code;

                        return null;
                    }
                    vm.sent = response.data.message;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#asset-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }

                    const type = error.response.data.type ? error.response.data.type.split('_') : null;
                    if (type) {
                        if (vm.type != type[0]) {
                            vm.leave_errors = true;
                            $('#' + (vm.is_modal ? '' : 'nm-') + 'share-' + type[0] + '-tab').click();
                            vm.type = type[0];
                        }
                    }

                    if ((error.response.status == 406) && (error.response.data.error == 'NotRegistered')) {
                        vm.errors = 'You can not share this ' + vm.$root.contents.asset_title
                            + ' externally as it is ' + (vm.asset.restricted ? 'restricted' : 'suspended') + '.';
                        if (error.response.data.emails) {
                            error.response.data.emails.forEach((email) => {
                                vm.highlight_emails.push(email);
                            });
                        }
                        $('.share-options-container').animate({ scrollTop: 0 }, 700);

                        return null;
                    }

                    if (vm.validating) {
                        vm.validating = false;
                    }
                    
                    // Show error.
                    $('.share-options-container').animate({ scrollTop: 0 }, 700);
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + (vm.is_modal ? '' : 'nm-') + 'share-' + vm.type + '-' + key + '-label')
                                    .addClass('text-danger');
                                $('#' + (vm.is_modal ? '' : 'nm-') + 'share-' + vm.type + '-' + key)
                                    .addClass('field-error');
                            }
                        }
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            focusEmailRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#' + (this.is_modal ? '' : 'nm-') + 'share-email-recipient-keyword').css('display', 'inline');
                    $('#' + (this.is_modal ? '' : 'nm-') + 'share-email-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.email_recipient_keyword) {
                    this.addEmailRecipient(null, false);
                }
                $('#' + (this.is_modal ? '' : 'nm-') + 'share-email-recipient-keyword').css('display', 'none');
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            searchEmailRecipient: function (delay = false) {
                if (!this.email_recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchEmailRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.email_recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.email_recipient_results = [];
                    response.data.users.forEach((item, index) => {
                        if ((item.email != vm.$root.user.email) && !vm.isEmailRecipientAdded(item.email)) {
                            vm.email_recipient_results.push(item);
                        }
                    });
                    if (vm.email_recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.share-email-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.share-email-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#asset-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isEmailRecipientAdded: function (email) {
                if (!email || (this.email_recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.email_recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            addEmailRecipient: function (user = null, addNew = true) {
                if (!user && !this.email_recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.email_recipient_keyword.trim();

                this.email_recipient_keyword = '';
                this.email_recipient_results = [];
                setTimeout(function () {
                    $('.share-email-recipient-dropdown').dropdown('hide');
                }, 250);

                if ((email.length > 0) && !this.isEmailRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.email_recipients.push(recipient);
                    this.onSubmit(true);
                }

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword();
                    }, 200);
                }
            },
            removeEmailRecipient: function (recipient) {
                this.$root.unHighlightErrors((this.is_modal ? '' : 'nm-') + 'share-email-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.email_recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.email_recipients.splice(itemIndex, 1);
                }
            },
            tomorrow: function () {
                const newDate = new Date();
                newDate.setDate(newDate.getDate() + 1);

                return newDate;
            },
            setDate: function (dateField, value) {
                this.updating_link_expiry_date = true;
                if (value == 'never') {
                    this[dateField] = null;
                } else {
                    const newDate = new Date();
                    if (value == '1d') {
                        newDate.setDate(newDate.getDate() + 1);
                    }
                    if (value == '7d') {
                        newDate.setDate(newDate.getDate() + 7);
                    }
                    if (value == '14d') {
                        newDate.setDate(newDate.getDate() + 14);
                    }
                    if (value == '1m') {
                        newDate.setMonth(newDate.getMonth() + 1);
                    }
                    if (value == '1y') {
                        newDate.setFullYear(newDate.getFullYear() + 1);
                    }
                    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                    const day = ('0' + newDate.getDate()).slice(-2);
                    this[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                }
                this.updating_link_expiry_date = false;
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#' + (this.is_modal ? '' : 'nm-') + 'share-link-generated');
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = 'Copied!';
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();
            },
            done: function () {
                if (this.is_modal) {
                    $('#asset-share-modal').modal('hide');

                    return null;
                }
                this.reset(this.asset);
            },
            emailRecipientClass: function (email) {
                let classList = 'badge ';
                classList += (this.highlight_emails.indexOf(email) > -1) ? 'badge-soft-danger'
                    : 'badge-soft-secondary';
                classList += ' contact-links font-size-12 mr-2 mb-2';

                return classList;
            },
            isRestricted: function () {
                return (this.asset.restricted || this.asset.suspended || false);
            },
            isLinkedFile: function () {
                return (this.asset.id < 0);
            }
        }
    }
</script>
<style scoped>
    .share-email-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    .share-email-recipient-keyword:focus {
        background-color: #f8f9fa;
    }

    .modal-body {
        min-height: 350px;
    }
</style>
