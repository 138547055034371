<template>
    <div>
        <div class="account-pages mx-md-5 my-5 pt-sm-5">
            <div class="container-fluid">
                <div :class="$root.loginPanelClass()">
                    <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                        <div class="card overflow-hidden">
                            <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                                <img
                                    v-if="$root.app.login_logo"
                                    :src="$root.app.login_logo"
                                    :alt="$root.client.client_name"
                                    class="img-fluid"
                                >
                            </div>
                            <div class="card-body pt-2 pb-1">
                                <div class="p-2">
                                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                    <div v-if="password_expired">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#forgotten-password-modal"
                                                @click="resetForgottenPasswordForm()"
                                                style="width: 100%"
                                            >
                                                <i class="far fa-key mr-1"></i> Change password
                                            </button>
                                        </div>
                                    </div>

                                    <!--<div v-if="account_locked">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#email-support-modal"
                                                @click="resetSupportForm('locked')"
                                                style="width: 100%"
                                            >
                                                <i class="far fa-envelope mr-1"></i> Email support
                                            </button>
                                        </div>
                                    </div>-->

                                    <div v-if="user_unapproved">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#email-support-modal"
                                                @click="resetSupportForm('unapproved')"
                                                style="width: 100%"
                                            >
                                                <i class="far fa-envelope mr-1"></i> Email support
                                            </button>
                                        </div>
                                    </div>

                                    <div v-if="email_not_verified">
                                        <div class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#email-support-modal"
                                                @click="resetSupportForm('not-verified')"
                                                style="width: 100%"
                                            >
                                                <i class="far fa-envelope mr-1"></i> Email support
                                            </button>
                                        </div>
                                    </div>

                                    <div v-if="loading"><loading></loading></div>

                                    <div v-if="showForm()">
                                        <form v-on:submit.prevent="onSubmit()">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input v-model="email" type="email" id="email" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label for="password">Password</label>
                                                <input
                                                    v-model="password"
                                                    type="password"
                                                    id="password"
                                                    class="form-control"
                                                >
                                            </div>
                                            <div v-if="$root.client.allow_remember_me" class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        v-model="remember_me"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="remember_me"
                                                    >
                                                    <label class="custom-control-label" for="remember_me">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <!--<div class="form-group">
                                                <tickbox
                                                    :field="'remember_me'"
                                                    v-model="remember_me"
                                                    :label="'Remember me'"
                                                ></tickbox>
                                            </div>-->
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-block">
                                                    <i class="far fa-sign-in-alt mr-1"></i> Login
                                                </button>
                                            </div>
                                        </form>

                                        <div v-if="canSso()" class="form-group">
                                            <button
                                                type="button"
                                                class="btn btn-secondary btn-block"
                                                @click="redirectToSso()"
                                            >
                                                <i class="far fa-id-badge mr-1"></i>
                                                {{ $root.app.saml_link_text }}
                                            </button>
                                        </div>

                                        <div class="form-group text-center">
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#forgotten-password-modal"
                                                @click="resetForgottenPasswordForm()"
                                            >
                                                <i class="far fa-lock-alt mr-1"></i> Forgot your password?
                                            </a>
                                        </div>

                                        <div
                                            v-if="$root.getSetting('allow_register', false)"
                                            class="form-group text-center"
                                        >
                                            <span>
                                                <a
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#register-modal"
                                                    @click="resetRegisterForm()"
                                                >
                                                    Don't have a login? Register now.
                                                </a>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="(!showForm() && !loading)">
                                        <div class="form-group text-center">
                                            <a href="#" @click.prevent="resetForm()">
                                                <i class="far fa-arrow-circle-left mr-1"></i> Back to login
                                            </a>
                                        </div>
                                    </div>

                                    <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                        Powered by <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="$root.getSetting('allow_register', false)"
                class="modal fade"
                id="register-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="register-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <register></register>
                </div>
            </div>

            <div
                class="modal fade"
                id="terms-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="terms-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <terms-and-conditions
                        v-model="terms_accepted"
                        :must_accept="true"
                        :content="$root.contents.terms_and_conditions"
                    ></terms-and-conditions>
                </div>
            </div>

        </div>

        <div
            class="modal fade"
            id="forgotten-password-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="forgotten-password-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <forgotten-password></forgotten-password>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'login',
                loading: false,
                errors: false,
                email: '',
                password: '',
                remember_me: false,
                password_expired: false,
                account_locked: false,
                user_unapproved: false,
                email_not_verified: false,
                terms_accepted: false
            }
        },
        mounted () {
            $('body').addClass('login-body');
            this.$root.current_route = 'login';
            if (this.$route.params.password_reset_user_data) {
                this.$root.login_email = null;
                const userData = atob(this.$route.params.password_reset_user_data).split('||');
                this.email = userData[0];
                $('#forgotten-password-modal').modal('show');
                this.resetForgottenPasswordForm({
                    first_name: userData[1],
                    last_name: userData[2],
                    migrated: false
                });

                return null;
            }
            if (this.$root.login_email) {
                this.email = this.$root.login_email.toString();
                if (this.$root.login_password) {
                    this.password = this.$root.login_password.toString();
                    this.onSubmit();
                }
            }
            this.$root.login_email = null;
            this.$root.login_password = null;
        },
        watch: {
            terms_accepted: function (val) {
                if (val) {
                    this.onSubmit();
                }
            },
        },
        methods: {
            onSubmit: function () {
                this.loading = true;
                this.errors = false;

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/login';
                const data = {
                    email: this.email,
                    password: this.password,
                    terms_accepted: this.terms_accepted,
                    device: this.$root.getDevice()
                };
                var vm = this;

                /**
                 * Send login request to API
                 */
                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    vm.$root.user = response.data.user;
                    vm.$root.token = response.data.token;

                    vm.$root.resetSystemVariables();
                    
                    // Create token cookie
                    const days = (vm.$root.client.allow_remember_me && vm.remember_me) ? '1Y' : null;
                    vm.$root.setCookie(vm.$root.app.client_id + '_api_token', response.data.token, days);
                    vm.$root.getSelectedAssetsAndCollectionFromCookie();
                    vm.$root.getAssetsInfo();

                    // Show mega menu info modal?
                    vm.$root.showMegaMenuInfo();

                    // Redirect to requested page
                    if (vm.$root.requested_route) {
                        if (!vm.$root.isProofsGuest() || (vm.$root.requested_route.name != 'home')) {
                            vm.$router.push({
                                name: vm.$root.requested_route.name,
                                params: vm.$root.requested_route.params,
                                query: vm.$root.requested_route.query
                            });
                            vm.$root.requested_route = null;

                            return null;
                        }
                        vm.$root.requested_route = null;
                    }
                    if (vm.$root.isPrintSupplier()) {
                        vm.$router.push({ name: 'my-account-print-orders' });

                        return null;
                    }
                    vm.$router.push({ name: vm.$root.isProofsGuest() ? 'proofs' : 'home' });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 503) {
                            vm.$root.showMaintenanceMessage(error.response.data.message);

                            return null;
                        }
                        if (error.response.status == 401) {
                            vm.errors = 'Invalid credentials. Please try again.';
                            if (error.response.data.error) {
                                if (error.response.data.error == 'Deleted') {
                                    vm.errors = 'Sorry, your account has been deleted.';
                                }
                                if (error.response.data.error == 'Locked') {
                                    vm.errors = "For security your account is locked for 15 minutes. After this time, "
                                        + "return to the login screen and try again. If you have forgotten your "
                                        + "password use the 'Forgotten password' link.";
                                    vm.account_locked = true;
                                }
                                if (error.response.data.error == 'Unapproved') {
                                    vm.errors = 'Your application is still being reviewed.'
                                        + ' To chase this please email support.';
                                    vm.user_unapproved = true;
                                    vm.$root.token = null;
                                    vm.$root.user = error.response.data.user;
                                    vm.$root.user.email = vm.email;
                                }
                                if (error.response.data.error == 'NotVerified') {
                                    vm.errors = 'You need to verify your email address before you can login.'
                                        + '<br><br>Please check your email from this system and click on the email'
                                        + ' verification link.<br><br>If you cannot find the email in your inbox,'
                                        + ' please check your spam/junk folders or email support.';
                                    vm.email_not_verified = true;
                                    vm.$root.token = null;
                                    vm.$root.user = error.response.data.user;
                                    vm.$root.user.email = vm.email;
                                }
                                if (error.response.data.error == 'TermsNotAccepted') {
                                    vm.errors = false;
                                    $('#terms-modal').modal('show');
                                }
                                if (error.response.data.error == 'Migrated') {
                                    vm.errors = false;
                                    $('#forgotten-password-modal').modal('show');
                                    let userData = error.response.data.user;
                                    userData.migrated = true;
                                    vm.resetForgottenPasswordForm(userData);
                                }
                            }
                        } else if (error.response.status == 406) {
                            vm.errors = 'Your password needs to be changed.'
                                + ' Please click on Change Password to set up a new one.';
                            vm.password_expired = true;
                        } else if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    this.loading = false
                );
            },
            resetForm: function () {
                this.loading = false;
                this.errors = false;
                this.remember_me = false;
                var vm = this;
                setTimeout(function () {
                    vm.password_expired = false;
                    vm.account_locked = false;
                    vm.user_unapproved = false;
                    vm.email_not_verified = false;
                    vm.$root.user = null;
                }, 200);
            },
            showForm: function () {
                if (this.loading || this.password_expired || this.account_locked) {
                    return false;
                }

                return (!this.user_unapproved && !this.email_not_verified);
            },
            resetForgottenPasswordForm: function (userData = null) {
                const component = this.$root.findComponent(this, 'forgotten-password');
                if (component) {
                    component.resetForm(userData);
                }
            },
            resetRegisterForm: function () {
                const component = this.$root.findComponent(this, 'register');
                if (component) {
                    component.resetForm();
                }
            },
            resetSupportForm: function (messageId) {
                const appComponent = this.$parent;
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    if (messageId == 'locked') {
                        component.resetForm('access', 'I am unable to login, my account is locked');
                    }
                    if (messageId == 'unapproved') {
                        component.resetForm('access', 'Please review application from '
                            + this.$root.user.first_name + ' ' + this.$root.user.last_name);
                    }
                    if (messageId == 'not-verified') {
                        component.resetForm('access', 'I have not received my email verification link');
                    }
                }
            },
            canSso: function () {
                if (!this.$root.app.saml_link_text) {
                    return false;
                }

                return true;
            },
            redirectToSso: function () {
                window.location.href = 'sso-saml.php';
            }
        }
    }
</script>
