<template>
    <div>
        <div v-if="updated" class="alert alert-success" role="alert">
            <div class="row">
                <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                <div class="col" v-html="updated"></div>
            </div>
        </div>

        <form v-else v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <div class="form-group">
                <vue-dropzone
                    ref="replaceAssetDropzone"
                    id="replace-asset-dropzone"
                    :options="dropzone_options"
                    v-on:vdropzone-sending="onSendingFile"
                    v-on:vdropzone-upload-progress="onProgressUpdate"
                    v-on:vdropzone-success="onFileUploaded"
                    v-on:vdropzone-error="onUploadError"
                    v-on:vdropzone-drag-over="onDragOver"
                    v-on:vdropzone-drag-leave="onDragLeave"
                    v-on:vdropzone-drop="onDragLeave"
                    v-on:vdropzone-file-added="onFileAdded"
                    :useCustomSlot=true
                >
                    <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                        <div class="dz-thumbnail">
                            <span v-if="!file.preview_image">
                                <img
                                    :src="$root.imagePlaceholder(file.filename)"
                                    class="img-fluid"
                                    alt=""
                                >
                            </span>
                            <img
                                v-else
                                :src="file.preview_image"
                                class="img-fluid"
                                alt=""
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                            <div
                                v-if="file.preview_image"
                                class="view-image-icon"
                                :id="'view-image-icon' + file.id"
                                v-b-tooltip.hover
                                title="Enlarge"
                                @click="viewImage(file)"
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                                <i class="fal fa-expand-arrows"></i>
                            </div>
                        </div>
                        <div :class="('dz-filename' + xlgClass())">{{ file.filename }}</div>
                        <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                        <div class="dz-remove">
                            <a
                                v-if="!updating"
                                href="#"
                                v-b-tooltip.hover
                                title="Delete"
                                @click.prevent="removeUploadedFile()"
                            >
                                <i class="fal fa-trash-alt"></i>
                            </a>
                            <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                        </div>
                        <div
                            :class="('dz-upload-info' + xlgClass())"
                            :id="'upload-info' + file.id"
                        >
                            <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                        </div>
                        <div
                            :class="('dz-progress' + xlgClass())"
                            :id="'upload-progress' + file.id"
                        >
                            <div class="dz-upload"></div>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="dz-add-files"
                        id="replace-asset-add-files"
                        @click.prevent="doNothing()"
                        :style="dzAddFilesStyle()"
                    >
                        <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                        Click or drop file here to upload
                    </a>
                </vue-dropzone>
            </div>
            <div class="form-group">
                <div class="custom-control custom-checkbox">
                    <input
                        v-model="keep_current_version"
                        type="checkbox"
                        class="custom-control-input"
                        id="keep-current-version"
                        :disabled="(uploading || updating)"
                    >
                    <label class="custom-control-label" for="keep-current-version">
                        Save current {{ $root.contents.asset_title }} to Previous versions
                    </label>
                </div>
            </div>
            <div v-if="keep_current_version" class="form-group">
                <label for="replace-asset-title" id="replace-asset-title-label" class="mandatory-label">
                    Previous version title *
                </label>
                <input
                    v-model="title"
                    type="text"
                    id="replace-asset-title"
                    class="form-control mandatory-field"
                    :readonly="(uploading || updating)"
                    :placeholder="placeholder('replace-asset-title')"
                    v-on:focus="placeholder('replace-asset-title', true)"
                    v-on:blur="placeholder('replace-asset-title', true)"
                    v-on:keyup="$root.unHighlightErrors('replace-asset-title')"
                    v-on:change="$root.unHighlightErrors('replace-asset-title')"
                >
            </div>
            <div v-if="(keep_current_version && has_linked_files)" class="form-group">
                <div class="custom-control custom-checkbox">
                    <input
                        v-model="keep_linked_files"
                        type="checkbox"
                        class="custom-control-input"
                        id="keep-linked-files"
                        :disabled="(uploading || updating)"
                    >
                    <label class="custom-control-label" for="keep-linked-files">
                        Move attachments to Previous versions
                    </label>
                </div>
            </div>
            <div v-if="$root.client.asset_upload_populate_title && file && !uploading" class="form-group">
                <label for="replace-asset-new_title" id="replace-asset-new_title-label" class="mandatory-label">
                    New title *
                </label>
                <input
                    v-model="new_title"
                    type="text"
                    id="replace-asset-new_title"
                    class="form-control mandatory-field"
                    :readonly="(uploading || updating)"
                    :placeholder="placeholder('replace-asset-new_title')"
                    v-on:focus="placeholder('replace-asset-new_title', true)"
                    v-on:blur="placeholder('replace-asset-new_title', true)"
                    v-on:keyup="$root.unHighlightErrors('replace-asset-new_title')"
                    v-on:change="$root.unHighlightErrors('replace-asset-new_title')"
                >
            </div>
            <div v-if="$root.client.assets_use_detected_labels && file && !uploading" class="form-group">
                <span
                    class="float-right contact-links font-size-18"
                    id="replace-asset-keywords-tooltip"
                    style="position: relative; top: -3px"
                >
                    <i class="fal fa-question-circle"></i>
                </span>
                <b-tooltip
                    target="replace-asset-keywords-tooltip"
                    triggers="hover focus"
                >
                    Please note that words you enter into the title and other fields will
                    already be counted as keywords.
                    <br><br>Please add additional keywords here to help more accurate
                    searching for users.
                </b-tooltip>
                <label for="replace-asset-keywords" id="replace-asset-keywords-label" class="mandatory-label">
                    Keywords *
                </label>
                <keywords-field-with-assistant
                    v-model="all_keywords"
                    :value="all_keywords"
                    field_id="replace-asset-keywords"
                    field_class="form-control mandatory-field"
                    :readonly="(uploading || updating)"
                    placeholder="No keywords added"
                    keyword_placeholder="Add keywords manually - separate with commas"
                    :add_current_keyword="updating"
                    :keyword_categories="keyword_categories"
                    :no_header="true"
                    v-on:change="onKeywordsChange()"
                ></keywords-field-with-assistant>
            </div>
            <div class="form-group">
                <button
                    v-if="!updating"
                    type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="!file || uploading"
                >
                    <i class="far fa-retweet-alt mr-1"></i> Replace
                </button>
                <span v-else>
                    <button type="button" class="btn btn-primary mr-2">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Replace</span>
                    </button>
                </span>
                <button type="button" class="btn btn-light" @click="onCancel()" :disabled="(uploading || updating)">
                    <i class="far fa-times mr-1"></i> Cancel
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        props: ['data', 'has_linked_files'],
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (10 * 1000 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#replace-asset-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                keep_current_version: false,
                title: '',
                keep_linked_files: false,
                keywords: '',
                detected_labels: '',
                detected_labels_removed: '',
                all_keywords: '',
                keyword_categories: [],
                new_title: ''
            }
        },
        mounted () {
            this.resetForm(this.data);
        },
        watch: {
            data: function (val) {
                this.resetForm(val);
            }
        },
        methods: {
            onSubmit: function (delay = true) {
                if (!this.file) {
                    this.errors = 'Please upload a file';

                    return null;
                }
                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }
                this.errors = false;

                if (!this.file.temp_file) {
                    this.$refs.replaceAssetDropzone.processQueue();
                    this.uploading = true;

                    return null;
                }
                this.updating = true;

                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 200);

                    return null;
                }

                /**
                 * Send request to API.
                 */
                let data = {
                    temp_file: this.file.temp_file,
                    filename: this.file.filename,
                    file_type: this.file.file_type,
                    file_size: this.file.file_size,
                    keep_current_version: this.keep_current_version,
                    title: this.title,
                    keep_linked_files: this.keep_linked_files,
                    keywords: this.keywords,
                    detected_labels: this.detected_labels,
                    detected_labels_removed: this.detected_labels_removed,
                };
                if (this.$root.client.asset_upload_populate_title) {
                    data.new_title = this.new_title;
                }
                if (this.$root.isFeatured('proofs')) {
                    data.app_info = {
                        app_url: this.$root.app.app_url
                    };
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.data.id + '/replace';

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.ucfirst(vm.$root.contents.asset_title) + ' replaced';

                    // Close after 2 seconds.
                    setTimeout(function () {
                        vm.onCancel();
                        // Reload assets.
                        vm.resetAsset();
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#replace-asset-' + key + '-label').addClass('text-danger');
                                    $('#replace-asset-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    if ($('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('hide');
                    }
                });
            },
            resetForm: function (data) {
                this.file = null;
                this.keep_current_version = false;
                this.title = '';
                this.keep_linked_files = false;
                this.keywords = '';
                this.detected_labels = '';
                this.detected_labels_removed = '';
                this.new_title = data.title;
                if (data) {
                    if (data.keywords) {
                        this.keywords = data.keywords.toString();
                    }
                    if (data.detected_labels) {
                        this.detected_labels = data.detected_labels.toString();
                    }
                }
                this.all_keywords = '';
                if (data && this.$root.client.assets_use_detected_labels) {
                    let allKeywords = [];
                    let keywords = [];
                    if (data.keywords) {
                        keywords = data.keywords.split(',');
                        keywords.forEach((item) => {
                            allKeywords.push(item);
                        });
                    }
                    if (data.detected_labels) {
                        keywords = data.detected_labels.split(',');
                        keywords.forEach((item) => {
                            allKeywords.push('[detected]' + item);
                        });
                    }
                    this.all_keywords = allKeywords.join(',');
                }
                this.errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                if (this.$root.client.assets_use_detected_labels) {
                    this.getKeywordSuggestions();
                }
            },
            onCancel: function () {
                this.resetForm(this.data);
                this.$parent.replacing = false;
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                this.errors = false;
                if (this.file) {
                    this.$refs.replaceAssetDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.replaceAssetDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                if (this.$root.client.asset_upload_populate_title) {
                    this.new_title = this.createTitleFromFilename(file.name);
                    this.title = this.data.title;
                }
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.replaceAssetDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#replace-asset-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#replace-asset-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + this.file.id).html(
                        this.file.preview_image ? 'Finalising upload...' : 'Previewing...'
                    );

                    this.getUploadInfo(this.file.id);
                    this.$refs.replaceAssetDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500/detect-labels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }

                    if (response.data.detected_labels) {
                        if (Array.isArray(response.data.detected_labels)) {
                            vm.detected_labels = response.data.detected_labels.join(',');
                            if (vm.$root.client.assets_use_detected_labels) {
                                let allKeywords = [];
                                if (vm.keywords) {
                                    allKeywords = vm.keywords.split(',');
                                }
                                response.data.detected_labels.forEach((item) => {
                                    allKeywords.push('[detected]' + item);
                                });
                                vm.all_keywords = allKeywords.join(',');
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                this.uploading = false;
                if (file.status != 'canceled') {
                    this.errors = 'Something went wrong, please try again';
                    console.log(file, message);
                }
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;

                    if (removeUploadFileId) {
                        this.$refs.replaceAssetDropzone.getUploadingFiles().forEach((file, fileIndex) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.replaceAssetDropzone.removeFile(file);
                            }
                        });
                        this.$refs.replaceAssetDropzone.getQueuedFiles().forEach((file, fileIndex) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.replaceAssetDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                this.$parent.$parent.$parent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'replace-asset-title':
                        text = 'Give the previous version a title';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            showPleaseWaitMessage: function () {
                this.$parent.$parent.$parent.message = {
                    type: null,
                    title: 'Replace ' + this.$root.contents.asset_title,
                    loading: true,
                    text: '<div style="text-align: center">Please wait until the file is uploaded</div>'
                };
                $('#message-modal').modal('show');
            },
            resetAsset: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::'
                    + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.data[0]) {
                        vm.$parent.setAsset(response.data.resources.data[0]);
                        vm.$parent.reload_assets_on_close = true;
                    }
                })
                .catch(function (error) {
                    vm.$parent.reload_assets_on_close = true;
                    vm.$parent.close();
                });
            },
            xlgClass: function () {
                if (this.$root.is_lg_screen && !this.$root.is_xl_screen) {
                    return ' xlg';
                }

                return '';
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item, index) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            onKeywordsChange: function () {
                if (!this.$root.client.assets_use_detected_labels) {
                    return null;
                }
                let keywords = [];
                let detectedLabels = [];
                const originalDetectedLabels = this.detected_labels ? this.detected_labels.split(',') : [];
                const allKeywords = this.all_keywords.split(',');
                allKeywords.forEach((item) => {
                    if (item.indexOf('[detected]') == 0) {
                        detectedLabels.push(item.substring(10));
                    } else {
                        keywords.push(item);
                    }
                });
                this.keywords = keywords.join(',');
                this.detected_labels = detectedLabels.join(',');
                let removedLabels = this.detected_labels_removed ? this.detected_labels_removed.split(',') : [];
                originalDetectedLabels.forEach((item) => {
                    if ((detectedLabels.indexOf(item) == -1) && (removedLabels.indexOf(item) == -1)) {
                        removedLabels.push(item);
                    }
                });
                this.detected_labels_removed = removedLabels.join(',');
            },
            createTitleFromFilename: function (filename) {
                if (!filename) {
                    return null;
                }
                let parts = filename.split('.');
                parts.pop();
                filename = parts.join('.');
                if (!filename) {
                    return null;
                }

                return filename.replace(/-/g, ' ').replace(/_/g, ' ');
            }
        }
    }
</script>
<style scoped>
    #replace-asset-dropzone {
        min-height: 126px;
    }

    #replace-asset-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-upload-info {
        left: 160px;
    }

    .dz-uploaded-file .dz-progress {
        width: calc(100% - 172px);
    }

    .dz-uploaded-file .dz-filename.xlg {
        height: 82px;
        max-height: 82px;
    }

    .dz-uploaded-file .dz-upload-info.xlg {
        left: 8px;
    }

    .dz-uploaded-file .dz-progress.xlg {
        width: calc(100% - 24px);
    }
</style>
