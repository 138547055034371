<template>
    <div class="container-fluid" id="asset-add-form">
        <div class="card">
            <div class="card-body" style="position: relative">
                <div v-if="updated" class="alert alert-success" role="alert">
                    <div class="row">
                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                        <div class="col" v-html="updated"></div>
                    </div>
                </div>

                <div class="row" :style="'display: ' + (updated ? 'none' : 'flex')">
                    <div class="col">
                        <div class="dropzone-container">
                            <vue-dropzone
                                ref="assetDropzone"
                                id="asset-dropzone"
                                :options="dropzone_options"
                                v-on:vdropzone-sending="onSendingFile"
                                v-on:vdropzone-upload-progress="onProgressUpdate"
                                v-on:vdropzone-success="onFileUploaded"
                                v-on:vdropzone-error="onUploadError"
                                v-on:vdropzone-drag-over="onDragOver"
                                v-on:vdropzone-drag-leave="onDragLeave"
                                v-on:vdropzone-drop="onDragLeave"
                                v-on:vdropzone-file-added="onFileAdded"
                                :useCustomSlot=true
                            >
                                <div v-if="(uploaded_files.length > 0)">
                                    <div
                                        v-if="(uploaded_files.length > 1)"
                                        class="dz-uploaded-file p-2 mb-2"
                                        :id="bulk_edit_file.id"
                                        style="cursor: default"
                                    >
                                        <div class="dz-thumbnail bulk-files">
                                            <div class="number text-center">{{ uploaded_files.length }}</div>
                                            {{ $root.contents.asset_title }}s
                                        </div>
                                        <div class="dz-filename">
                                            <h5
                                                v-if="selected_file == bulk_edit_file"
                                                class="mb-1 font-weight-normal font-size-16"
                                            >
                                                Add data common to all {{ uploaded_files.length }}
                                                {{ $root.contents.asset_title }}s or click a preview to manage each one
                                                separately.
                                            </h5>
                                            <button
                                                v-else
                                                type="button"
                                                class="btn btn-secondary float-right"
                                                :disabled="updating"
                                                @click="editFile(bulk_edit_file, true)"
                                            >
                                                Edit common data
                                            </button>
                                        </div>
                                        <div class="dz-size" style="right: 12px">
                                            Total
                                            <span v-html="$root.formatFileSize(upload_progress.bytes_total)"></span>
                                        </div>
                                        <!--<div class="dz-remove">
                                            <a
                                                v-if="!updating"
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Delete all"
                                                @click.prevent="removeAllFiles()"
                                            >
                                                <i class="fal fa-trash-alt"></i>
                                            </a>
                                            <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                        </div>-->
                                        <div
                                            v-if="uploading"
                                            class="dz-upload-info"
                                            :id="'upload-info' + bulk_edit_file.id"
                                        >
                                            <i class="fal fa-clock mr-1"></i> Pending upload
                                        </div>
                                        <div class="dz-progress">
                                            <div
                                                class="asset-overall-upload"
                                                :style="'width: ' + upload_progress.progress + '%'"
                                            ></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            v-for="file in uploaded_files"
                                            :key="file.id"
                                            :class="'dz-uploaded-file individual p-2 mb-2' + (file.checked ? ' checked'
                                                : ((file == selected_file) ? '' : ' greyed-out'))"
                                            :id="file.id"
                                            @click="editFile(file, true)"
                                        >
                                            <div class="dz-thumbnail">
                                                <span v-if="!file.preview_image">
                                                    <img
                                                        :src="$root.imagePlaceholder(file.filename)"
                                                        class="img-fluid"
                                                        alt=""
                                                    >
                                                </span>
                                                <img
                                                    v-else
                                                    :src="file.preview_image"
                                                    class="img-fluid"
                                                    alt=""
                                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                                >
                                                <div
                                                    v-if="file.preview_image"
                                                    class="view-image-icon"
                                                    :id="'view-image-icon' + file.id"
                                                    v-b-tooltip.hover
                                                    title="Enlarge"
                                                    @click="viewImage(file)"
                                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                                >
                                                    <i class="fal fa-expand-arrows"></i>
                                                </div>
                                            </div>
                                            <!--<div class="dz-filename mt-3">-->
                                            <div class="dz-filename" v-b-tooltip.hover :title="file.filename">
                                                {{ file.filename }}
                                            </div>
                                            <div
                                                v-if="(file.upload.progress == 0) || (file.upload.progress == 100)"
                                                class="dz-size"
                                                v-html="$root.formatFileSize(file.file_size)"
                                            ></div>
                                            <div class="dz-remove">
                                                <a
                                                    v-if="!updating"
                                                    href="#"
                                                    v-b-tooltip.hover
                                                    title="Delete"
                                                    @click.prevent="removeUploadedFile(file.id)"
                                                >
                                                    <i class="fal fa-trash-alt"></i>
                                                </a>
                                                <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                            </div>
                                            <div v-if="(hasErrors(file.id) && file.temp_file)">
                                                <div
                                                    :key="'incomplete-' + file.id"
                                                    class="dz-upload-info text-danger"
                                                >
                                                    <span
                                                        v-b-tooltip.hover
                                                        title="Form incomplete - see highlighted fields"
                                                    >
                                                        <i class="far fa-exclamation-triangle"></i>
                                                        <span class="d-lg-none d-xl-inline">Form incomplete</span>
                                                        <span class="d-none d-lg-inline d-xl-none">Incomplete</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="!file.temp_file"
                                                :key="'upload-info-' + file.id"
                                                class="dz-upload-info"
                                                :id="'upload-info' + file.id"
                                            >
                                                <i class="fal fa-clock mr-1"></i> Pending upload
                                            </div>
                                            <div
                                                v-if="(file.preview_image && !hasErrors(file.id))"
                                                :key="'enlarge-' + file.id"
                                                class="dz-upload-info"
                                            >
                                                <span>
                                                    <a href="#" @click.prevent="viewImage(file)">
                                                        <i class="fal fa-expand-arrows mr-1"></i> Enlarge
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="dz-progress" :id="'upload-progress' + file.id">
                                                <div
                                                    class="dz-upload"
                                                    :style="'width: ' + file.upload.progress + '%'"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    href="#"
                                    class="dz-add-files"
                                    id="asset-add-files"
                                    @click.prevent="doNothing()"
                                    :style="dzAddFilesStyle()"
                                >
                                    <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                    Click or drop files here to upload
                                </a>
                            </vue-dropzone>
                        </div>
                    </div>
                    <div
                        class="col-xl-6 col-12"
                        :style="'min-height: 800px; display: ' + ((uploaded_files.length > 0) ? 'block' : 'none')"
                    >
                        <div v-if="uploaded_files.length > 0" class="mt-3 mt-lg-0">
                            <form v-on:submit.prevent="doNothing()" class="mt-3 mt-lg-0">
                                <div v-if="loading_edit_form"><loading></loading></div>

                                <div v-else>
                                    <div v-for="file in getFilesToEdit()" :key="file.id">
                                        <div v-if="file.id == selected_file.id">
                                            <!--<div v-if="(uploaded_files.length > 1)" class="form-group details-for">
                                                <label v-if="(file.id != 'bulk_edit')">
                                                    Details for: {{ file.filename }}
                                                </label>
                                            </div>-->

                                            <div
                                                v-if="(errors && hasErrors(file.id))"
                                                v-html="errors"
                                                class="alert alert-danger form-errors"
                                                role="alert"
                                            ></div>

                                            <div
                                                v-if="file.id != 'bulk_edit'"
                                                class="form-group"
                                                :id="'title-fg' + file.id"
                                            >
                                                <label
                                                    :for="'title' + file.id"
                                                    :id="'title-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Title *
                                                </label>
                                                <input
                                                    v-model="file.title"
                                                    type="text"
                                                    :id="'title' + file.id"
                                                    class="form-control mandatory-field"
                                                    :readonly="(updating && !validating)"
                                                    :placeholder="placeholder(file, 'title')"
                                                    v-on:focus="placeholder(file, 'title', true)"
                                                    v-on:blur="placeholder(file, 'title', true)"
                                                    v-on:keyup="onFieldChange(file, 'title', null, true)"
                                                    v-on:change="onFieldChange(file, 'title', null, true)"
                                                >
                                            </div>

                                            <div
                                                v-if="$root.client.show_asset_description"
                                                class="form-group"
                                                :id="'description-fg' + file.id"
                                            >
                                                <label
                                                    :for="'description' + file.id"
                                                    :id="'description-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    {{ $root.ucfirst($root.contents.description_title) }}
                                                    <span
                                                        v-if="$root.client.asset_description_required
                                                            && (file.id != 'bulk_edit')"
                                                    >*</span>
                                                </label>
                                                <input
                                                    v-model="file.description"
                                                    type="text"
                                                    :id="'description' + file.id"
                                                    class="form-control mandatory-field"
                                                    :readonly="(updating && !validating)"
                                                    :placeholder="placeholder(file, 'description')"
                                                    v-on:focus="placeholder(file, 'description', true)"
                                                    v-on:blur="placeholder(file, 'description', true)"
                                                    v-on:keyup="onFieldChange(file, 'description', null,
                                                        $root.client.asset_description_required)"
                                                    v-on:change="onFieldChange(file, 'description', null,
                                                        $root.client.asset_description_required)"
                                                >
                                            </div>

                                            <div
                                                v-if="file.id != 'bulk_edit'"
                                                class="form-group"
                                                :id="'keywords-fg' + file.id"
                                            >
                                                <span
                                                    class="float-right contact-links font-size-18"
                                                    :id="'keywords-tooltip-' + file.id"
                                                    style="position: relative; top: -3px"
                                                >
                                                    <i class="fal fa-question-circle"></i>
                                                </span>
                                                <b-tooltip
                                                    :target="'keywords-tooltip-' + file.id"
                                                    triggers="hover focus"
                                                >
                                                    Please note that words you enter into the title and other fields will
                                                    already be counted as keywords.
                                                    <br><br>Please add additional keywords here to help more accurate
                                                    searching for users.
                                                </b-tooltip>
                                                <label
                                                    :for="'keywords' + file.id"
                                                    :id="'keywords-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Keywords
                                                    <span v-if="file.id != 'bulk_edit'">*</span>
                                                </label>
                                                <keywords-field-with-assistant
                                                    v-model="file.all_keywords"
                                                    :value="file.all_keywords"
                                                    :field_id="'keywords' + file.id"
                                                    field_class="form-control mandatory-field"
                                                    :readonly="(updating && !validating)"
                                                    placeholder="No keywords added"
                                                    keyword_placeholder="Add keywords manually - separate with commas"
                                                    :add_current_keyword="(updating || selecting_file)"
                                                    :keyword_categories="keyword_categories"
                                                    v-on:change="onFieldChange(file, 'keywords', null, true)"
                                                    :no_header="true"
                                                ></keywords-field-with-assistant>
                                            </div>

                                            <div
                                                class="form-group"
                                                :id="'asset_type_id-fg' + file.id"
                                            >
                                                <label
                                                    :for="'asset_type_id' + file.id"
                                                    :id="'asset_type_id-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    {{ $root.ucfirst($root.contents.asset_title) }} type
                                                    <span v-if="file.id != 'bulk_edit'">*</span>
                                                </label>
                                                <searchable-option-list
                                                    :key="'asset_type_id'"
                                                    v-model="file.asset_type_id"
                                                    :value="file.asset_type_id"
                                                    :options="asset_types"
                                                    :field_id="'asset_type_id' + file.id"
                                                    field_class="mandatory-field"
                                                    :readonly="(updating && !validating)"
                                                    v-on:change="onFieldChange(file, 'asset_type_id', null, true)"
                                                ></searchable-option-list>
                                            </div>

                                            <div class="form-group" :id="'restricted-fg' + file.id">
                                                <label>
                                                    {{ $root.ucfirst($root.contents.asset_title) }} requires approval
                                                    before download
                                                </label>
                                                <div class="form-control" style="height: auto">
                                                    <div class="mr-4" style="display: inline-block">
                                                        <div class="custom-control custom-radio">
                                                            <input
                                                                :key="'restricted0'"
                                                                v-model="file.restricted"
                                                                type="radio"
                                                                :id="'restricted' + file.id + '-0'"
                                                                class="custom-control-input"
                                                                :value="0"
                                                                :disabled="(updating && !validating)"
                                                                @click="onFieldChange(file, 'restricted')"
                                                            >
                                                            <label
                                                                class="custom-control-label"
                                                                :for="'restricted' + file.id + '-0'"
                                                            >No</label>
                                                        </div>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <div class="custom-control custom-radio">
                                                            <input
                                                                :key="'restricted1'"
                                                                v-model="file.restricted"
                                                                type="radio"
                                                                :id="'restricted' + file.id + '-1'"
                                                                class="custom-control-input"
                                                                :value="1"
                                                                :disabled="(updating && !validating)"
                                                                @click="onFieldChange(file, 'restricted')"
                                                            >
                                                            <label
                                                                class="custom-control-label"
                                                                :for="'restricted' + file.id + '-1'"
                                                            >Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="form-group"
                                                :id="'folder_id-fg' + file.id"
                                            >
                                                <label
                                                    :for="'folder_id' + file.id"
                                                    :id="'folder_id-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    {{ $root.ucfirst($root.contents.folder_title) }}
                                                    <span v-if="file.id != 'bulk_edit'">*</span>
                                                </label>
                                                <searchable-option-list
                                                    :key="'folder_id'"
                                                    v-model="file.folder_id"
                                                    :value="file.folder_id"
                                                    :options="folders"
                                                    :field_id="'folder_id' + file.id"
                                                    field_class="mandatory-field"
                                                    :readonly="(updating && !validating)"
                                                    v-on:change="onFieldChange(file, 'folder_id', null, true)"
                                                ></searchable-option-list>
                                            </div>

                                            <div
                                                v-if="hasSubfolders(file.folder_id)"
                                                class="form-group"
                                                :id="'subfolders-fg' + file.id"
                                            >
                                                <label
                                                    :for="'subfolders' + file.id"
                                                    :id="'subfolders-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    {{ $root.ucfirst($root.contents.subfolders_title) }}
                                                </label>
                                                <searchable-option-list
                                                    :key="'subfolders'"
                                                    v-model="file.subfolders"
                                                    :value="(file.subfolders || [])"
                                                    :multiple="true"
                                                    :options="getSubfolderOptions(file.folder_id)"
                                                    :field_id="'subfolders' + file.id"
                                                    :field_class="'form-control pb-2'"
                                                    :readonly="(updating && !validating)"
                                                    :placeholder="'You can choose more than one'"
                                                    v-on:change="onFieldChange(file, 'subfolders')"
                                                ></searchable-option-list>
                                            </div>

                                            <div v-for="cf in custom_fields" :key="cf.id">
                                                <div
                                                    v-if="hasCustomField(file.folder_id, cf)"
                                                    class="form-group"
                                                    :id="'cf' + cf.id + '-fg' + file.id"
                                                >
                                                    <label
                                                        :for="'cf' + cf.id  + file.id"
                                                        :id="'cf' + cf.id + '-label' + file.id"
                                                        class="mandatory-label"
                                                    >
                                                        {{ cf.name }}
                                                        <span v-if="cf.required && (file.id != 'bulk_edit')">*</span>
                                                    </label>
                                                    <input
                                                        v-if="(cf.type == 'text')"
                                                        v-model="file.custom_fields[cf.id]"
                                                        type="text"
                                                        class="form-control mandatory-field"
                                                        :id="'cf' + cf.id + file.id"
                                                        :readonly="(updating && !validating)"
                                                        v-on:keyup="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                        v-on:change="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                    >
                                                    <textarea
                                                        v-if="(cf.type == 'textarea')"
                                                        v-model="file.custom_fields[cf.id]"
                                                        class="form-control mandatory-field"
                                                        :id="'cf' + cf.id + file.id"
                                                        rows="4"
                                                        :readonly="(updating && !validating)"
                                                        v-on:keyup="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                        v-on:change="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                    ></textarea>
                                                    <searchable-option-list
                                                        :key="'cf' + cf.id"
                                                        v-if="(cf.type == 'multiselect')"
                                                        v-model="file.custom_fields[cf.id]"
                                                        :value="(file.custom_fields[cf.id] || [])"
                                                        :multiple="true"
                                                        :options="getCustomFieldOptions(cf)"
                                                        :field_id="'cf' + cf.id + file.id"
                                                        :field_class="'form-control mandatory-field pb-2'"
                                                        :readonly="(updating && !validating)"
                                                        v-on:change="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                    ></searchable-option-list>
                                                    <searchable-option-list
                                                        :key="'cf' + cf.id"
                                                        v-if="(cf.type == 'singleselect')"
                                                        v-model="file.custom_fields[cf.id]"
                                                        :value="file.custom_fields[cf.id]"
                                                        :options="getCustomFieldOptions(cf)"
                                                        :field_id="'cf' + cf.id + file.id"
                                                        field_class="mandatory-field"
                                                        :readonly="(updating && !validating)"
                                                        v-on:change="onFieldChange(file, 'custom_fields',
                                                            cf.id, cf.required)"
                                                    ></searchable-option-list>
                                                </div>
                                            </div>

                                            <div
                                                v-if="(user_groups.length > 0)"
                                                class="form-group"
                                                :id="'user_groups-fg' + file.id"
                                            >
                                                <label
                                                    :for="'user_groups' + file.id"
                                                    :id="'user_groups-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    User group(s)
                                                    <span
                                                        v-if="isUserGroupRequired() && (file.id != 'bulk_edit')"
                                                    >*</span>
                                                </label>
                                                <searchable-option-list
                                                    :key="'user_groups'"
                                                    v-model="file.user_groups"
                                                    :value="(file.user_groups || [])"
                                                    :multiple="true"
                                                    :options="getUserGroupOptions()"
                                                    :field_id="'user_groups' + file.id"
                                                    :field_class="'form-control mandatory-field pb-2'"
                                                    :readonly="(updating && !validating)"
                                                    :placeholder="'You can choose more than one'"
                                                    v-on:change="onFieldChange(file, 'user_groups')"
                                                ></searchable-option-list>
                                            </div>

                                            <div
                                                class="form-group"
                                                :id="'output_options-fg' + file.id"
                                            >
                                                <label
                                                    :for="'output_options' + file.id"
                                                    :id="'output_options-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Output options
                                                </label>
                                                <searchable-option-list
                                                    :key="'output_options'"
                                                    v-model="output_options"
                                                    :multiple="true"
                                                    :value="getOutputOptionValues(file)"
                                                    :options="getOutputOptions(file)"
                                                    :field_id="'output_options' + file.id"
                                                    field_class="form-control pb-2 mandatory-field"
                                                    :placeholder="'None'"
                                                    :readonly="(updating && !validating)"
                                                    v-on:change="onFieldChange(file, 'output_options')"
                                                ></searchable-option-list>
                                            </div>

                                            <div
                                                v-if="file.print_order"
                                                class="form-group"
                                                :id="'price_panels-fg' + file.id"
                                            >
                                                <label
                                                    :id="'price_panels-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Price panel(s)
                                                    <span v-if="file.id != 'bulk_edit'">*</span>
                                                </label>
                                                <div
                                                    :id="'price_panels' + file.id"
                                                    class="form-control pb-0 mandatory-field"
                                                    style="height: auto"
                                                >
                                                    <div v-if="file.price_panels.length > 0" class="form-group">
                                                        <div
                                                            v-for="price_panel in file.price_panels"
                                                            :key="price_panel.id"
                                                            style="padding-top: 2px; padding-bottom: 2px"
                                                        >
                                                            {{ price_panel.title }}
                                                            <span class="float-right ml-2">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-secondary btn-sm"
                                                                    v-b-tooltip.hover.left
                                                                    title="Remove"
                                                                    @click="removePricePanel(file, price_panel.id)"
                                                                    :disabled="(updating && !validating)"
                                                                    style="padding-top: 1px; padding-bottom: 1px"
                                                                >
                                                                    <i class="far fa-trash-alt"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" :id="'price_panel_type_id-fg' + file.id">
                                                        <label>Add price panel</label>
                                                        <searchable-option-list
                                                            :key="'price_panel_type_id' + file.id"
                                                            v-model="file.price_panel_type_id"
                                                            :value="file.price_panel_type_id"
                                                            :options="price_panel_types"
                                                            :field_id="'price_panel_type_id' + file.id"
                                                            search_placeholder="Select price panel type"
                                                            :readonly="(updating && !validating)"
                                                            v-on:change="onFieldChange(file, 'price_panel_type_id')"
                                                        ></searchable-option-list>
                                                    </div>
                                                    <div
                                                        v-if="file.price_panel_type_id"
                                                        class="form-group"
                                                        :id="'price_panel_id-fg' + file.id"
                                                    >
                                                        <searchable-option-list
                                                            :key="'price_panel_id' + file.id"
                                                            v-model="file.price_panel_id"
                                                            :multiple="true"
                                                            :value="getPricePanelIds(file)"
                                                            :options="getPricePanelOptions(file.price_panel_type_id)"
                                                            :field_id="'price_panel_id' + file.id"
                                                            search_placeholder="Select price panel to add"
                                                            :readonly="(updating && !validating)"
                                                            v-on:change="onFieldChange(file, 'price_panel_id')"
                                                        ></searchable-option-list>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group"
                                                :id="'notes-fg' + file.id"
                                            >
                                                <label
                                                    :for="'notes' + file.id"
                                                    :id="'notes-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Notes
                                                </label>
                                                <textarea
                                                    v-model="file.notes"
                                                    :id="'notes' + file.id"
                                                    class="form-control mandatory-field"
                                                    rows="4"
                                                    :readonly="(updating && !validating)"
                                                    :placeholder="placeholder(file, 'notes')"
                                                    v-on:focus="placeholder(file, 'notes', true)"
                                                    v-on:blur="placeholder(file, 'notes', true)"
                                                    v-on:keyup="onFieldChange(file, 'notes')"
                                                    v-on:change="onFieldChange(file, 'notes')"
                                                ></textarea>
                                            </div>

                                            <div
                                                class="form-group"
                                                :id="'review_date-fg' + file.id"
                                            >
                                                <label
                                                    :for="'review_date' + file.id"
                                                    :id="'review_date-label' + file.id"
                                                    class="mandatory-label"
                                                >
                                                    Review date (optional)
                                                </label>
                                                <div class="input-group">
                                                    <b-form-datepicker
                                                        v-model="file.review_date"
                                                        :id="'review_date' + file.id"
                                                        class="form-control"
                                                        start-weekday="1"
                                                        locale="en-GB"
                                                        :min="today()"
                                                        :hideHeader="true"
                                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit',
                                                            'year': 'numeric' }"
                                                        :disabled="(updating_review_date || (updating && !validating))"
                                                        v-on:input="onReviewDateUpdated"
                                                    ></b-form-datepicker>
                                                    <div class="input-group-append">
                                                        <button
                                                            class="btn btn-light dropdown-toggle date-presets-button"
                                                            type="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :disabled="(updating && !validating)"
                                                        >
                                                            Preset periods
                                                            <i
                                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                                :style="'font-size: 9px; position: relative; top: -1px; '
                                                                    + 'left: 2px'"
                                                            ></i>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a
                                                                v-for="preset in review_date_presets"
                                                                :key="preset.month"
                                                                class="dropdown-item"
                                                                href="#"
                                                                @click.prevent="setDate(file, 'review_date',
                                                                    preset.month)"
                                                            >{{ preset.text }}</a>
                                                        </div>
                                                    </div>
                                                    <button
                                                        v-if="(file.review_date && (!updating || validating))"
                                                        class="btn btn-link clear-review-date"
                                                        @click="clearReviewDate(file)"
                                                    >
                                                        <i class="far fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    class="text-right"
                    :style="'position: relative; display: ' + ((uploaded_files.length > 0) ? 'block' : 'none')"
                >
                    <hr>

                    <div class="progress-display">
                        <span v-if="(uploading || updating)" v-html="uploadProgress()"></span>
                        <div v-if="uploading" class="asset-overall-progress">
                            <div class="asset-overall-upload" :style="'width: ' + upload_progress.progress + '%'"></div>
                        </div>
                    </div>
                    <div v-if="(uploading || updating)" class="mb-2 d-block d-lg-none"></div>

                    <span class="button-holder left-buttons">
                        <span class="button-holder" :style="addMoreFilesStyle()">
                            <button
                                type="button"
                                class="btn btn-secondary mb-2 mb-lg-0"
                                id="asset-add-more-files"
                                :disabled="updating"
                            >
                                <i class="far fa-cloud-upload-alt mr-1"></i>
                                Add more files
                            </button>
                        </span>
                        <!--<span
                            v-if="(uploaded_files.length > 1) && (selected_file != bulk_edit_file)"
                            class="button-holder"
                        >
                            <button
                                type="button"
                                class="btn btn-secondary mb-2 mb-lg-0 ml-lg-2 asset-bulk-edit-button"
                                :disabled="updating"
                                @click="editFile(bulk_edit_file)"
                            >
                                Edit common data
                            </button>
                        </span>-->
                    </span>

                    <span v-if="(isLastFile() && !updating)" class="d-none d-lg-inline">
                        <button
                            :key="'finish-button'"
                            type="button"
                            class="btn btn-primary"
                            @click="onSubmit()"
                            :disabled="((uploaded_files.length == 0) || !selected_file)"
                        >
                            <i class="far fa-check mr-1"></i> Finish
                        </button>
                    </span>
                    <span v-if="updating" class="d-none d-lg-inline">
                        <button :key="'finishing-button'" type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Finish</span>
                        </button>
                    </span>

                    <span v-if="!isLastFile()" class="d-none d-lg-inline">
                        <button
                            :key="'save-and-next-button'"
                            type="button"
                            class="btn btn-primary"
                            @click="selectFile('next')"
                            :disabled="updating"
                        >
                            Save and next
                            <i class="far fa-chevron-down ml-1"></i>
                        </button>
                    </span>

                    <div class="row no-gutters d-lg-none" style="width: 100%; margin: 0">
                        <div class="col">
                            <span v-if="(isLastFile() && !updating)" class="button-holder">
                                <button
                                    :key="'finish-button-sm'"
                                    type="button"
                                    class="btn btn-primary submit-form"
                                    @click="onSubmit()"
                                    :disabled="((uploaded_files.length == 0) || !selected_file)"
                                >
                                    <i class="far fa-check mr-1"></i> Finish
                                </button>
                            </span>
                            <span v-if="updating" class="button-holder">
                                <button :key="'finishing-button-sm'" type="button" class="btn btn-primary submit-form">
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">Finish</span>
                                </button>
                            </span>

                            <span v-if="!isLastFile()" class="button-holder">
                                <button
                                    :key="'save-and-next-button-sm'"
                                    type="button"
                                    class="btn btn-primary submit-form"
                                    @click="selectFile('next')"
                                    :disabled="updating"
                                >
                                    Save and next
                                    <i class="far fa-chevron-down ml-1"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                component_name: 'asset-add-form',
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                message: false,
                uploaded_files: [],
                upload_progress: {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                },
                selecting_file: false,
                selected_file: null,
                edit_request_file: null,
                loading_edit_form: false,
                num_assets_to_create: 0,
                assets_created: [],
                assets_failed: [],
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (10 * 1000 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: '#asset-add-files, #asset-add-more-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                asset_types: [],
                folders: [],
                review_date_presets: [
                    {
                        month: 0,
                        text: 'Today'
                    },
                    {
                        month: 1,
                        text: 'In a month'
                    },
                    {
                        month: 6,
                        text: 'In 6 months'
                    },
                    {
                        month: 12,
                        text: 'In a year'
                    },
                    {
                        month: 24,
                        text: 'In 2 years'
                    },
                    {
                        month: 36,
                        text: 'In 3 years'
                    },
                    {
                        month: 60,
                        text: 'In 5 years'
                    },
                ],
                validating: false,
                block_file_select: false,
                user_groups: [],
                custom_fields: [],
                updating_review_date: false,
                keyword_categories: [],
                bulk_edit_file: {},
                files_edited: false,
                files_bulk_edited: false,
                multiple_uploads_tooltip_shown: false,
                price_panel_types: [],
                output_options: [],
                on_close_redirect_to: null
            }
        },
        mounted () {
            var vm = this;
            $(window).bind('beforeunload', function(eventObject) {
                let returnValue = undefined;
                if (vm.uploaded_files.length > 0) {
                    returnValue = "Are you sure you want to leave this page? Any uploads will be cancelled.";
                }
                eventObject.returnValue = returnValue;

                return returnValue;
            });
        },
        watch: {
            uploaded_files: function (val) {
                if (val.length > 0) {
                    $('.form-column').addClass('pl-lg-2');
                    $('#asset-dropzone').addClass('full-height');

                    if (val.length > 1) {
                        this.updateBulkEditFile();
                    }

                    return null;
                }
                $('.form-column').removeClass('pl-lg-2');
                $('#asset-dropzone').removeClass('full-height');
            },
            assets_created: function (val) {
                if (val.length > 0) {
                    if (val.length == this.num_assets_to_create) {
                        if (this.validating) {
                            this.validating = false;
                            this.createAssets();
                        } else {
                            $('#message-modal').modal('hide');

                            const resources = (this.num_assets_to_create === 1) 
                                ? this.$root.ucfirst(this.$root.contents.asset_title) 
                                : this.$root.ucfirst(this.$root.contents.asset_title) + 's';
                            this.updated = resources + ' added successfully';
                            this.$root.folders_changed = true;
                            this.uploaded_files = [];
                            $('.dropzone-container').hide();
                            var vm = this;
                            
                            setTimeout(function () {
                                vm.$parent.hideAssetAddForm();
                                let assetIds = [];
                                vm.assets_created.forEach((item) => {
                                    assetIds.push(item.asset_id);
                                });
                                if (assetIds.length == 1) {
                                    vm.$root.redirect_to_home = true;
                                }
                                vm.$router.push({
                                    name: 'assets-filtered',
                                    params: { filters: 'id::' + assetIds.join(',') } 
                                });
                            }, 2000);
                        }
                    } else if ((val.length + this.assets_failed.length) == this.num_assets_to_create) {
                        this.onAssetsCreated();
                    }
                }
            },
            assets_failed: function (val) {
                if (val.length > 0) {
                    if ((val.length + this.assets_created.length) == this.num_assets_to_create) {
                        this.onAssetsCreated();
                    }
                }
            },
            uploading: function (val) {
                if (!val && (this.uploaded_files.length > 1)) {
                    let bytesTotal = 0;
                    this.uploaded_files.forEach((item, index) => {
                        bytesTotal += item.file_size;
                    });
                    this.upload_progress.bytes_total = bytesTotal;
                }
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.uploading = false;
                this.updating = false;
                this.updated = false;
                this.message = false;
                this.uploaded_files = [];
                this.upload_progress = {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                };
                this.selecting_file = false;
                this.selected_file = null;
                this.loading_edit_form = false;
                this.bulk_edit_file = {
                    asset_type_id: null,
                    folder_id: null,
                    restricted: 0,
                    custom_fields: {},
                    //keywords: '',
                    user_groups: [],
                    downloadable: true,
                    print_order: false,
                    web_image: false,
                    price_panel_type_id: null,
                    price_panel_id: [],
                    price_panels: []
                };
                this.files_edited = false;
                this.files_bulk_edited = false;
                this.output_options = [0];
                if (this.$refs.assetDropzone) {
                    this.$refs.assetDropzone.removeAllFiles();
                }
                this.getAssetTypes();
                this.getFolders();
                this.getUserGroups();
                this.getCustomFields();
                this.getKeywordSuggestions();
                this.price_panel_types = [];
                if (this.$root.isFeatured('printing')) {
                    this.getPricePanels();
                }
                $('.dropzone-container').show();

                const cookieName = this.$root.app.client_id + '_mutt' + this.$root.user.id;
                this.multiple_uploads_tooltip_shown = this.$cookie.get(cookieName);
            },
            onSubmit: function(delay = true) {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (this.uploading) {
                    this.showPleaseWaitMessage();

                    return null;
                }

                this.updating = true;

                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 500);

                    return null;
                }

                this.validating = (this.uploaded_files.length > 1);
                this.createAssets();
            },
            uploadProgress: function () {
                if (this.message) {
                    return this.message;
                }
                const percent = this.upload_progress.progress.toFixed(1);

                return '<div class="pb-2" style="text-align: center">Uploading ' + ((percent < 100) ? percent : '100')
                    + '%</div>';
            },
            updateBulkEditFile: function () {
                if (!this.bulk_edit_file.id) {
                    this.bulk_edit_file.id = 'bulk_edit';
                    const file = this.uploaded_files[0];
                    const excludeFields = [
                        'id',
                        'filename',
                        'file_type',
                        'file_size',
                        'preview_image',
                        'upload',
                        'checked',
                        'temp_file',
                        'title',
                        'edited',
                        'keywords',
                        'detected_labels',
                        'detected_labels_removed',
                        'all_keywords'
                    ];
                    for (const [key, value] of Object.entries(file)) {
                        if (excludeFields.indexOf(key) < 0) {
                            this.bulk_edit_file[key] = value;
                        }
                    }
                    this.editFile(this.bulk_edit_file);
                }
            },
            getFilesToEdit() {
                let items = [];
                if (this.uploaded_files.length > 1) {
                    items.push(this.bulk_edit_file);
                }
                this.uploaded_files.forEach((item, index) => {
                    items.push(item);
                });

                return items;
            },
            onFileAdded: function (file) {
                if (this.updating) {
                    this.$refs.assetDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.assetDropzone.removeFile(file);
                    this.$parent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#multiple-uploads-tooltip-modal').modal('hide');
                    $('#message-modal').modal('show');

                    return null;
                }

                this.message = false;
                this.uploading = true;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null),
                    asset_type_id: null,
                    folder_id: null,
                    restricted: 0,
                    custom_fields: {},
                    keywords: '',
                    detected_labels: '',
                    detected_labels_removed: '',
                    all_keywords: '',
                    user_groups: this.isUserGroupRequired() ? [] : [-1],
                    downloadable: true,
                    print_order: false,
                    web_image: false,
                    price_panel_type_id: null,
                    price_panel_id: [],
                    price_panels: []
                };
                if (this.$root.client.asset_upload_populate_title) {
                    newFile.title = this.createTitleFromFilename(file.name);
                }
                this.uploaded_files.push(newFile);
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);

                if (this.uploaded_files.length == 1) {
                    this.editFile(this.uploaded_files[0]);

                    return null;
                }

                this.bulkEdit(this.uploaded_files[this.uploaded_files.length - 1]);

                if (!this.multiple_uploads_tooltip_shown) {
                    this.showMultipleUploadsTooltip();
                }
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let bytesTotal = 0;
                let bytesSentTotal = 0;
                let html = '';
                this.uploaded_files.forEach((item) => {
                    bytesTotal += item.file_size;
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        bytesSentTotal += bytesSent;
                        html = (progress < 100)
                            ? 'Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%'
                            : 'Previewing...';
                        $('#upload-info' + item.id).html(html);
                    } else if (item.upload.progress == 100) {
                        bytesSentTotal += item.file_size;
                    }
                });
                this.upload_progress.progress = ((bytesTotal * bytesSentTotal) > 0)
                    ? ((bytesSentTotal / bytesTotal) * 100) : 0;
                this.upload_progress.bytes_total = bytesTotal;
                this.upload_progress.bytes_sent = bytesSentTotal;
                $('#upload-info' + this.bulk_edit_file.id)
                    .html('Uploading ' + this.$root.formatFileSize(bytesSentTotal) + ' '
                        + this.upload_progress.progress.toFixed(1) + '%');
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-info' + file.upload.uuid).hide();
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        this.uploaded_files[itemIndex].preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    this.getUploadInfo(this.uploaded_files[itemIndex].id);
                    this.$refs.assetDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500/detect-labels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }
                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                    }
                    if (response.data.detected_labels) {
                        if (Array.isArray(response.data.detected_labels)) {
                            vm.uploaded_files[itemIndex].detected_labels = response.data.detected_labels.join(',');
                            if (vm.$root.client.assets_use_detected_labels) {
                                let allKeywords = [];
                                if (vm.uploaded_files[itemIndex].all_keywords) {
                                    allKeywords = vm.uploaded_files[itemIndex].all_keywords.split(',');
                                }
                                response.data.detected_labels.forEach((item) => {
                                    allKeywords.push('[detected]' + item);
                                });
                                vm.uploaded_files[itemIndex].all_keywords = allKeywords.join(',');
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                this.upload_progress.progress = 100;
                this.upload_progress.bytes_sent = this.upload_progress.bytes_total;

                $('#upload-info' + this.bulk_edit_file.id).html('&nbsp;');
                if ($('#message-modal').hasClass('show') && (this.$parent.message.title == 'Submit forms')) {
                    $('#message-modal').modal('hide');
                }
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    this.errors = 'Something went wrong, please try again';
                }
                console.log(file, message);
            },
            onDragOver: function (event) {
                $('#asset-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#asset-dropzone').removeClass('dz-drag-highlight');
            },
            editFile: function(file, validate = false, delay = true, confirmed = false) {
                if (this.block_file_select) {
                    return null;
                }
                if ((file.id != 'bulk_edit') && (this.uploaded_files.indexOf(file) < 0)) {
                    return null;
                }

                this.selecting_file = true;
                var vm = this;

                // Jira R2-246 - do not validate if not edited.
                if (
                    validate
                    && (this.selected_file.id != 'bulk_edit')
                    && !this.selected_file.edited
                    && !this.files_bulk_edited
                ) {
                    validate = false;
                }

                if (validate && delay) {
                    var eFile = file;
                    var eValidate = validate;
                    setTimeout(function () {
                        vm.editFile(eFile, eValidate, false);
                    }, 200);

                    return null;
                }
                this.loading_edit_form = true;

                if (this.selected_file && !this.updating) {
                    if ((this.selected_file.id != 'bulk_edit') && validate) {
                        this.edit_request_file = file;
                        // Validate current form before loading the next one
                        this.assets_created = [];
                        this.assets_failed = [];
                        this.createAsset(this.selected_file, true);

                        return null;
                    }
                }

                if ((file.id == 'bulk_edit') && this.files_edited && !confirmed) {
                    this.selecting_file = false;
                    this.loading_edit_form = false;
                    this.$parent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"><p>You have '
                            + 'already entered data for individual ' + this.$root.contents.asset_title + 's.</p> '
                            + '<p class="mb-0">Any changes you make to whole batch will overwrite any individual data '
                            + 'entered</p></div> </div>',
                        component: this,
                        action: 'bulk-edit',
                        buttons: {
                            yes: 'Continue',
                            cancel: 'Cancel'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }

                this.output_options = this.getOutputOptionValues(file);
                this.selecting_file = false;
                this.selected_file = file;
                this.selected_file.price_panel_type_id = null;
                this.selected_file.price_panel_id = [];
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                
                setTimeout(function () {
                    $('.dz-uploaded-file').removeClass('active');
                    $('#' + file.id).addClass('active');
                    vm.loading_edit_form = false;
                    $('html, body').animate({ scrollTop: 0 }, 700);

                    // Highlight errors
                    setTimeout(function () {
                        let firstErrorField = null;
                        vm.assets_failed.forEach((item) => {
                            if (item.file_id == file.id) {
                                item.error_fields.forEach((field) => {
                                    if (!firstErrorField) {
                                        firstErrorField = field;
                                    }
                                    if (field == 'price_panel_id') {
                                        $('#price_panels-label' + file.id).addClass('text-danger');
                                        $('#price_panels' + file.id).addClass('field-error');
                                    } else {
                                        $('#' + field + '-label' + file.id).addClass('text-danger');
                                        $('#' + field + file.id).addClass('field-error');
                                    }
                                });
                            }
                        });

                        // Scroll to file in upload column
                        let scrollTo = 0;
                        let found = (vm.selected_file.id == 'bulk_edit');
                        if (!found) {
                            vm.uploaded_files.forEach((item) => {
                                if (!found && (item != vm.selected_file)) {
                                    scrollTo += $('#' + item.id).outerHeight();
                                } else {
                                    found = true;
                                }
                            });
                        }
                        $('#asset-dropzone').animate({ scrollTop: scrollTo }, 700);
                    }, 500);
                }, 500);

                return false;
            },
            doNothing: function () {
                return null;
            },
            createAssets: function () {
                this.num_assets_to_create = this.uploaded_files.length;
                this.assets_created = [];
                this.assets_failed = [];

                if (this.validating) {
                    this.message = 'Checking forms';
                    this.uploaded_files.forEach((file) => {
                        this.createAsset(file, true);
                    });

                    return null;
                }

                if (this.num_assets_to_create > 1) {
                    this.showDoNotCloseMessage();
                }

                this.message = '<span class="loading mr-1"><i class="fal fa-spinner-third fa-spin"></i></span> '
                    + 'Adding ' + this.$root.contents.asset_title;
                if (this.num_assets_to_create != 1) {
                    this.message += 's';
                }
                
                var vm = this;
                vm.uploaded_files.forEach((file, index) => {
                    setTimeout(function () {
                        vm.createAsset(file);
                    }, (index * 1000 + 100));
                });
            },
            createAsset: function (file, preValidate = false) {
                /**
                 * Send request to API.
                 */
                let data = {};
                const excludeFields = [
                    'preview_image',
                    'upload',
                    'checked',
                    'edited',
                    'price_panel_type_id',
                    'price_panel_id',
                    'all_keywords'
                ];
                for (const [key, value] of Object.entries(file)) {
                    if (excludeFields.indexOf(key) < 0) {
                        switch (key) {
                            case 'custom_fields':
                                data[key] = this.getCustomFieldsValue(value);
                                break;
                            case 'web_image':
                                if (this.isImageAsset(file)) {
                                    data.web_image = file.web_image;
                                }
                                break;
                            case 'print_order':
                                if (this.$root.isFeatured('printing')) {
                                    data.print_order = file.print_order;
                                }
                                break;
                            case 'price_panels':
                                if (this.$root.isFeatured('printing') && file.print_order) {
                                    data.price_panels = this.getPricePanelsValue(value);
                                }
                                break;
                            default:
                                data[key] = value;
                        }
                    }
                }

                let path = '/' + this.$root.app.client_id + '/resource';
                if (preValidate) {
                    path += '/validate';
                }
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    //$('#' + response.data.file_id).removeClass('greyed-out');
                    vm.checkFile(response.data.file_id, true);
                    if ((response.data.message == 'Validation OK.') && !vm.validating) {
                        vm.editFile(vm.edit_request_file);

                        return null;
                    }
                    vm.assets_created.push({
                        file_id: response.data.file_id,
                        asset_id: (vm.validating) ? null : response.data.asset_id
                    });
                    if (vm.validating) {
                        vm.message = 'Form ' + (vm.assets_created.length + vm.assets_failed.length) + ' of '
                            + vm.num_assets_to_create + ' checked';
                    } else {
                        vm.message = '<span class="loading mr-1"><i class="fal fa-spinner-third fa-spin"></i></span> '
                            + this.$root.ucfirst(this.$root.contents.asset_title) + ' '
                            + vm.assets_created.length + ' of ' + vm.num_assets_to_create + ' added';
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.handleFormErrors(error.response.data);
                        }
                    }
                });
            },
            handleFormErrors: function (data) {
                const fileId = data.file_id;
                this.checkFile(fileId, false);
                let errorFields = [];
                if (typeof data.error === 'string') {
                    this.errors = data.error;
                } else if (typeof data.error === 'object') {
                    //this.errors = '';
                    for (const [key, value] of Object.entries(data.error)) {
                        errorFields.push(key);
                    }
                }
                this.assets_failed.push({
                    file_id: fileId,
                    error_fields: errorFields
                });
                if (this.validating) {
                    this.message = 'Form ' + (this.assets_created.length + this.assets_failed.length) + ' of '
                        + this.num_assets_to_create + ' checked';
                }
                if (data.message) {
                    if ((data.message == 'Validation failed.') && (this.assets_failed.length == 1)) {
                        this.uploaded_files.forEach((file) => {
                            if (file.id == fileId) {
                                this.editFile(file);
                            }
                        });
                        this.errors = 'The fields highlighted below are required.';
                    }
                }
            },
            onAssetsCreated: function () {
                this.updating = false;
                if (this.validating) {
                    this.validating = false;

                    return null;
                }
                $('#message-modal').modal('hide');
                const resources = (this.num_assets_to_create === 1) ? this.$root.contents.asset_title
                    : this.$root.contents.asset_title + 's';
                if (this.errors) {
                    this.errors += '<br>';
                } else {
                    this.errors = '';
                }
                this.errors += this.assets_failed.length + ' out of ' + this.num_assets_to_create + ' ' + resources
                    + ' could not be added. The fields highlighted below are required.';

                this.$root.folders_changed = true;

                if (this.assets_created.length > 0) {
                    this.assets_created.forEach((item) => {
                        this.removeUploadedFile(item.file_id, false, true);
                    });

                    /*if (this.isUnfilteredAssetsPage()) {
                        const component = this.$root.findComponent(this.$parent, 'assets');
                        if (component) {
                            component.getAssets();
                        }
                    }*/
                }
                this.editFile(this.uploaded_files[0]);
            },
            bulkEdit: function (file, field = null, property = null) {
                if (this.uploaded_files.length < 2) {
                    return null;
                }
                if (file.id == 'bulk_edit') {
                    this.uploaded_files.forEach((item) => {
                        if (field == 'output_options') {
                            item.downloadable = file.downloadable;
                            item.print_order = file.print_order;
                            item.web_image = file.web_image;
                        } else if (field == 'custom_fields') {
                            item.custom_fields[property] = file.custom_fields[property];
                        } else {
                            item[field] = file[field];
                            if (field == 'folder_id') {
                                item.subfolders = null;
                                item.custom_fields = {};
                            }
                        }
                    });

                    return null;
                }
                const excludeFields = [
                    'preview_image',
                    'upload',
                    'title',
                    'file_size',
                    'file_type',
                    'filename',
                    'id',
                    'checked',
                    'edited',
                    'output_options',
                    'keywords',
                    'detected_labels',
                    'detected_labels_removed',
                    'all_keywords'
                ];
                for (const [key, value] of Object.entries(this.bulk_edit_file)) {
                    if (excludeFields.indexOf(key) < 0) {
                        if (key == 'custom_fields') {
                            for (const [cfKey, cfValue] of Object.entries(this.bulk_edit_file.custom_fields)) {
                                file.custom_fields[cfKey] = cfValue;
                            }
                        } else {
                            file[key] = value;
                        }
                    }
                }
                file.downloadable = this.bulk_edit_file.downloadable;
                file.print_order = this.bulk_edit_file.print_order;
                file.web_image = this.bulk_edit_file.web_image;
            },
            hasErrors: function (fileId) {
                if (this.assets_failed.length == 0) {
                    return false;
                }
                let errors = false;
                this.assets_failed.forEach((item) => {
                    if (item.file_id == fileId) {
                        errors = true;
                    }
                });

                return errors;
            },
            getAssetTypes: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/!pagination|active';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            assetTypeRestricted: function (assetTypeId) {
                let restricted = 0;
                this.asset_types.forEach((item) => {
                    if (item.id == assetTypeId) {
                        restricted = item.restricted;
                    }
                });

                return restricted;
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|!archived';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                    if (!vm.isUserGroupRequired()) {
                        vm.bulk_edit_file.user_groups = [-1];
                    }
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getCustomFields: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let customFields = [];
                    response.data.custom_fields.forEach((item) => {
                        if (item.area != 'T') {
                            customFields.push(item);
                        }
                    });
                    vm.custom_fields = customFields;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            getOutputOptions: function (file) {
                return [
                    {
                        id: 0,
                        name: 'Download',
                        disabled: (this.output_options.indexOf(2) > -1)
                    },
                    {
                        id: 1,
                        name: 'Order item',
                        disabled: (
                            !this.$root.isFeatured('printing')
                            || (this.output_options.indexOf(2) > -1)
                        )
                    },
                    {
                        id: 2,
                        name: 'Web image',
                        disabled: !this.isImageAsset(file)
                    }
                ];
            },
            getOutputOptionValues: function (file) {
                if (file.web_image) {
                    return [2];
                }

                let values = [];
                if (file.downloadable) {
                    values.push(0);
                }
                if (file.print_order) {
                    values.push(1);
                }

                return values;
            },
            /*isUnfilteredAssetsPage: function () {
                return (this.$route.name == 'assets');
            },*/
            hasSubfolders: function (folderId) {
                if (!this.folders || !folderId) {
                    return false;
                }
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return false;
                }
                if (!this.folders[itemIndex].subfolders) {
                    return false;
                }

                return (this.folders[itemIndex].subfolders.length > 0);
            },
            getSubfolderOptions: function (folderId) {
                if (!this.folders || !folderId) {
                    return null;
                }
                let options = [];
                this.folders.forEach((folderItem) => {
                    if (folderItem.id == folderId) {
                        if (folderItem.subfolders) {
                            folderItem.subfolders.forEach((item) => {
                                if (!item.archived) {
                                    options.push({ id: item.id, name: item.name });
                                }
                            });
                        }
                    }
                });
                if (options.length < 0) {
                    return null;
                }

                return options;
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: '<strong>All users</strong>'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            isUserGroupRequired: function () {
                if (!this.$root.client.asset_user_group_required) {
                    return false;
                }

                return (this.user_groups.length > 0);
            },
            hasCustomField: function (folderId, customField) {
                if (!folderId) {
                    return false;
                }
                if (!customField.folders) {
                    return true;
                }
                if (customField.folders.length == 0) {
                    return true;
                }

                return (customField.folders.indexOf(folderId) > -1);
            },
            getCustomFieldOptions: function (customField) {
                const customFieldOptions = (customField.options || []);
                let options = [];
                customFieldOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text
                    });
                });

                return options;
            },
            getCustomFieldsValue: function (customFields) {
                if (!customFields) {
                    return null;
                }
                let customFieldsValue = {};
                let customFieldValue = null;
                let customFieldOptions = [];
                for (const [key, value] of Object.entries(customFields)) {
                    this.custom_fields.forEach((item, index) => {
                        if (key == item.id) {
                            customFieldValue = value;
                            if (item.type == 'multiselect') {
                                customFieldValue = [];
                                if (value.length > 0) {
                                    customFieldOptions = (item.options || []);
                                    customFieldOptions.forEach((option, optionIndex) => {
                                        if (value.indexOf(optionIndex) > -1) {
                                            customFieldValue.push(option.value);
                                        }
                                    });
                                }
                            }
                            if (item.type == 'singleselect') {
                                customFieldOptions = (item.options || []);
                                customFieldOptions.forEach((option, optionIndex) => {
                                    if (value == optionIndex) {
                                        customFieldValue = option.value;
                                    }
                                });
                            }
                            customFieldsValue[key] = customFieldValue;
                        }
                    });
                }

                return customFieldsValue;
            },
            getPricePanelsValue: function (pricePanels) {
                let pricePanelIds = [];
                pricePanels.forEach((item) => {
                    pricePanelIds.push(item.id);
                });

                return pricePanelIds;
            },
            placeholder: function (file, field, update = false) {
                const focused = $('#' + field + file.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + file.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'title':
                        text = 'Give the ' + this.$root.contents.asset_title + ' a title';
                        break;
                    case 'description':
                        text = (this.$root.contents.description_title == 'description')
                            ? 'Any other details/description?' : '';
                        break;
                    case 'notes':
                        text = 'Any notes like captions, photographer, special instructions etc?';
                        break;
                }
                if (update) {
                    $('#' + field + file.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            setDate: function (model, dateField, monthsToAdd) {
                this.updating_review_date = true;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + monthsToAdd);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                model[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                if (model.id == 'bulk_edit') {
                    this.bulkEdit(model, 'review_date');
                }
                this.updating_review_date = false;
            },
            clearAllData: function (confirmed = false) {
                if (confirmed) {
                    const items = this.getFilesToEdit();
                    items.forEach((item) => {
                        delete item.title;
                        delete item.description;
                        item.keywords = '';
                        item.all_keywords = '';
                        item.asset_type_id = null;
                        item.folder_id = null;
                        delete item.subfolders;
                        item.user_groups = this.isUserGroupRequired() ? [] : [-1];
                        item.restricted = 0;
                        delete item.notes;
                        delete item.review_date;
                        item.custom_fields = {};
                        item.checked = false;
                        item.edited = false;
                    });
                    this.files_edited = false;
                    this.files_bulk_edited = false;
                    this.errors = false;
                    this.assets_failed = [];

                    this.editFile(items[0]);

                    return null;
                }
                this.$parent.confirm_delete = {
                    title: 'Clear all data',
                    text: 'Are you sure you want to clear all data entered on the form'
                        + ((this.uploaded_files.length > 1) ? 's' : '') + '?',
                    component: this,
                    action: 'clear-all-data'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'clear-all-data') {
                    this.clearAllData(true);

                    return null;
                }
                if (action == 'close-modal') {
                    this.onClose(true, this.on_close_redirect_to);

                    return null;
                }
                if (action == 'bulk-edit') {
                    this.editFile(this.bulk_edit_file, false, false, true);

                    return null;
                }
                if (action == 'delete-all-files') {
                    this.resetForm();

                    return null;
                }
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true, true);
                }
            },
            removeUploadedFile: function(fileId, selectNextFile = true, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        if (this.selected_file) {
                            if (
                                (
                                    (this.selected_file.id == fileId)
                                    || ((this.selected_file.id == 'bulk_edit') && (this.uploaded_files.length < 3))
                                )
                                && selectNextFile
                            ) {
                                var vm = this;
                                setTimeout(function () {
                                    if (vm.uploaded_files.length > 0) {
                                        vm.editFile(vm.uploaded_files[0]);
                                    }
                                }, 200);
                            }
                        }
                        const removeUploadFileId = (this.uploaded_files[fileIndex].upload.progress < 100) ? fileId
                            : null;

                        this.uploaded_files.splice(fileIndex, 1);

                        if (removeUploadFileId) {
                            this.$refs.assetDropzone.getUploadingFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.assetDropzone.removeFile(file);
                                }
                            });
                            this.$refs.assetDropzone.getQueuedFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.assetDropzone.removeFile(file);
                                }
                            });
                        }

                        if (!this.uploading) {
                            let bytesTotal = 0;
                            this.uploaded_files.forEach((item) => {
                                bytesTotal += item.file_size;
                            });
                            this.upload_progress = {
                                bytes_total: bytesTotal,
                                bytes_sent: bytesTotal,
                                progress: 100
                            };
                        }
                    
                        return null;
                    }
                    this.block_file_select = true;

                    this.$parent.confirm_delete = {
                        title: 'Delete ' + this.$root.contents.asset_title,
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');

                    var vm = this;
                    setTimeout(function () {
                        vm.block_file_select = false;
                    }, 1000);
                }
            },
            removeAllFiles: function () {
                this.block_file_select = true;
                this.$parent.confirm_delete = {
                    title: 'Delete ' + this.$root.contents.asset_title + 's',
                    text: 'Are you sure you want to delete all ' + this.$root.contents.asset_title + 's?',
                    component: this,
                    action: 'delete-all-files'
                };
                $('#confirm-delete-modal').modal('show');
                var vm = this;
                setTimeout(function () {
                    vm.block_file_select = false;
                }, 1000);
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                this.block_file_select = true;

                this.$parent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');

                var vm = this;
                setTimeout(function () {
                    vm.block_file_select = false;
                }, 1000);
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            today: function () {
                return new Date();
            },
            showDoNotCloseMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Submit forms',
                    loading: true,
                    text: '<div style="text-align: center">'
                        + 'Your files are processing please do not close this window until this completes</div>'
                };
                $('#message-modal').modal('show');
            },
            showPleaseWaitMessage: function () {
                this.$parent.message = {
                    type: null,
                    title: 'Submit forms',
                    loading: true,
                    text: '<div style="text-align: center">Please wait until all files are uploaded</div>'
                };
                $('#message-modal').modal('show');
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            addMoreFilesStyle: function () {
                if ((this.uploaded_files.length > 0) && !this.uploading) {
                    return 'display: inline';
                }

                return 'display: none';
            },
            clearAllDataDesktopStyle: function () {
                if (this.uploading) {
                    return 'position: absolute; bottom: 12px; left: 15px; width: 180px';
                }

                return 'position: absolute; bottom: 12px; left: 180px; width: calc(100% - 360px); text-align: center';
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item, index) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            clearReviewDate: function (file) {
                this.updating_review_date = true;
                file.review_date = null;

                if (file.id == 'bulk_edit') {
                    this.bulkEdit(file, 'review_date');
                }

                var vm = this;
                setTimeout(function () {
                    vm.updating_review_date = false;
                }, 200);
            },
            /*isFirstFile: function () {
                const filesCount = this.uploaded_files.length;
                if (!this.selected_file || (filesCount == 0)) {
                    return true;
                }
                const firstFile = this.uploaded_files[0];

                return (firstFile.id == this.selected_file.id);
            },*/
            isLastFile: function () {
                const filesCount = this.uploaded_files.length;
                if (!this.selected_file || (filesCount == 0)) {
                    return true;
                }
                const lastFile = this.uploaded_files[filesCount - 1];

                return (lastFile.id == this.selected_file.id);
            },
            selectFile: function (which) {
                const filesCount = this.uploaded_files.length;
                if (!this.selected_file || (filesCount == 0)) {
                    return null;
                }
                let currentFileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == this.selected_file.id) {
                        currentFileIndex = index;
                    }
                });
                if ((which == 'prev') && (currentFileIndex > 0)) {
                    this.editFile(this.uploaded_files[currentFileIndex - 1]);
                }
                if ((which == 'next') && (currentFileIndex < (filesCount - 1))) {
                    this.editFile(this.uploaded_files[currentFileIndex + 1], true);
                }

                return null;
            },
            checkFile: function (fileId, check) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                this.uploaded_files.forEach((item) => {
                    if (item.id == fileId) {
                        item.checked = check;
                    }
                });
            },
            onFieldChange: function (file, field, property = null, mandatory = false, delay = true) {
                var vm = this;
                if (delay) {
                    var cFile = file;
                    var cField = field;
                    var cProperty = property;
                    var cMandatory = mandatory;
                    setTimeout(function () {
                        vm.onFieldChange(cFile, cField, cProperty, cMandatory, false);
                    }, 100);

                    return null;
                }

                if (field == 'asset_type_id') {
                    file.restricted = this.assetTypeRestricted(file.asset_type_id);
                }

                if (field == 'folder_id') {
                    file.subfolders = null;
                    file.custom_fields = {};
                }

                if (field == 'price_panel_type_id') {
                    file.price_panel_id = this.getPricePanelIds(file);

                    return null;
                }

                if (field == 'price_panel_id') {
                    const pricePanelIds = this.getPricePanelsValue(file.price_panels);
                    file.price_panel_id.forEach((item) => {
                        if (pricePanelIds.indexOf(item) < 0) {
                            file.price_panels.push({ id: item, title: this.getPricePanelTitle(item) });
                        }
                    });
                    let pricePanelsToRemove = [];
                    this.getPricePanelOptions(file.price_panel_type_id).forEach((item) => {
                        if ((pricePanelIds.indexOf(item.id) > -1) && (file.price_panel_id.indexOf(item.id) < 0)) {
                            pricePanelsToRemove.push(item.id);
                        }
                    });
                    pricePanelsToRemove.forEach((item) => {
                        this.removePricePanel(file, item);
                    });

                    field = 'price_panels';
                }

                if (field == 'output_options') {
                    if (this.output_options.indexOf(2) > -1) {
                        file.downloadable = false;
                        file.print_order = false;
                        file.web_image = true;
                        this.output_options = [2];
                    } else {
                        file.downloadable = (this.output_options.indexOf(0) > -1);
                        file.print_order = (this.output_options.indexOf(1) > -1);
                        file.web_image = false;
                    }
                    if (!file.print_order) {
                        file.price_panel_type_id = null;
                        file.price_panel_id = [];
                        file.price_panels = [];
                    }
                }

                if (file.id == 'bulk_edit') {
                    this.files_bulk_edited = true;
                    this.bulkEdit(file, field, property);

                    return null;
                }

                this.files_edited = true;
                if (mandatory) {
                    file.edited = true;
                }

                if (field == 'custom_fields') {
                    field = 'cf' + property;
                }

                if (field == 'keywords') {
                    let keywords = [];
                    let detectedLabels = [];
                    const originalDetectedLabels = file.detected_labels ? file.detected_labels.split(',') : [];
                    const allKeywords = file.all_keywords.split(',');
                    allKeywords.forEach((item) => {
                        if (item.indexOf('[detected]') == 0) {
                            detectedLabels.push(item.substring(10));
                        } else {
                            keywords.push(item);
                        }
                    });
                    file.keywords = keywords.join(',');
                    if (this.$root.client.assets_use_detected_labels) {
                        file.detected_labels = detectedLabels.join(',');
                    }
                    let removedLabels = file.detected_labels_removed ? file.detected_labels_removed.split(',') : [];
                    originalDetectedLabels.forEach((item) => {
                        if ((detectedLabels.indexOf(item) == -1) && (removedLabels.indexOf(item) == -1)) {
                            removedLabels.push(item);
                        }
                    });
                    file.detected_labels_removed = removedLabels.join(',');
                }

                this.$root.unHighlightErrors(field, file.id);
            },
            onReviewDateUpdated: function (date) {
                if (this.selected_file.id == 'bulk_edit') {
                    this.onFieldChange(this.bulk_edit_file, 'review_date');
                }
            },
            onClose: function (confirmed, redirectTo = null) {
                if (!confirmed && (this.uploaded_files.length > 0)) {
                    this.on_close_redirect_to = redirectTo;
                    this.$parent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"> '
                            + 'Are you sure you want to leave this page? Any uploads will be cancelled. '
                            + '</div> </div>',
                        component: this,
                        action: 'close-modal',
                        buttons: {
                            yes: 'Leave',
                            cancel: 'Stay'
                        }
                    };
                    $('#confirm-delete-modal').modal('show');

                    return null;
                }
                this.$parent.hideAssetAddForm();
                this.uploaded_files = [];

                if (redirectTo) {
                    if (redirectTo.name == 'logout') {
                        const component = this.$root.findComponent(this.$parent, 'main-nav');
                        if (component) {
                            component.logout();
                        }

                        return null;
                    }
                    this.$router.push(redirectTo);
                }
            },
            showMultipleUploadsTooltip: function (delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.showMultipleUploadsTooltip(false);
                    }, 800);

                    return null;
                }
                if ($('#message-modal').hasClass('show')) {
                    return null;
                }
                $('#multiple-uploads-tooltip-modal').modal('show');
                this.multiple_uploads_tooltip_shown = true;
            },
            isImageAsset: function (file) {
                const imageTypes = ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf'];

                return (imageTypes.indexOf(file.file_type) > -1);
            },
            getPricePanels: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.price_panel_types = response.data.price_panel_types;
                })
                .catch(function (error) {
                    //
                });
            },
            getPricePanelOptions: function (panelTypeId) {
                let options = [];
                this.price_panel_types.forEach((type) => {
                    if (type.id == panelTypeId) {
                        type.price_panels.forEach((item) => {
                            options.push({
                                id: item.id,
                                name: item.title
                            });
                        });
                    }
                });

                return options;
            },
            getPricePanelTitle: function (panelId) {
                let title = '';
                this.price_panel_types.forEach((type) => {
                    type.price_panels.forEach((item) => {
                        if (item.id == panelId) {
                            title = item.title;
                        }
                    });
                });

                return title;
            },
            getPricePanelIds: function (file) {
                if (!file.price_panel_type_id) {
                    return [];
                }
                const pricePanelIds = this.getPricePanelsValue(file.price_panels);
                const pricePanels = this.getPricePanelOptions(file.price_panel_type_id);
                let ids = [];
                pricePanels.forEach((item) => {
                    if (pricePanelIds.indexOf(item.id) > -1) {
                        ids.push(item.id);
                    }
                });

                return ids;
            },
            removePricePanel: function (file, panelId) {
                let itemIndex = -1;
                file.price_panels.forEach((item, index) => {
                    if (item.id == panelId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    file.price_panels.splice(itemIndex, 1);
                }

                let pricePanelIds = [];
                file.price_panel_id.forEach((item) => {
                    if (item != panelId) {
                        pricePanelIds.push(item);
                    }
                });
                file.price_panel_id = pricePanelIds;
            },
            createTitleFromFilename: function (filename) {
                if (!filename) {
                    return null;
                }
                let parts = filename.split('.');
                parts.pop();
                filename = parts.join('.');
                if (!filename) {
                    return null;
                }

                return filename.replace(/-/g, ' ').replace(/_/g, ' ');
            }
        }
    }
</script>
<style scoped>
    #asset-add-form {
        display: none;
        max-width: 1600px;
    }

    #asset-add-files {
        padding-top: 135px;
    }

    .dropzone-container {
        height: calc(100% - 15px);
    }

    #asset-dropzone {
        min-height: 300px;
        /*padding-right: 16px;*/
    }

    .dz-uploaded-file:hover, .dz-uploaded-file.checked {
        border-color: #f8f9fa;
    }

    .dz-uploaded-file.greyed-out {
        border-color: #f8f9fa;
    }

    .dz-uploaded-file.greyed-out > div {
        filter: grayscale(80%);
        opacity: 0.5;
    }

    .dz-uploaded-file.greyed-out:hover {
        border-color: #f8f9fa;
    }

    .dz-uploaded-file.checked {
        background-color: #eff2f7;
    }

    .dz-uploaded-file.active {
        border-width: 3px;
        background-color: #fff;
    }

    .dz-uploaded-file .dz-filename {
        max-height: 40px;
        width: calc(100% - 155px);
        margin-bottom: 0;
    }

    #asset-dropzone .dz-uploaded-file .dz-upload-info {
        left: 160px;
    }

    #asset-dropzone .dz-uploaded-file .dz-progress {
        width: calc(100% - 172px);
    }

    #asset-dropzone .dz-uploaded-file.individual .dz-thumbnail .view-image-icon {
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        padding: 6px 10px;
    }

    .clear-review-date {
        position: absolute;
        top: 2px;
        right: 127px;
    }

    .left-buttons {
        position: absolute;
        left: 0;
        bottom: 0;
        white-space: nowrap;
    }

    .button-holder {
        margin: 0;
    }

    .progress-display {
        position: absolute;
        left: 280px;
        bottom: 12px;
        width: calc(100% - 560px);
        text-align: center;
    }

    #asset-dropzone.full-height {
        height: 100%;
        max-height: 800px;
        overflow-y: auto;
        overflow-x: visible;
    }

    @media (max-width: 767px) {
        .dz-uploaded-file .dz-filename {
            display: block;
            margin-top: 10px;
            margin-bottom: 35px;
            /*width: calc(100% - 90px);*/
            width: 100%;
            max-width: none;
            max-height: none;
        }

        .dz-uploaded-file .dz-upload-info {
            position: static;
            padding-bottom: 15px;
            width: calc(100% - 90px);
        }

        .dz-uploaded-file .dz-progress {
            width: calc(100% - 22px) !important;
        }

        .button-holder, #asset-add-more-files, .asset-bulk-edit-button {
            display: block;
            width: 100%
        }

        .progress-display {
            position: relative;
            left: 0px;
            bottom: 0px;
            display: block;
            width: 100%;
            margin: 0;
        }

        .submit-form {
            display: block;
            width: 100%;
        }

        #asset-dropzone.full-height {
            height: auto;
        }

        .left-buttons {
            position: static;
        }
    }

    @media (min-width: 768px) {
        #asset-dropzone .dz-uploaded-file.individual .dz-thumbnail {
            width: 200px;
            height: 156px;
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-thumbnail img {
            max-height: 156px;
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-filename {
            max-height: 118px;
            width: calc(100% - 220px);
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-upload-info {
            left: 225px;
            max-width: calc(100% - 310px);
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-progress {
            width: calc(100% - 237px);
        }
    }

    /*@media (min-width: 1200px) {
        .dz-uploaded-file.active:after {
            content: "";
            position: absolute;
            top: 34px;
            right: -26px;
            border-top: 3px solid;
            border-right: 5px solid;
            width: 49px;
            height: 26px;
            transform: rotate(75deg) skewX(60deg);
        }
    }*/

    @media (min-width: 1270px) {
        #asset-dropzone .dz-uploaded-file.individual .dz-thumbnail {
            width: 270px;
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-filename {
            width: calc(100% - 290px);
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-upload-info {
            left: 295px;
            max-width: calc(100% - 380px);
        }

        #asset-dropzone .dz-uploaded-file.individual .dz-progress {
            width: calc(100% - 307px);
        }
    }
</style>
