<template>
    <div>
        <loading v-if="loading"></loading>

        <div v-if="!proof && errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="proof && !loading" class="row">
            <div class="col">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12 pb-sm-0 pb-3 asset-preview">
                                <div class="thumbnail">
                                    <img :src="image_src" :alt="proof.title">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12" style="min-height: 290px">
                                <div
                                    v-if="chart_data"
                                    :style="'position: absolute; top: 0; right: 0; left: 0; margin: auto; '
                                        + 'width: 180px; height: 165px'"
                                >
                                    <apex-stroked-gauge-chart
                                        :percent="chart_data.percent"
                                        :dash_array="4"
                                        :width="chart_data.width"
                                        :height="chart_data.height"
                                        :title="chart_data.title"
                                        :subtitle="chart_data.subtitle"
                                    ></apex-stroked-gauge-chart>
                                </div>
                                <div
                                    class="text-center"
                                    style="position: absolute; left: 12px; width: calc(100% - 24px); bottom: 10px"
                                >
                                    <div
                                        v-if="proof.target_date"
                                        style="display: inline-block; max-width: 100%; text-align: left; width: 154px"
                                    >
                                        <span :class="'text-' + (datePassed(proof.target_date) ? 'danger' : 'muted')">
                                            <i
                                                class="fal fa-calendar-check mr-1 font-size-22"
                                                style="position: relative; top: 14px"
                                            ></i> Project target date:
                                        </span>
                                        <div style="padding-left: 29px">{{ target_date_formatted }}&nbsp;</div>
                                    </div>
                                    <div style="height: 1px"></div>
                                    <div
                                        v-if="proof.version_target_date"
                                        class="mt-3"
                                        style="display: inline-block; max-width: 100%; text-align: left; width: 154px"
                                    >
                                        <span
                                            :class="'text-' + (datePassed(proof.version_target_date) ? 'danger'
                                                : 'muted')"
                                        >
                                            <i
                                                class="fal fa-calendar-day mr-1 font-size-22"
                                                style="position: relative; top: 14px"
                                            ></i> Cycle target date:
                                        </span>
                                        <div style="padding-left: 29px">{{ version_target_date_formatted }}&nbsp;</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-center d-sm-none" style="padding-top: 11px">
                                <div style="display: inline-block; max-width: 100%; text-align: left; width: 154px">
                                    <span class="text-muted">
                                        <span style="width: 20px">
                                            <i
                                                class="fal fa-calendar-clock mr-1 font-size-22"
                                                style="position: relative; top: 14px"
                                            ></i>
                                        </span>
                                        Start date:
                                    </span>
                                    <div style="padding-left: 29px">{{ created_at_formatted }}&nbsp;</div>
                                </div>
                            </div>
                            <div class="col-12 text-center d-sm-none" style="padding-top: 28px">
                                <span class="mr-1">
                                    <i class="fal fa-crown gold font-size-22"></i>
                                </span>
                                <span v-if="!isLeader()">
                                    <a
                                        href="#"
                                        @click.prevent="emailLeader()"
                                        v-b-tooltip.hover
                                        title="Email the leader"
                                    >
                                        {{ getLeaderName() }}
                                    </a>
                                </span>
                                <span v-else>{{ getLeaderName() }}</span>
                            </div>
                            <div class="col-sm-6 col-12 pt-3 text-center d-lg-none">
                                <span
                                    v-if="proof.approved"
                                    class="badge badge-success font-size-13"
                                    style="padding: 11px"
                                >Completed {{ formatDate(proof.approved_at) }}</span>
                                <span v-if="!proof.approved && proof.suspended">
                                    <span
                                        v-if="isLeader() && (proof.number_of_reviewed >= proof.number_of_reviewers)"
                                        class="badge badge-light font-size-13"
                                        style="padding: 11px"
                                    >Ready for next cycle</span>
                                    <span
                                        v-else
                                        class="badge badge-danger font-size-13"
                                        style="padding: 11px"
                                    >Suspended</span>
                                </span>
                                <span v-if="!proof.suspended && !proof.approved">
                                    <span v-if="canReview() && !proof.in_use">
                                        <button
                                            v-if="downloading != 'proof_review'"
                                            :key="'review-button'"
                                            type="button"
                                            class="btn btn-primary"
                                            @click="reviewProof()"
                                            :disabled="downloading"
                                        ><i class="far fa-file-arrow-down mr-1"></i> Download and review</button>
                                        <button
                                            v-else
                                            :key="'downloading-button'"
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">Downloading</span>
                                        </button>
                                    </span>
                                    <button
                                        v-if="canReview() && proof.in_use"
                                        :key="'return-button'"
                                        type="button"
                                        class="btn btn-primary"
                                        data-toggle="modal"
                                        data-target="#proof-return-modal"
                                        @click="returnProof()"
                                    ><i class="far fa-file-arrow-up mr-1"></i> Return this proof</button>
                                    <span v-if="!canReview()">
                                        <span
                                            v-if="proof.number_of_reviewed >= proof.number_of_reviewers"
                                            class="badge badge-light font-size-13"
                                            style="padding: 11px"
                                        >Cycle complete</span>
                                        <span v-else>
                                            <span
                                                v-if="proof.in_use
                                                    && (isLeader() || $root.hasPermission('proofs_view_others'))"
                                                class="badge bg-secondary text-secondary font-size-13"
                                                style="padding: 11px; margin-right: 12px"
                                            >&nbsp;In use&nbsp;</span>
                                            <span v-else class="badge badge-light font-size-13" style="padding: 11px">
                                                <span v-if="hasReturned()">{{ hasReturned() }}</span>
                                                <span v-else>
                                                    <span
                                                        v-if="proof.in_use || (!isLeader()
                                                            && !$root.hasPermission('proofs_view_others'))"
                                                    >&nbsp;In use&nbsp;</span>
                                                    <span v-else>
                                                        {{ proof.previous_reviewer ? 'Last review: '
                                                            + proof.previous_reviewer.name : 'Awaiting next reviewer' }}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div
                                            v-if="proof.in_use
                                                && (isLeader() || $root.hasPermission('proofs_view_others'))"
                                            style="display: inline-block; position: relative"
                                        >
                                            <div
                                                v-if="getReviewerProfileImage()"
                                                :key="'reviewer-profile-image'"
                                                class="rounded-circle avatar-xs"
                                                :style="'position: absolute; top: -27px; left: -22px; width: 44px; '
                                                    + 'height: 44px; border: 2px solid #fff'"
                                                v-b-tooltip.hover
                                                :title="getReviewerName()"
                                            >
                                                <img
                                                    class="rounded-circle avatar-xs"
                                                    :src="getReviewerProfileImage()"
                                                    alt="avatar"
                                                    style="width: 40px; height: 40px"
                                                >
                                            </div>
                                            <div
                                                v-else
                                                :key="'reviewer-initials'"
                                                class="rounded-circle avatar-xs"
                                                :style="'position: absolute; top: -27px; left: -22px; width: 44px; '
                                                    + 'height: 44px; border: 2px solid #fff'"
                                                v-b-tooltip.hover
                                                :title="getReviewerName()"
                                            >
                                                <span class="avatar-title rounded-circle" style="font-size: 16px">
                                                    {{ getReviewerInitials() }}
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                            </div>
                            <div
                                class="col-sm-6 col-12 text-center d-lg-none d-sm-block d-none"
                                style="padding-top: 10px"
                            >
                                <div style="display: inline-block; max-width: 100%; text-align: left; width: 154px">
                                    <span class="text-muted">
                                        <span style="width: 20px">
                                            <i
                                                class="fal fa-calendar-clock mr-1 font-size-22"
                                                style="position: relative; top: 14px"
                                            ></i>
                                        </span>
                                        Start date:
                                    </span>
                                    <div style="padding-left: 29px">{{ created_at_formatted }}&nbsp;</div>
                                </div>
                            </div>
                            <div class="col-12 text-center d-lg-none d-sm-block d-none" style="padding-top: 28px">
                                <span class="mr-1">
                                    <i class="fal fa-crown gold font-size-22"></i>
                                </span>
                                <span v-if="!isLeader()">
                                    <a
                                        href="#"
                                        @click.prevent="emailLeader()"
                                        v-b-tooltip.hover
                                        title="Email the leader"
                                    >
                                        {{ getLeaderName() }}
                                    </a>
                                </span>
                                <span v-else>{{ getLeaderName() }}</span>
                            </div>

                            <div
                                v-if="!isLeader() && !isNonReviewer() && !proof.suspended && !proof.approved
                                    && canReview() && proof.in_use"
                                class="col-lg-4 col-12 mt-lg-0 mt-3"
                            >
                                <h5>Actions</h5>
                                <button
                                    :key="'lost-proof-button'"
                                    type="button"
                                    class="btn btn-secondary btn-block mb-2"
                                    data-toggle="modal"
                                    data-target="#proof-return-modal"
                                    @click="returnProof('LP')"
                                    style="width: 190px"
                                >
                                    <i class="far fa-binoculars mr-1"></i> Lost proof?
                                </button>
                                <button
                                    :key="'review-later-button'"
                                    type="button"
                                    class="btn btn-secondary btn-block mb-2"
                                    data-toggle="modal"
                                    data-target="#proof-return-modal"
                                    @click="returnProof('UR')"
                                    style="width: 190px"
                                >
                                    <i class="far fa-clock mr-1"></i> Review later
                                </button>
                            </div>
                            <div
                                v-if="!isLeader() && isNonReviewer() && !proof.archived"
                                class="col-lg-4 col-12 mt-lg-0 mt-3"
                            >
                                <h5>Actions</h5>
                                <button
                                    v-if="downloading != 'proof_actions'"
                                    :key="'non-reviewer-download-proof-button'"
                                    type="button"
                                    class="btn btn-secondary mb-2"
                                    @click="downloadProof()"
                                    :disabled="leader_action"
                                    style="width: 190px"
                                >
                                    <i class="far fa-download mr-1"></i> Download
                                    {{ proof.approved ? 'final proof' : 'latest proof' }}
                                </button>
                                <button
                                    v-else
                                    :key="'non-reviewer-downloading-proof-button'"
                                    type="button"
                                    class="btn btn-secondary mb-2"
                                    style="width: 190px"
                                >
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">
                                        Download {{ proof.approved ? 'final proof' : 'latest proof' }}
                                    </span>
                                </button>
                            </div>

                            <div v-if="isLeader()" class="col-lg-4 col-12 mt-lg-0 mt-3">
                                <h5>Actions</h5>
                                <div
                                    class="contact-links font-size-22"
                                    style="position: absolute; top: -10px; right: 12px"
                                >
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#proof-info-modal"
                                        v-b-tooltip.hover
                                        title="Proof info"
                                    ><i class="far fa-info-circle"></i></a>
                                    <a
                                        v-if="!proof.archived"
                                        href="#"
                                        class="ml-2"
                                        data-toggle="modal"
                                        data-target="#proof-leader-actions-modal"
                                        v-b-tooltip.hover
                                        title="More actions"
                                    ><i class="far fa-cog"></i></a>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-sm-6 col-12">
                                        <div v-if="!proof.suspended && !proof.approved && canReview() && proof.in_use">
                                            <button
                                                :key="'lost-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                data-toggle="modal"
                                                data-target="#proof-return-modal"
                                                @click="returnProof('LP')"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-binoculars mr-1"></i> Lost proof?
                                            </button>
                                            <button
                                                :key="'review-later-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                data-toggle="modal"
                                                data-target="#proof-return-modal"
                                                @click="returnProof('UR')"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-clock mr-1"></i> Review later
                                            </button>
                                        </div>
                                        <div v-if="proof.archived">
                                            <button
                                                v-if="leader_action != 'dearchive'"
                                                :key="'dearchive-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                @click="deArchiveProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-archive mr-1"></i> De-archive
                                            </button>
                                            <button
                                                v-else
                                                :key="'dearchiving-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">De-archive</span>
                                            </button>
                                            <button
                                                v-if="leader_action != 'delete'"
                                                :key="'delete-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                @click="deleteProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-trash-can mr-1"></i> Delete proof
                                            </button>
                                            <button
                                                v-else
                                                :key="'deleting-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Delete proof</span>
                                            </button>
                                        </div>
                                        <div v-if="!proof.archived">
                                            <button
                                                v-if="leader_action != 'download-proof'"
                                                :key="'download-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="downloadProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-download mr-1"></i> Download
                                                {{ proof.approved ? 'final proof' : 'latest proof' }}
                                            </button>
                                            <button
                                                v-else
                                                :key="'downloading-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">
                                                    Download {{ proof.approved ? 'final proof' : 'latest proof' }}
                                                </span>
                                            </button>
                                        </div>
                                        <div v-if="!proof.suspended">
                                            <button
                                                v-if="leader_action != 'suspend'"
                                                :key="'suspend-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="suspendProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-lock-keyhole mr-1"></i> Suspend
                                            </button>
                                            <button
                                                v-else
                                                :key="'suspending-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Suspend</span>
                                            </button>
                                        </div>
                                        <div v-if="canUnsuspend()">
                                            <button
                                                v-if="leader_action != 'unsuspend'"
                                                :key="'unsuspend-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="unsuspendProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-lock-keyhole-open mr-1"></i> Unsuspend
                                            </button>
                                            <button
                                                v-else
                                                :key="'unsuspending-proof-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Unsuspend</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <button
                                            v-if="canUploadNextVersion()"
                                            :key="'next-version-button'"
                                            type="button"
                                            class="btn btn-secondary mb-2"
                                            data-toggle="modal"
                                            data-target="#proof-next-version-modal"
                                            @click="nextVersion()"
                                            :disabled="leader_action"
                                            style="width: 190px"
                                        >
                                            <i class="far fa-cloud-upload mr-1"></i> Upload new version
                                        </button>
                                        <div v-if="canEndCycle()">
                                            <button
                                                v-if="leader_action != 'end-cycle'"
                                                :key="'end-cycle-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="endCycle()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-hand mr-1"></i> End this cycle
                                            </button>
                                            <button
                                                v-else
                                                :key="'ending-cycle-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">End this cycle</span>
                                            </button>
                                        </div>
                                        <!--<button
                                            v-if="!proof.archived"
                                            :key="'email-team-button'"
                                            type="button"
                                            class="btn btn-secondary mb-2"
                                            data-toggle="modal"
                                            data-target="#email-team-modal"
                                            @click="emailTeam()"
                                            :disabled="leader_action || !canEmailTeam()"
                                            style="width: 190px"
                                        >
                                            <i class="far fa-envelope mr-1"></i> Email team
                                        </button>-->
                                        <div v-if="!proof.archived && !proof.approved">
                                            <button
                                                v-if="leader_action != 'proof-complete'"
                                                :key="'proof-complete-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="proofComplete()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                Finish project
                                            </button>
                                            <button
                                                v-else
                                                :key="'proof-completing-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Finish project</span>
                                            </button>
                                        </div>
                                        <div v-if="canMakeAvailable()">
                                            <button
                                                v-if="leader_action != 'make-available'"
                                                :key="'make-available-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                @click="makeAvailable()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-arrow-rotate-left mr-1"></i> Make available
                                            </button>
                                            <button
                                                v-else
                                                :key="'making-available-button'"
                                                type="button"
                                                class="btn btn-secondary mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Make available</span>
                                            </button>
                                        </div>
                                        <div v-if="!proof.archived && proof.approved">
                                            <button
                                                v-if="leader_action != 'archive'"
                                                :key="'archive-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                @click="archiveProof()"
                                                :disabled="leader_action"
                                                style="width: 190px"
                                            >
                                                <i class="far fa-archive mr-1"></i> Archive
                                            </button>
                                            <button
                                                v-else
                                                :key="'archiving-proof-button'"
                                                type="button"
                                                class="btn btn-secondary btn-block mb-2"
                                                style="width: 190px"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Archive</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-none d-lg-flex">
                            <div class="col-4 pt-3 text-center">
                                <span
                                    v-if="proof.approved"
                                    class="badge badge-success font-size-13"
                                    style="padding: 11px"
                                >Completed {{ formatDate(proof.approved_at) }}</span>
                                <span v-if="!proof.approved && proof.suspended">
                                    <span
                                        v-if="isLeader() && (proof.number_of_reviewed >= proof.number_of_reviewers)"
                                        class="badge badge-light font-size-13"
                                        style="padding: 11px"
                                    >Ready for next cycle</span>
                                    <span
                                        v-else
                                        class="badge badge-danger font-size-13"
                                        style="padding: 11px"
                                    >Suspended</span>
                                </span>
                                <span v-if="!proof.suspended && !proof.approved">
                                    <span v-if="canReview() && !proof.in_use">
                                        <button
                                            v-if="downloading != 'proof_review'"
                                            :key="'review-button'"
                                            type="button"
                                            class="btn btn-primary"
                                            @click="reviewProof()"
                                            :disabled="downloading"
                                        ><i class="far fa-file-arrow-down mr-1"></i> Download and review</button>
                                        <button
                                            v-else
                                            :key="'downloading-button'"
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">Downloading</span>
                                        </button>
                                    </span>
                                    <button
                                        v-if="canReview() && proof.in_use"
                                        :key="'return-button'"
                                        type="button"
                                        class="btn btn-primary"
                                        data-toggle="modal"
                                        data-target="#proof-return-modal"
                                        @click="returnProof()"
                                    ><i class="far fa-file-arrow-up mr-1"></i> Return this proof</button>
                                    <span v-if="!canReview()">
                                        <span
                                            v-if="proof.number_of_reviewed >= proof.number_of_reviewers"
                                            class="badge badge-light font-size-13"
                                            style="padding: 11px"
                                        >Cycle complete</span>
                                        <span v-else>
                                            <span
                                                v-if="proof.in_use
                                                    && (isLeader() || $root.hasPermission('proofs_view_others'))"
                                                class="badge bg-secondary text-secondary font-size-13"
                                                style="padding: 11px; margin-right: 12px"
                                            >&nbsp;In use&nbsp;</span>
                                            <span v-else class="badge badge-light font-size-13" style="padding: 11px">
                                                <span v-if="hasReturned()">{{ hasReturned() }}</span>
                                                <span v-else>
                                                    <span
                                                        v-if="proof.in_use || (!isLeader()
                                                            && !$root.hasPermission('proofs_view_others'))"
                                                    >&nbsp;In use&nbsp;</span>
                                                    <span v-else>
                                                        {{ proof.previous_reviewer ? 'Last review: '
                                                            + proof.previous_reviewer.name : 'Awaiting next reviewer' }}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div
                                            v-if="proof.in_use
                                                && (isLeader() || $root.hasPermission('proofs_view_others'))"
                                            style="display: inline-block; position: relative"
                                        >
                                            <div
                                                v-if="getReviewerProfileImage()"
                                                :key="'reviewer-profile-image-lg'"
                                                class="rounded-circle avatar-xs"
                                                :style="'position: absolute; top: -27px; left: -22px; width: 44px; '
                                                    + 'height: 44px; border: 2px solid #fff'"
                                                v-b-tooltip.hover
                                                :title="getReviewerName()"
                                            >
                                                <img
                                                    class="rounded-circle avatar-xs"
                                                    :src="getReviewerProfileImage()"
                                                    alt="avatar"
                                                    style="width: 40px; height: 40px"
                                                >
                                            </div>
                                            <div
                                                v-else
                                                :key="'reviewer-initials-lg'"
                                                class="rounded-circle avatar-xs"
                                                :style="'position: absolute; top: -27px; left: -22px; width: 44px; '
                                                    + 'height: 44px; border: 2px solid #fff'"
                                                v-b-tooltip.hover
                                                :title="getReviewerName()"
                                            >
                                                <span class="avatar-title rounded-circle" style="font-size: 16px">
                                                    {{ getReviewerInitials() }}
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                            </div>
                            <div class="col-4 text-center" style="padding-top: 10px">
                                <div style="display: inline-block; max-width: 100%; text-align: left; width: 154px">
                                    <span class="text-muted">
                                        <span style="display: inline-block; width: 24px">
                                            <i
                                                class="fal fa-calendar-clock mr-1 font-size-22"
                                                style="position: relative; top: 14px"
                                            ></i>
                                        </span>
                                        Start date:
                                    </span>
                                    <div style="padding-left: 29px">{{ created_at_formatted }}&nbsp;</div>
                                </div>
                            </div>
                            <div class="col-4 text-center" style="padding-top: 28px">
                                <span class="mr-1">
                                    <i class="fal fa-crown gold font-size-22"></i>
                                </span>
                                <span v-if="!isLeader()">
                                    <a
                                        href="#"
                                        @click.prevent="emailLeader()"
                                        v-b-tooltip.hover
                                        title="Email the leader"
                                    >
                                        {{ getLeaderName() }}
                                    </a>
                                </span>
                                <span v-else>{{ getLeaderName() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <proof-history :proof="proof"></proof-history>
                    </div>
                </div>
            </div>
            <div :class="($root.is_4xl_screen ? 'col-xl-3' : 'col-xl-4') + ' col-lg-4 col-md-5'">
                <div
                    :class="'collaborate-bar px-2 pt-2 mb-md-4 '
                        + ((!$root.is_md_screen && is_team_expanded) ? 'pb-4' : 'pb-2')"
                >
                    <proof-team
                        :proof="proof"
                        :team="getTeam()"
                        :comments="comments"
                        :user_profile_images="comment_user_profile_images"
                        :comments_loading="comments_loading"
                    ></proof-team>
                </div>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-team-edit-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-team-edit-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-team-edit :proof="proof"></proof-team-edit>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-info-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-info-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-leader-actions
                    :proof="proof"
                    :info_only="true"
                    :downloading_linked_asset="downloading_linked_asset"
                ></proof-leader-actions>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-leader-actions-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-leader-actions-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <proof-leader-actions
                    :proof="proof"
                    :leader_action="leader_action"
                    :downloading_linked_asset="downloading_linked_asset"
                ></proof-leader-actions>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-change-folder-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-change-folder-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-change-folder :proof="proof"></proof-change-folder>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-change-leader-alerts-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-change-leader-alerts-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-change-leader-alerts :proof="proof"></proof-change-leader-alerts>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-change-review-alerts-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-change-review-alerts-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-change-review-alerts :proof="proof"></proof-change-review-alerts>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-change-target-dates-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-change-target-dates-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-change-target-dates :proof="proof"></proof-change-target-dates>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-next-version-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-next-version-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-next-version :proof="proof"></proof-next-version>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="proof-share-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="proof-share-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-share :proof="proof"></proof-share>
            </div>
        </div>

        <div
            class="modal fade"
            id="email-team-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="email-team-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-email-team :proof="proof"></proof-email-team>
            </div>
        </div>

        <div
            v-if="isLeader()"
            class="modal fade"
            id="skip-review-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="skip-review-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <proof-skip-review :proof="proof"></proof-skip-review>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'view-proof',
                errors: false,
                loading: true,
                proof: null,
                comments: [],
                comment_user_profile_images: [],
                comments_loading: true,
                is_team_expanded: false,
                image_src: '/images/file-icon.png',
                chart_data: null,
                downloading: null,
                get_comments_interval: null,
                leader_action: null,
                downloading_linked_asset: false,
                get_date_time_formatted_response: null,
                created_at_formatted: '',
                target_date_formatted: '',
                version_target_date_formatted: '',
                reload_proof: false
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.view_proof_title = null;
            if (!this.$root.hasPermission('proofs_access')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getProof();

            var vm = this;
            $(window).on('resize', function () {
                vm.resizeTeamBarContent();
            });
            vm.get_comments_interval = setInterval(function () {
                if (vm.proof) {
                    vm.getComments();
                }
            }, 89000);
        },
        beforeDestroy() {
            clearInterval(this.get_comments_interval);
        },
        watch: {
            is_team_expanded: function (val) {
                this.resizeTeamBarContent();
            },
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'created_at') {
                            this.created_at_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'target_date') {
                            this.target_date_formatted = val.date_time_formatted;
                        }
                        if (val.id == 'version_target_date') {
                            this.version_target_date_formatted = val.date_time_formatted;
                        }
                    }
                }
            }
        },
        methods: {
            getProof: function (proofId = null) {
                this.errors = false;
                if (!proofId) {
                    proofId = parseInt(this.$route.params.id);
                    if (!(proofId > 0)) {
                        this.errors = 'Invalid proof';
                        this.loading = false;

                        return null;
                    }
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + proofId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.proof = response.data.proof;
                    vm.$root.view_proof_title = response.data.proof.title;
                    if (!vm.proof.suspended && (vm.proof.number_of_reviewers > 0)/* && vm.isLeader()*/) {
                        vm.chart_data = {
                            percent: ((vm.proof.number_of_reviewers == 0) || (vm.proof.number_of_reviewed == 0))
                                ? 0 : Math.floor((vm.proof.number_of_reviewed / vm.proof.number_of_reviewers) * 100),
                            width: 180,
                            height: 165,
                            title: {
                                text: vm.proof.number_of_reviewed + '/' + vm.proof.number_of_reviewers,
                                font_family: 'Poppins',
                                font_size: '34px',
                                font_weight: 700,
                                colour: this.$root.client.third_colour,
                                top: 8
                            },
                            subtitle: {
                                text: 'Cycle ' + vm.proof.version,
                                font_size: '19px',
                                font_weight: 500,
                                colour: '#495057',
                                top: 128
                            }
                        };
                    }
                    vm.getPreviewImage();
                    vm.getComments();
                    vm.$root.getDateTimeFormatted(vm, 'created_at', vm.proof.created_at, 'j F Y');
                    if (vm.proof.target_date) {
                        vm.$root.getDateTimeFormatted(vm, 'target_date', vm.proof.target_date, 'j F Y');
                    }
                    if (vm.proof.version_target_date) {
                        vm.$root.getDateTimeFormatted(vm, 'version_target_date', vm.proof.version_target_date, 'j F Y');
                    }

                    // Mark notification as read.
                    setTimeout(function () {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        if (appComponent) {
                            const component = vm.$root.findComponent(appComponent, 'main-nav');
                            if (component) {
                                component.markNotificationAsRead('user', null, 'proof_review', vm.proof.id);
                            }
                        }
                    }, 1200);
                })
                .catch(function (error) {
                    vm.proof = null;
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                    vm.leader_action = null;
                });
            },
            getComments: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-comments/'
                    + this.proof.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.comments = response.data.comments;
                    vm.comment_user_profile_images = response.data.user_profile_images;
                    vm.comments_loading = false;
                    setTimeout(function () {
                        vm.resizeTeamBarContent();
                    }, 200);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            resizeTeamBarContent: function () {
                if (!this.$root.is_md_screen) {
                    $('.collaborate-bar .tab-content').css({
                        'max-height': 'none',
                        'min-height': '0px'
                    });
                    if (!this.is_team_expanded) {
                        $('.collaborate-bar .tab-content').css('height', '0px');
                        $('.collaborate-bar .tab-content #p-team').css('opacity', 0);
                        $('.collaborate-bar .tab-content #p-comments').css('opacity', 0);

                        return null;
                    }
                }
                $('.collaborate-bar .tab-content #p-team').css('opacity', 1);
                $('.collaborate-bar .tab-content #p-comments').css('opacity', 1);
                const windowHeight = $(window).height();
                if (this.$root.is_md_screen) {
                    const maxHeight = windowHeight - 300;
                    $('.collaborate-bar .tab-content').css({
                        'max-height': maxHeight,
                        'min-height': (maxHeight > 300) ? 300 : maxHeight,
                        'height': 'auto'
                    });

                    return null;
                }
                $('.collaborate-bar .tab-content').css({
                    'height': (windowHeight - 200)
                });
            },
            isLeader: function () {
                if (!this.isTeamMember()) {
                    return false;
                }
                let found = false;
                this.proof.users.forEach((item) => {
                    if (item.leader && (item.user_id == this.$root.user.id)) {
                        found = true;
                    }
                });

                return found;
            },
            isNonReviewer: function () {
                if (!this.isTeamMember()) {
                    return false;
                }
                let found = false;
                this.proof.users.forEach((item) => {
                    if (!item.reviewer && (item.user_id == this.$root.user.id)) {
                        found = true;
                    }
                });

                return found;
            },
            isTeamMember: function () {
                if (!this.proof) {
                    return false;
                }
                if (this.proof.users.length == 0) {
                    return false;
                }
                let found = false;
                this.proof.users.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        found = true;
                    }
                });

                return found;
            },
            getPreviewImage: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/preview';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            canReview: function () {
                if (this.proof.suspended || this.proof.archived) {
                    return false;
                }
                if (this.proof.in_use) {
                    if (!this.proof.current_reviewer) {
                        return false;
                    }

                    return (this.proof.current_reviewer.user_id == this.$root.user.id);
                }
                if (this.proof.ordered) {
                    if (!this.proof.next_reviewer) {
                        return false;
                    }

                    return (this.proof.next_reviewer.user_id == this.$root.user.id);
                }

                let alreadyReviewed = false;
                let isReviewer = false;
                let reviewedStatuses = ['AP', 'NC', 'WC', 'MC', 'SK'];
                this.proof.users.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        if (item.reviewer) {
                            isReviewer = true;
                        }
                        if (item.review_status && (reviewedStatuses.indexOf(item.review_status) > -1)) {
                            alreadyReviewed = true;
                        }
                    }
                });

                return (isReviewer && !alreadyReviewed);
            },
            reviewProof: function (confirmed = false) {
                if (!this.proof) {
                    return null;
                }
                if (!this.proof.file) {
                    return null;
                }
                if (confirmed || !this.proof.review_alerts_interval) {
                    this.downloading = 'proof_review';
                    /**
                     * Send request to API.
                     */
                    const download = this.proof.file;
                    const data = {
                        item_type: 'proof_review',
                        item_id: this.proof.id,
                        file_path: download.file_path,
                        file_type: download.file_type,
                        download_filename: download.filename + '.' + download.extension
                    };
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                    var vm = this;

                    axios({
                        method: 'post',
                        url: url,
                        data: data,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                            + response.data.token;
                        vm.loading = true;
                        setTimeout(function () {
                            vm.getProof(vm.proof.id);
                        }, 500);
                        setTimeout(function () {
                            window.location.href = url;
                        }, 50);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() =>
                        vm.downloading = null
                    );

                    return null;
                }
                let duration = this.proof.review_alerts_interval + ' hour';
                if (this.proof.review_alerts_interval > 1) {
                    duration += 's';
                }
                let appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Download and review proof',
                    text: 'The proof leader has set a time of ' + duration + ' for you to review this proof.',
                    component: this,
                    action: 'review',
                    buttons: {
                        yes: 'Download',
                        yes_icon: 'fa-file-arrow-down',
                        cancel: 'Cancel'
                    }
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'review') {
                    this.reviewProof(true);

                    return null;
                }
                if (action == 'end-cycle') {
                    this.endCycle(true);

                    return null;
                }
                if (action == 'suspend') {
                    this.suspendProof(true);

                    return null;
                }
                if (action == 'unsuspend') {
                    this.unsuspendProof(true);

                    return null;
                }
                if (action == 'make-available') {
                    this.makeAvailable(true);

                    return null;
                }
                if (action == 'delete') {
                    this.deleteProof(true);

                    return null;
                }
                if (action == 'archive') {
                    this.archiveProof(true);

                    return null;
                }
                if (action == 'dearchive') {
                    this.deArchiveProof(true);

                    return null;
                }
                if (action == 'proof-complete') {
                    this.proofComplete(true);

                    return null;
                }
            },
            returnProof: function (action = null) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'proof-return');
                if (component) {
                    component.reset(this.proof, action);
                }
            },
            canEndCycle: function () {
                if (this.proof.suspended || this.proof.approved || this.proof.archived) {
                    return false;
                }

                return (this.proof.number_of_reviewed < this.proof.number_of_reviewers);
            },
            endCycle: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'end-cycle';
                    /**
                     * Send end cycle request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/end-cycle';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.chart_data = null;
                        vm.loading = true;
                        vm.reload_proof = true;
                        vm.downloadProofFile(response.data.file, 'proof_file');
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.leader_action = null;
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'End this cycle',
                    text: 'Ending a cycle prevents others in the team being able to access it.'
                        + '<br><br>Are you sure you want to continue?',
                    component: this,
                    action: 'end-cycle'
                };
                $('#confirm-delete-modal').modal('show');
            },
            downloadProofFile: function (file, type) {
                this.errors = false;
                this.downloading = type;
                /**
                 * Send request to API.
                 */
                const data = {
                    item_type: type,
                    item_id: this.proof.id,
                    file_path: file.file_path,
                    file_type: file.file_type,
                    download_filename: file.filename + '.' + file.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    if (vm.reload_proof) {
                        setTimeout(function () {
                            vm.getProof(vm.proof.id);
                            vm.reload_proof = false;
                        }, 500);
                    }
                    setTimeout(function () {
                        window.location.href = url;
                    }, 50);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.downloading = null;
                    vm.leader_action = null;
                });
            },
            downloadLinkedAsset: function () {
                if (this.downloading_linked_asset) {
                    return null;
                }
                this.errors = false;
                const file = this.proof.linked_resource;
                this.downloading_linked_asset = true;
                /**
                 * Send request to API.
                 */
                const data = {
                    item_type: 'proof_linked_resource',
                    item_id: this.proof.id,
                    file_path: file.file_path,
                    file_type: file.file_type,
                    download_filename: file.filename + '.' + file.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    setTimeout(function () {
                        window.location.href = url;
                    }, 50);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.downloading_linked_asset = false;
                });
            },
            canUploadNextVersion: function () {
                if (!this.proof) {
                    return false;
                }
                if (/*!this.proof.suspended || */this.proof.archived || this.proof.approved) {
                    return false;
                }

                return (this.proof.number_of_reviewed >= this.proof.number_of_reviewers);
            },
            nextVersion: function () {
                const component = this.$root.findComponent(this, 'proof-next-version');
                if (component) {
                    component.reset();
                }
            },
            proofComplete: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'proof-complete';
                    const data = {
                        app_info: {
                            app_url: this.$root.app.app_url
                        }
                    };
                    /**
                     * Send approve proof request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/approve';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        data: data,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        let autoArchive = 1;
                        if ('auto_archive_proof_weeks' in this.$root.client) {
                            autoArchive = parseInt(this.$root.client.auto_archive_proof_weeks);
                        }
                        if (autoArchive || vm.$root.hasPermission('proofs_view_archive')) {
                            vm.loading = true;
                            vm.getProof(vm.proof.id);

                            return null;
                        }
                        vm.$router.push({ name: 'proofs' });
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.leader_action = null;
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                let autoArchive = 1;
                if ('auto_archive_proof_weeks' in this.$root.client) {
                    autoArchive = parseInt(this.$root.client.auto_archive_proof_weeks);
                }
                let text = 'Marking this proof as complete will put it ';
                if (autoArchive) {
                    text += 'into the archive after ' + autoArchive + ' week';
                    if (autoArchive > 1) {
                        text += 's';
                    }
                    text += '.';
                } else {
                    text += 'directly into the archive.';
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Proof complete',
                    text: text,
                    component: this,
                    action: 'proof-complete',
                    buttons: {
                        yes: 'OK',
                        cancel: 'Cancel'
                    }
                };
                $('#confirm-delete-modal').modal('show');
            },
            archiveProof: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'archive';
                    /**
                     * Send archive proof request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/archive';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$router.push({ name: 'proofs' });
                    })
                    .catch(function (error) {
                        vm.leader_action = null;
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Archive',
                    text: 'Are you sure you want to archive this proof?',
                    component: this,
                    action: 'archive'
                };
                $('#confirm-delete-modal').modal('show');
            },
            deArchiveProof: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'dearchive';
                    /**
                     * Send de-archive proof request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/de-archive';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$router.push({ name: 'proofs' });
                    })
                    .catch(function (error) {
                        vm.leader_action = null;
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'De-archive',
                    text: 'Are you sure you want to de-archive this proof?',
                    component: this,
                    action: 'dearchive'
                };
                $('#confirm-delete-modal').modal('show');
            },
            emailProof: function () {
                const component = this.$root.findComponent(this, 'proof-share');
                if (component) {
                    component.reset();
                }
            },
            changeFolder: function () {
                const component = this.$root.findComponent(this, 'proof-change-folder');
                if (component) {
                    component.reset();
                }
            },
            changeLeaderAlerts: function () {
                const component = this.$root.findComponent(this, 'proof-change-leader-alerts');
                if (component) {
                    component.reset();
                }
            },
            changeReviewAlerts: function () {
                const component = this.$root.findComponent(this, 'proof-change-review-alerts');
                if (component) {
                    component.reset();
                }
            },
            changeTargetDates: function () {
                const component = this.$root.findComponent(this, 'proof-change-target-dates');
                if (component) {
                    component.reset();
                }
            },
            chooseAsset: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    'Choose ' + this.$root.contents.asset_title,
                    this,
                    'proof-linked-asset',
                    'asset',
                    (this.proof.asset_id < 999999999999999) ? this.proof.asset_id : null
                );
                $('#select-item-modal').modal('show');
            },
            selectedItem: function (field, itemType, value) {
                if (!value || (value == this.proof.asset_id)) {
                    return null;
                }
                this.leader_action = 'link-asset';
                this.linkAsset(value);
            },
            linkAsset: function (assetId, uploadedFile = null) {
                let data = {
                    resource_id: assetId
                };
                if (uploadedFile !== null) {
                    data.linked_temp_file = uploadedFile.temp_file;
                    data.linked_filename = uploadedFile.filename;
                    data.linked_file_type = uploadedFile.file_type;
                    data.linked_file_size = uploadedFile.file_size;
                }

                /**
                 * Send link asset request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/link-resource';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.loading = true;
                    vm.getProof(vm.proof.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.leader_action = null;
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            canMakeAvailable: function () {
                if (this.proof.archived || this.proof.approved) {
                    return false;
                }
                if (this.proof.in_use) {
                    return true;
                }
                if (!this.proof.suspended) {
                    return false;
                }

                return (this.proof.number_of_reviewed < this.proof.number_of_reviewers);
            },
            makeAvailable: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'make-available';
                    /**
                     * Send make available request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/make-available';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.loading = true;
                        vm.getProof(vm.proof.id);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.leader_action = null;
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Make available',
                    text: 'This proof will be now be available to download again.',
                    component: this,
                    action: 'make-available',
                    buttons: {
                        yes: 'OK',
                        cancel: 'Cancel'
                    }
                };
                $('#confirm-delete-modal').modal('show');
            },
            downloadProof: function () {
                if (!this.proof.file) {
                    return null;
                }
                if (this.isLeader()) {
                    this.leader_action = 'download-proof';
                    this.downloadProofFile(this.proof.file, 'proof_file');

                    return null;
                }
                this.downloadProofFile(this.proof.file, 'proof_actions');
            },
            suspendProof: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'suspend';
                    /**
                     * Send suspend proof request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/suspend';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.chart_data = null;
                        vm.loading = true;
                        vm.getProof(vm.proof.id);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.leader_action = null;
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Suspend',
                    text: 'Are you sure you want to suspend this proof?',
                    component: this,
                    action: 'suspend'
                };
                $('#confirm-delete-modal').modal('show');
            },
            canUnsuspend: function () {
                if (!this.proof) {
                    return false;
                }
                if (!this.proof.suspended || this.proof.archived || this.proof.approved) {
                    return false;
                }

                return (this.proof.number_of_reviewed < this.proof.number_of_reviewers);
            },
            unsuspendProof: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'unsuspend';
                    /**
                     * Send unsuspend proof request to API.
                     */
                    const data = {
                        app_info: {
                            app_url: this.$root.app.app_url
                        }
                    };

                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                        + '/unsuspend';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        data: data,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.loading = true;
                        vm.getProof(vm.proof.id);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.leader_action = null;
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Unsuspend',
                    text: 'Are you sure you want to unsuspend this proof?',
                    component: this,
                    action: 'unsuspend'
                };
                $('#confirm-delete-modal').modal('show');
            },
            deleteProof: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.leader_action = 'delete';
                    /**
                     * Send delete proof request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$router.push({ name: 'proofs' });
                    })
                    .catch(function (error) {
                        vm.leader_action = null;
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete proof',
                    text: 'Are you sure you want to delete this proof?',
                    component: this,
                    action: 'delete'
                };
                $('#confirm-delete-modal').modal('show');
            },
            emailTeam: function (reviewer = null, chase = false) {
                const component = this.$root.findComponent(this, 'proof-email-team');
                if (component) {
                    component.reset(reviewer, chase);
                }
            },
            emailLeader: function () {
                let leader = null;
                if (this.proof.users) {
                    this.proof.users.forEach((item) => {
                        if (item.leader) {
                            leader = item;
                        }
                    });
                }

                if (!leader) {
                    return null;
                }

                this.emailTeam(leader);
                $('#email-team-modal').modal('show');
            },
            getLeaderName: function () {
                let name = '';
                if (this.proof.users) {
                    this.proof.users.forEach((item) => {
                        if (item.leader) {
                            name = item.first_name + ' ' + item.last_name;
                        }
                    });
                }

                return name;
            },
            getLeaderAlert: function () {
                if (this.proof.leader_alerts == 'Y') {
                    return 'Each time proof is returned';
                }
                if (this.proof.leader_alerts == 'A') {
                    return 'At the end of each cycle';
                }
                
                return 'None';
            },
            getTeam: function () {
                if (!this.proof) {
                    return [];
                }
                if (!this.proof.users) {
                    return [];
                }
                if (!this.proof.ordered) {
                    return this.proof.users;
                }
                let team = [];
                let order = 1;
                this.proof.users.forEach((item) => {
                    if (item.reviewer) {
                        item.review_order = order;
                        order++;
                    }
                    team.push(item);
                });

                return team;
            },
            canEmailTeam: function () {
                const team = this.getTeam();
                if (team.length == 0) {
                    return false;
                }
                let numRecipients = 0;
                team.forEach((item) => {
                    if (item.reviewer && !item.leader) {
                        numRecipients++;
                    }
                });

                return (numRecipients > 0);
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            editTeam: function () {
                const component = this.$root.findComponent(this, 'proof-team-edit');
                if (component) {
                    component.reset();
                }
            },
            getReviewerProfileImage: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return null;
                }
                const userId = this.proof.current_reviewer ? this.proof.current_reviewer.user_id
                    : this.proof.next_reviewer.user_id;
                let profileImage = null;
                this.proof.users.forEach((item) => {
                    if (item.user_id == userId) {
                        profileImage = item.profile_image;
                    }
                });

                return profileImage;
            },
            getReviewerName: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return '';
                }
                return this.proof.current_reviewer ? this.proof.current_reviewer.name
                    : this.proof.next_reviewer.name;
            },
            getReviewerInitials: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return '';
                }
                const userId = this.proof.current_reviewer ? this.proof.current_reviewer.user_id
                    : this.proof.next_reviewer.user_id;
                let initials = '';
                this.proof.users.forEach((item) => {
                    if (item.user_id == userId) {
                        initials = this.$root.initial(item.first_name, item.last_name);
                    }
                });

                return initials;
            },
            hasReturned: function () {
                let returnStatus = false;
                this.proof.users.forEach((item) => {
                    if ((item.user_id == this.$root.user.id) && item.reviewer) {
                        if (item.review_status == 'WC') {
                            returnStatus = 'You returned with changes';
                        }
                        if ((item.review_status == 'NC') || (item.review_status == 'MC')) {
                            returnStatus = 'You approved this';
                        }
                        if (item.review_status == 'UR') {
                            returnStatus = 'Await notification';
                        }
                    }
                });

                return returnStatus;
            },
            datePassed: function (dateFormatted) {
                const now = new Date();
                const date = new Date(dateFormatted);

                return (date < now);
            }
        }
    }
</script>
<style scoped>
    .asset-preview .thumbnail {
        height: 302px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    .asset-preview .thumbnail img {
        max-height: 300px;
    }

    .fa-crown.gold {
        color: #bf9000;
    }
</style>
