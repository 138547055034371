import { render, staticRenderFns } from "./PrintOrderDetails.vue?vue&type=template&id=71060d54&scoped=true"
import script from "./PrintOrderDetails.vue?vue&type=script&lang=js"
export * from "./PrintOrderDetails.vue?vue&type=script&lang=js"
import style0 from "./PrintOrderDetails.vue?vue&type=style&index=0&id=71060d54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71060d54",
  null
  
)

export default component.exports