<template>
    <div :class="is_modal ? 'modal-content' : ''">
        <div v-if="is_modal" class="modal-header">
            <h5 class="modal-title" id="manage-field-order-modal-label">Manage field order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div :class="is_modal ? 'modal-body' : ''">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <loading v-if="loading"></loading>

            <div v-if="(!loading && (items.length == 0))" class="alert alert-warning form-errors" role="alert">
                There are no editable fields in this {{ $root.contents.template_title }}.
            </div>

            <div v-if="(items.length > 0)" :style="is_modal ? 'min-height: 300px' : ''">
                <p>
                    Drag &amp; drop fields into a logical order for the edit form
                </p>

                <div v-if="template.number_of_pages > 1">
                    <div class="form-group">
                        <searchable-option-list
                            v-model="current_page"
                            :value="current_page"
                            :options="templatePages()"
                            :readonly="(updating || (templatePages().length == 1))"
                            search_placeholder="Select page"
                            v-on:change="selecting_template_page = true"
                        ></searchable-option-list>
                    </div>

                    <hr v-if="(current_page !== null)">
                </div>

                <div v-if="(!selecting_template_page && (current_page !== null))">
                    <draggable
                        v-model="items[current_page]"
                        ghost-class="vue-drag-ghost"
                        tag="div"
                        @end="onDragSortEnd"
                        animation="200"
                        class="form-group"
                    >
                        <div
                            v-for="item in items[current_page]"
                            :key="'manage-field-order-template-field' + item.id"
                            class="form-control mb-1"
                            style="height: auto; cursor: move"
                        >
                            <div class="row">
                                <div class="col">
                                    {{ item.title }}{{ item.title_suffix }}
                                    <span v-if="item.group_id" class="small text-muted">
                                        (GID: {{ item.group_id }})
                                    </span>
                                </div>
                                <div
                                    class="col-2 font-size-18 contact-links"
                                    v-b-tooltip.hover
                                    :title="((item.type == 'T') ? 'Text' : 'Image')
                                        + ' field'"
                                >
                                    <i
                                        :class="'fal '
                                            + ((item.type == 'T')
                                                ? 'fa-input-text' : 'fa-image')
                                            + ' float-right'"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
        <div v-if="!loading && is_modal" :class="is_modal ? 'modal-footer' : ''">
            <span v-if="!updating">
                <button :key="'done-button'" type="button" class="btn btn-primary" @click="updateFields()">
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </span>
            <span v-else>
                <button :key="'updating-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Updating fields</span>
                </button>
            </span>
        </div>
        <button
            v-if="!loading && !is_modal"
            :key="'finish-button'"
            type="button"
            class="btn btn-primary"
            @click="finish()"
        >
            <i class="far fa-check mr-1"></i> Finish
        </button>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        props: ['template', 'is_modal'],
        components: {
            draggable,
        },
        data () {
            return {
                component_name: 'manage-field-order',
                errors: false,
                loading: true,
                items: [],
                current_page: null,
                selecting_template_page: false,
                updating: false
            }
        },
        mounted () {
            //
        },
        watch: {
            selecting_template_page: function (val) {
                if (val) {
                    if (this.current_page) {
                        this.$emit('pageChanged', this.current_page);
                    }
                    var vm = this;
                    setTimeout(function () {
                        vm.selecting_template_page = false;
                    }, 500);
                }
            }
        },
        methods: {
            reset: function () {
                this.getFields(this.template);
            },
            getFields: function (template) {
                if (!this.template) {
                    this.errors = 'Something went wrong, please try again';
                    this.loading = false;
                    this.items = [];

                    return null;
                }
                this.errors = false;
                this.loading = true;
                this.items = [];
                this.current_page = null;

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + template.id
                    + '/fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let page = 0;
                    let items = [];
                    let fields = [];
                    response.data.fields.forEach((item) => {
                        if (item.page_number > page) {
                            for (let p = page; p < item.page_number; p++) {
                                items.push(fields);
                                fields = [];
                            }
                            page = item.page_number;
                        }
                        fields.push(item);
                    });
                    if (fields.length > 0) {
                        items.push(fields);
                    }
                    vm.items = items;
                    const templatePages = vm.templatePages();
                    if (templatePages.length == 1) {
                        vm.current_page = templatePages[0].id;
                        vm.selecting_template_page = true;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        if (vm.is_modal) {
                            $('#manage-field-order-modal').modal('hide');
                        }
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            templatePages: function () {
                let pages = [];
                this.items.forEach((fields, page) => {
                    if ((page > 0) && (fields.length > 1)) {
                        pages.push({ id: page, name: 'Page ' + page });
                    }
                });

                return pages;
            },
            updateFields: function () {
                this.errors = false;
                this.updating = true;

                let fields = [];
                this.items.forEach((page) => {
                    page.forEach((item, index) => {
                        fields.push({
                            id: item.id,
                            sort_order: index,
                            profile_images: item.profile_images,
                            user_images: item.user_images,
                            image_editing: item.image_editing
                        });
                    });
                });
                const data = {
                    fields: fields,
                    fields_managed: true
                };

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template.id
                    + '/fields';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.is_modal) {
                        $('#manage-field-order-modal').modal('hide');
                    }
                    vm.$parent.template.fields_managed = true;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - close modal and logout user.
                        if (vm.is_modal) {
                            $('#manage-field-order-modal').modal('hide');
                        }
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onDragSortEnd: function(event) {
                if (this.is_modal) {
                    return null;
                }
                this.updateFields();
            },
            finish: function () {
                this.$parent.managing_field_order = false;
                if (!this.$parent.template.fields_managed) {
                    this.updateFields();
                }
            }
        }
    }
</script>
