<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <p v-if="(!errors && !loading)">
            The keywords listed below will be ignored when detecting keywords in image
            {{ $root.contents.asset_title }}s. You can add maximum 100 keywords to the list.
        </p>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            There are no keywords added
        </div>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-11">Keyword</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'exclusion-row' + item.id" class="xxxpb-0">
                                    <div class="row">
                                        <div class="col-11" style="padding-top: 3px">
                                            {{ item.label }}
                                            <!--<h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditExclusion(item)">
                                                    {{ item.label }}
                                                </a>
                                            </h5>-->
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="remove(item)"
                                                v-b-tooltip.hover.left
                                                title="Remove"
                                            >
                                                <i class="fa-regular fa-trash-alt"></i>
                                            </a>
                                        </div>
                                        <!--<div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditExclusion(item)"
                                            >
                                                <span
                                                    v-if="(exclusion_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>-->
                                    </div>
                                    <!--<div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'exclusion-details' + item.id">
                                            <detected-label-exclusion-edit-form
                                                :data="item"
                                            ></detected-label-exclusion-edit-form>
                                        </div>
                                    </div>-->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="detected-label-exclusion-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="detected-label-exclusion-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <detected-label-exclusion-add-form></detected-label-exclusion-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'detected-label-exclusions',
                loading: false,
                errors: false,
                roles: [],
                items: [],
                exclusion_details_open: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getLabels();
        },
        methods: {
            getLabels: function () {
                if (
                    !this.$root.client.assets_use_detected_labels
                    || !this.$root.hasPermission('assets_keyword_assistant')
                ) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.exclusion_details_open = [];
                this.$root.status_info.right = null;

                /**
                 * Send request to API.
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/detected-labels/excluded';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.items = response.data.detected_labels;
                    vm.updateStatusInfo();
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            updateStatusInfo: function () {
                const itemsTotal = this.items.length;
                this.$root.status_info.right = itemsTotal + ' keyword';
                if (itemsTotal !== 1) {
                    this.$root.status_info.right += 's';
                }
            },
            /*toggleEditExclusion: function (item) {
                if (this.exclusion_details_open.indexOf(item.id) < 0) {
                    this.exclusion_details_open.push(item.id);
                    $('#exclusion-row' + item.id).addClass('highlighted');
                    $('#exclusion-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#exclusion-details' + item.id).slideUp('slow', function () {
                        $('#exclusion-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.exclusion_details_open.length; n++) { 
                            if (vm.exclusion_details_open[n] == item.id) { 
                                vm.exclusion_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },*/
            remove: function (label) {
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item.id == label.id) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.items.splice(itemIndex, 1);
                }
                this.updateStatusInfo();
                label.excluded = 0;
                /**
                 * Send request to API.
                 */
                const data = label;
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/detected-label/'
                    + label.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    //
                })
                .catch(function (error) {
                    //
                });
            }
        }
    }
</script>
